import { Node } from './node/node.model';
import { Section } from './section.model';
import { SelectedElementType } from '../enums/selected-element-type.enum';

export abstract class SelectedElement {
    type: string;
    element: Node | Section;

    protected constructor(type: string, element: Node | Section) {
        this.type = type;
        this.element = element;
    }
}

export class SelectedNode extends SelectedElement {
    element: Node;

    public constructor(node: Node) {
        super(SelectedElementType.Node, node);
    }
}

export class SelectedSection extends SelectedElement {
    element: Section;

    public constructor(section: Section) {
        super(SelectedElementType.Section, section);
    }
}
