import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { NodesState, NodesStore } from './nodes.store';
import { Node } from '../../models/node/node.model';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
@QueryConfig({
    sortBy: 'position',
    sortByOrder: Order.ASC, // Order.DESC
})
export class NodesQuery extends QueryEntity<NodesState, Node> {
    constructor(protected override store: NodesStore) {
        super(store);
    }

    public isAnyInEditingState(): boolean {
        return this.getAll().filter((n) => n.editing).length > 0;
    }

    public waitUntilAllSaved(): Promise<boolean> {
        return this.select('isSaving')
            .pipe(first((isSaving) => !isSaving))
            .toPromise();
    }
}
