<div [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutGap="20px" class="excel-container">
        <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="false" fxFlex="70">
            <mat-label>{{ label }}</mat-label>
            <ng-container *ngIf="!asset">
                <input
                    matInput
                    readonly
                    [formControlName]="name"
                    placeholder="{{ 'component.excel.notselected' | translate }}"
                    [required]="true" />
            </ng-container>
            <ng-container *ngIf="asset">
                <input
                    matInput
                    readonly
                    placeholder="{{ 'component.excel.notselected' | translate }}"
                    [value]="asset ? asset.name : ''" />
            </ng-container>
            <!--        <mat-hint *ngIf="hint">{{hint | translate}}</mat-hint>-->
            <mat-error *ngIf="formGroup.get(name).hasError('required')">
                {{ 'component.excel.asseterror' | translate }}
            </mat-error>
        </mat-form-field>
        <elias-assetbrowser-button
            [allowedTypes]="allowedTypes"
            [single]="true"
            (assetsSelected)="onSelect($event)"
            text="{{ 'component.excel.changefile' }}"
            type="spreadsheets"
            [selectedAsset]="asset"></elias-assetbrowser-button>
    </div>
    <div *ngIf="asset" class="processing-msg">
        <small *ngIf="asset['processing'] === false && asset['processedAt'] === null">{{
            'propertyEditor.excel.waitingForProcessing' | translate
        }}</small>
        <small *ngIf="asset['processing'] === true">{{ 'propertyEditor.excel.processing' | translate }}</small>
        <small *ngIf="asset['processing'] === false && asset['processedAt'] !== null"
            >{{ 'propertyEditor.excel.processedAt' | translate }}
            {{ asset['processedAt'] | localizedDate : 'eliasShortDateTime' }}</small
        >
    </div>
</div>
