import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/visualchars';
import 'tinymce/icons/default';
import '../../../../../../assets/tinymce/plugins/colbreak/index';
import '../../../../../../assets/tinymce/plugins/spellchecker/index';
import { Node } from '../../../models/node/node.model';
import { Observable } from 'rxjs';
import { NodeConfig, NodeType } from '../../../models/node/node-type.model';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';

@Component({
    selector: 'elias-editor-edit-lead',
    templateUrl: './edit-lead.component.html',
    styleUrls: ['./edit-lead.component.scss'],
})
export class EditLeadComponent implements NodeEdit, NodeRepresentation, OnInit {
    @Input() config?: NodeConfig;
    @Input() content: string = '';
    @Input() node?: Node;
    @Input() nodeViewModel?: Observable<Node>;

    @Output() contentChange = new EventEmitter<any>();

    public localContent: string = '';
    public containerId = 'tinymce';

    ngOnInit(): void {
        this.containerId = 'tinymce-' + this.node?.id;
        this.localContent = this.content;

        if (this.config === undefined) {
            let nodeConfig = {} as NodeConfig;
            nodeConfig.type = NodeType.unknown;
            nodeConfig.settings = {};

            this.config = nodeConfig;
        }
    }

    public onContentChange(content: string): void {
        this.content = content;
        this.contentChange.emit(this.content);
    }
}
