import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Image } from '../../models/image.model';

export interface ImagesState extends EntityState<Image> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Images', resettable: true })
export class ImagesStore extends EntityStore<ImagesState, Image> {
    constructor() {
        super({ loaded: false });
    }
}
