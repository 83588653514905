import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../modules/shared/models/user.model';
import { UsersQuery } from '../../../../modules/shared/state/users/users.query';
import { NodeConfig } from '../../models/node/node-type.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'elias-tinymce-editor',
    template: `<ng-template></ng-template>`,
})
export class TinymceComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() config?: NodeConfig;
    @Input() content: string = '';
    @Input() elementId?: string;

    @Output() contentChange = new EventEmitter<any>();
    @Output() save = new EventEmitter<any>();

    protected containerId: string = '';
    protected tinymceSettings = {};

    private currLang: string = '';
    private currLangUrl: string = '';
    private inlineEditor: typeof tinymce;

    private setup: object = {
        setup: (editor: typeof tinymce) => {
            this.inlineEditor = editor;

            const handler = (event: Event) => {
                this.onContentChange();
            };

            editor.on('init', () => {
                editor.focus();
                editor.selection.select(editor.getBody(), true);
                editor.selection.collapse(false);
            });

            editor.on('keyup', handler);
            editor.on('execcommand', handler);
            editor.on('mouseout', handler);
        },
    };

    constructor(private usersQuery: UsersQuery) {}

    ngOnInit(): void {
        this.containerId = 'tinymce-' + this.elementId;
        this.currLang = (this.usersQuery.getLoggedInUser() as User).locale;
        this.currLangUrl = environment.assetsRootPath + 'tinymce/js/langs/' + this.currLang + '.js';
    }

    ngAfterViewInit(): void {
        const settings = Object.assign(this.defaultSettings(), this.config?.settings, this.setup);

        if (this.currLang !== 'en_US') {
            settings['language'] = this.currLang;
            settings['language_url'] = this.currLangUrl;
        }

        tinymce.init(settings);
        this.tinymceSettings = settings;
        this.runAutomaticSpellCheck();
    }

    ngOnDestroy(): void {
        tinymce.remove(this.inlineEditor);
    }

    public getTinyMceSettings(): any {
        return this.tinymceSettings;
    }

    protected onContentChange(): void {
        this.content = this.inlineEditor.getContent();
        this.contentChange.emit(this.content);
    }

    protected runAutomaticSpellCheck(): void {
        setTimeout(
            function (that) {
                if (that.inlineEditor) {
                    that.inlineEditor.execCommand('mceSpellCheck');
                }
            },
            500,
            this
        );
    }

    protected defaultSettings(): object {
        return {
            selector: '#' + this.containerId,
            plugins: ['spellchecker', 'lists', 'advlist', 'paste', 'nonbreaking', 'link', 'quickbars', 'visualchars'],
            menubar: false,
            toolbar: 'spellchecker',
            style_formats: [],
            inline: true,
            quickbars_selection_toolbar: 'bold italic underline | link',
            quickbars_insert_toolbar: false,
            default_link_target: '_blank',
            link_default_protocol: 'https',
            link_title: false,
            auto_focus: this.containerId,
            skin_url: environment.assetsRootPath + 'tinymce/skins/ui/elias',
            paste_word_valid_elements: 'a,p,ul,ol,li,b,strong,i,u,h2,h3,h4,h5,h6,blockquote,sub,sup,br,em',
            valid_elements: 'p,br',
            fixed_toolbar_container: '#toolbar-content',
            valid_styles: {
                span: 'color,background-color,text-decoration',
                ol: 'list-style-type',
                ul: 'list-style-type',
            },
            nonbreaking_wrap: true,
            visualchars_default_state: true,
            // changed to color map
            color_map: ['FF0000', 'Red', 'FFFF00', 'Yellow'],
            advlist_bullet_styles: 'default',
            advlist_number_styles: 'default,lower-alpha',
            deprecation_warnings: false,

            // The paste preprocess function was introduced to remove data and aria attributes. This should be removed
            // once there is a build in solution for https://github.com/tinymce/tinymce/issues/8046
            paste_preprocess: (plugin: any, args: { content: string }): void => {
                let html = args.content as string;
                const tags = html.match(/(<\/?[\S][^>]*>)/gi);
                if (tags !== null) {
                    tags.forEach((tag) => {
                        html = html.replace(
                            tag,
                            tag.replace(
                                /(data-.+?=".*?")|(data-.+?='.*?')|(data-[a-zA-Z0-9-]+)|(aria-.+?=".*?")|(aria-.+?='.*?')|(aria-[a-zA-Z0-9-]+)/g,
                                ''
                            )
                        );
                    });
                }

                args.content = html;
            },
        };
    }
}
