export class RequestDto {
    params: {
        [key: string]: any;
    };
    queryParams?: {
        [key: string]: string;
    };
    body?: any;

    constructor(params: { [key: string]: any } = {}, queryParams: { [key: string]: string } = {}, body?: any) {
        this.params = params;
        this.queryParams = queryParams;
        this.body = body;
    }

    /**
     * @param {string} key
     * @param {any} value
     */
    addParam(key: string, value: any): void {
        this.params = {
            ...this.params,
            [key]: value,
        };
    }

    /**
     *
     * @param {string} key
     * @param {string} value
     */
    addQueryParam(key: string, value: string): void {
        this.queryParams = {
            ...this.queryParams,
            [key]: value,
        };
    }

    /**
     *
     * @param {string} key
     * @returns {any | null}
     */
    getParam(key: string): any | null {
        return this.params[key] ? this.params[key] : null;
    }

    /**
     *
     * @param {string} key
     * @returns {string | null}
     */
    getQueryParam(key: string): string | null {
        return this.queryParams[key] ? this.queryParams[key] : null;
    }

    /**
     *
     * @returns {string}
     */
    getQueryString(): string {
        let queryString = '';
        queryString = Object.keys(this.queryParams)
            .map((key) => {
                if (this.queryParams[key] !== null) {
                    return encodeURI(key) + '=' + encodeURI(this.queryParams[key]);
                }
                return null;
            })
            .filter((value) => value !== null)
            .join('&');

        return queryString ? '?' + queryString : '';
    }
}
