import { Component, Injector } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'elias-form-element-date',
    templateUrl: './form-date.component.html',
})
export class FormDateComponent extends BasicFormElementComponent {
    constructor(injector: Injector, private adapter: DateAdapter<any>, private translate: TranslateService) {
        super(injector);
        this.adapter.setLocale(this.translate.currentLang);
    }
}
