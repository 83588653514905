<elias-base-dialog (dialogClosed)="onCancel()">
    <h1 class="dialog-header" header>{{ 'modal.confirm.isDataProcessingApproved.title' | translate }}</h1>

    <elias-data-processing-content></elias-data-processing-content>

    <div footer class="footer-bar">
        <button class="footer-btn prev" mat-button mat-dialog-close (click)="onCancel()">
            {{ 'actions.cancel' | translate }}
        </button>
        <button class="footer-btn next" mat-stroked-button color="accent" (click)="onConfirm()">
            {{ 'actions.agree' | translate }}
        </button>
    </div>
</elias-base-dialog>
