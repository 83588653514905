<ng-container [formGroup]="formGroup" *ngIf="!active">
    <mat-form-field appearance="outline" [hideRequiredMarker]="false">
        <mat-label>{{ label }}</mat-label>
        <textarea
            matInput
            [formControlName]="name"
            [placeholder]="placeholder"
            [required]="required"
            [rows]="rows"
            #textAreaElement
            [maxLength]="600"></textarea>
        <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
        <mat-hint align="end" *ngIf="hint">{{ textAreaElement.value?.length || 0 }}/600</mat-hint>
        <mat-error *ngIf="formGroup.get(name).hasError('required')">
            {{ 'error.' + name | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get(name).hasError('maxlength') && !formGroup.get(name).hasError('required')">
            {{ 'error.minlength.' + name | translate }}
        </mat-error>
    </mat-form-field>
</ng-container>

<!--<ng-container *ngIf="active">
    <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="false">
        <mat-label>{{label}}</mat-label>
        <input matInput type="text" style="display: none">
        <div id="{{containerId}}"  (click)="openMCE()" class="text-area" [innerHTML]="getSanitizedData(localContent)">
        </div>
    </mat-form-field>
</ng-container>-->
<ng-container *ngIf="active">
    <div id="{{ containerId }}" class="text-area" [innerHTML]="getSanitizedData(localContent)"></div>
</ng-container>
