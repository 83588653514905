import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Creators } from '../../../../modules/shared/models/creator.model';
import { Version } from '../../models/version.model';
import { Log } from '../../../history/models/log.model';

@Component({
    selector: 'elias-version-card',
    templateUrl: './version-card.component.html',
    styleUrls: ['./version-card.component.scss'],
})
export class VersionCardComponent {
    @Input() creator?: Creators[string];
    @Input() nodeId?: string;
    @Input() version?: Version;

    @Output() versionOpened = new EventEmitter<Log>();

    get canBeDisplayed(): boolean {
        return this.version?.log.action !== 'deleted';
    }

    constructor() {}

    public openVersion(): void {
        if (this.version?.log) {
            this.versionOpened.emit(this.version.log);
        }
    }
}
