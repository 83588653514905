import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { CustomStepComponent } from './custom-step/custom-step.component';

@Component({
    selector: 'elias-shared-custom-stepper',
    templateUrl: './custom-stepper.component.html',
    styleUrls: ['./custom-stepper.component.scss'],
    providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }],
})
export class CustomStepperComponent extends CdkStepper implements OnInit {
    @Input() mode = 'publication';

    @Output() cancel = new EventEmitter();
    @Output() nextStep = new EventEmitter<any>();

    @ContentChildren(CustomStepComponent) _steps: QueryList<CustomStepComponent>;

    autofocus = true;

    get selectedStep(): CustomStepComponent {
        return this.getActiveStep();
    }

    ngOnInit(): void {
        this.refreshAutofocus();

        this.selectionChange.subscribe((el) => {
            this.refreshAutofocus();
        });
    }

    onClick(index: number): void {
        if (index < this.selectedIndex) {
            this.selectedIndex = index;
        } else if (this.getActiveStep().isSave) {
            this.selectedIndex = index;
        }
    }

    getActiveStep(): CustomStepComponent {
        return this._steps.toArray()[this.selectedIndex];
    }

    onCancel(): void {
        this.cancel.emit();
    }

    next() {
        this._steps.toArray()[this.selectedIndex].nextStep();
    }

    goToNext() {
        super.next();
    }

    trackByIndex(index, item) {
        return index;
    }

    refreshAutofocus(): void {
        this.autofocus = false;

        setTimeout(() => {
            this.autofocus = true;
        });
    }
}
