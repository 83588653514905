import { Pipe, PipeTransform } from '@angular/core';

declare let _;

@Pipe({
    name: 'locale',
    pure: true,
})
export class LocalePipe implements PipeTransform {
    localeValue: string[];
    value: string;

    constructor() {
        this.value = '';
    }

    transform(value: string): string {
        this.localeValue = value.split(',').map((val) => {
            return val;
        });
        if (this.localeValue.length >= 3) {
            this.value = '3+';
        } else {
            this.value = this.localeValue.join(' | ');
        }
        return this.value;
    }
}
