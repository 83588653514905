import { LinksGeneratorRegistry } from './links-generator.registry';
import { LinksGeneratorInterface } from '../interfaces/links-generator.interface';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';
import { AssetLinksProvider } from '../providers/asset-links.provider';

@LinksGeneratorRegistry.register
export class AssetLinksGenerator implements LinksGeneratorInterface {
    canGenerate(provider: LinksProviderInterface): provider is AssetLinksProvider {
        return provider instanceof AssetLinksProvider;
    }

    generate(provider: LinksProviderInterface): any[] | null {
        if (!this.canGenerate(provider)) {
            return null;
        }

        const { publicationGroupId, publicationId, assetId } = provider.getParameters();

        return [
            '/publication-groups',
            publicationGroupId,
            'publication',
            publicationId,
            'editor',
            'files',
            { asset: assetId },
        ];
    }
}
