import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'newlinetobreak' })
export class NewLineToBreakPipe implements PipeTransform {
    transform(value: string | SafeHtml): any {
        if (typeof value === 'string') {
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }
        return value;
    }
}
