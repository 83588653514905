import { Injectable } from '@angular/core';
import { FormConfig } from '../../../modules/forms/models/form-config.model';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services';
import { Publication } from '../../../publication/models/publication.model';
import { Section } from '../models/section.model';
import { KeyValueOption } from '../../../modules/shared/models/key-value-option.model';

@Injectable({
    providedIn: 'root',
})
export class SectionTypeForm {
    constructor(private layoutService: LayoutService, private translateService: TranslateService) {}

    public getConfiguration(section: Section, publication?: Publication): FormConfig {
        return {
            elements: [
                {
                    label: 'editor.forms.changeSectionType.type',
                    name: 'sectionType',
                    options: this.getSectionTypeOptions(section, publication),
                    required: true,
                    type: 'select',
                    validators: [],
                    width: 100,
                },
            ],
        };
    }

    private getSectionTypeOptions(section: Section, publication?: Publication): KeyValueOption[] {
        let options = this.layoutService.getSectionTypeOptions(publication);

        return options.filter((option) => option.key !== section.type);
    }
}
