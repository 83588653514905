import { LinksGeneratorRegistry } from './links-generator.registry';
import { LinksGeneratorInterface } from '../interfaces/links-generator.interface';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';
import { NodeLinksProvider } from '../providers/node-links.provider';

@LinksGeneratorRegistry.register
export class NodeLinksGenerator implements LinksGeneratorInterface {
    public canGenerate(provider: LinksProviderInterface): provider is NodeLinksProvider {
        return provider instanceof NodeLinksProvider;
    }

    public generate(provider: LinksProviderInterface): any[] | null {
        if (!this.canGenerate(provider)) {
            return null;
        }

        const { publicationGroupId, publicationId, sectionId, nodeId } = provider.getParameters();

        return [
            '/publication-groups',
            publicationGroupId,
            'publication',
            publicationId,
            'editor',
            'section',
            sectionId,
            { node: nodeId },
        ];
    }
}
