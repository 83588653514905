<div style="word-break: break-word">
    <div
        class="editor pe-reset pe-style-title {{ titleClass }}"
        id="{{ containerId }}"
        [innerHTML]="localContent || '&nbsp;' | html">
        <elias-tinymce-editor
            [content]="localContent"
            [elementId]="node?.id"
            [config]="config"
            (contentChange)="onContentChange($event)"></elias-tinymce-editor>
    </div>
</div>
