import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../../../auth/service/auth.service';
import { User } from '../../../shared/models/user.model';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { MainSideNavService } from '../../services/main-side-nav.service';
import { UsersUtilsService } from '../../../../user/services/users-utils.service';
import { SystemInformationQuery } from '../../../shared/state/system-information/system-information.query';
import { first } from 'rxjs/operators';
import { NotificationsQuery } from '../../../../notifications/state/notifications.query';
import { UsersQuery } from '../../../shared/state/users/users.query';
import { MatDialog } from '@angular/material/dialog';
import { DataProtectionComponent } from '../../dialogs/data-protection/data-protection.component';

@Component({
    selector: 'elias-core-main-navigation',
    templateUrl: './main-navigation.component.html',
    styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit {
    @ViewChild('mainnav') public sidenav!: MatSidenav;

    public isAdmin = false;
    public version!: string;
    public clientVersion!: string;
    public user!: User;
    public numberOfUnreadNotifications!: number;

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private mainSideNavService: MainSideNavService,
        private notificationsQuery: NotificationsQuery,
        private router: Router,
        private systemInformationQuery: SystemInformationQuery,
        private userUtilService: UsersUtilsService,
        private usersQuery: UsersQuery
    ) {}

    ngOnInit(): void {
        this.user = this.usersQuery.getLoggedInUser() as User;
        if (this.user && (this.userUtilService.isAdmin(this.user) || this.userUtilService.isSuperAdmin(this.user))) {
            this.isAdmin = true;
        }

        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError:
                    {
                        if (this.sidenav !== undefined && this.sidenav.opened) {
                            this.mainSideNavService.toggle();
                        }
                    }
                    break;
            }
        });

        this.systemInformationQuery.version$.pipe(first()).subscribe((version) => {
            this.version = version;
        });

        this.systemInformationQuery.clientVersion$.pipe(first()).subscribe((clientVersion) => {
            this.clientVersion = clientVersion;
        });

        this.notificationsQuery.select('additionalData').subscribe((additionalData) => {
            this.numberOfUnreadNotifications = additionalData.numberOfUnreadNotifications;
        });
    }

    public logout(): void {
        this.authService.logout();
    }

    public openDataProtectionDialog(): void {
        this.dialog.open(DataProtectionComponent, { width: '1140px', height: '840px' });
    }
}
