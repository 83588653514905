import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { NodeDto } from '../models/dto/node-dto.model';
import { PropertyEditorQuery } from '../state/property-editor/property-editor.query';
import { PropertyEditorService } from '../services/property-editor.service';
import { NodesQuery } from '../state/nodes/nodes.query';
import { NodesService } from '../services/nodes.service';
import { PublicationsQuery } from '../../../publication/state/publications/publications.query';
import { SectionsService } from '../services/sections.service';
import { SectionsQuery } from '../state/sections/sections.query';
import { LocksQuery } from '../state/locks/locks.query';
import { LocksService } from '../services/locks.service';
import { PublicationsService } from '../../../publication/services/publications.service';
import { UrlParamsService } from '../../../modules/core/services/url-params.service';
import { Section } from '../models/section.model';

@Injectable()
export class EditorDocumentResolver implements Resolve<any> {
    constructor(
        private propertyEditorService: PropertyEditorService,
        private propertyEditorQuery: PropertyEditorQuery,
        private nodesQuery: NodesQuery,
        private nodesService: NodesService,
        private publicationsQuery: PublicationsQuery,
        private sectionsService: SectionsService,
        private sectionsQuery: SectionsQuery,
        private locksQuery: LocksQuery,
        private locksService: LocksService,
        private publicationsService: PublicationsService,
        private urlParamsService: UrlParamsService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const sectionId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(route, 'sectionId');
        const publicationId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(route, 'publicationId');

        const observables = [];
        if (!this.publicationsQuery.hasEntity(publicationId)) {
            observables.push(this.publicationsService.getSinglePublication(publicationId));
        }

        if (this.nodesQuery.getValue().loadedNodesForSectionId !== sectionId) {
            const payload: NodeDto = new NodeDto({ sectionId });
            observables.push(this.nodesService.getNodesForSection(payload));
        }

        if (observables.length > 0) {
            return forkJoin(observables).pipe(
                first(),
                switchMap(() => {
                    if (!this.sectionsQuery.hasEntity(sectionId)) {
                        return this.sectionsService.getSections();
                    }

                    return of(true);
                }),
                tap(() => {
                    this.sectionsService.setActiveSection(this.sectionsQuery.getEntity(sectionId) as Section);
                })
            );
        } else {
            this.sectionsService.setActiveSection(this.sectionsQuery.getEntity(sectionId) as Section);
            return true;
        }
    }
}
