import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SpecialUsers } from '../../../../modules/shared/enums/special-users.enum';
import { CommentStatus } from '../../enums/comment-status.enum';
import { Comment, Reply } from '../../models/comment.model';
import { UsersUtilsService } from '../../../../user/services/users-utils.service';

@Component({
    selector: 'elias-base-comment',
    templateUrl: './base-comment.component.html',
    styleUrls: ['./base-comment.component.scss'],
})
export class BaseCommentComponent implements OnChanges {
    public SpecialUsersEnum = SpecialUsers;
    public creatorName: string = '';

    @Input() comment?: Comment | Reply;
    @Input() status: CommentStatus = CommentStatus.Editable;

    @Output() commentClicked = new EventEmitter<void>();

    constructor(private usersUtilsService: UsersUtilsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['comment']) {
            this.creatorName = this.usersUtilsService.getFullName(this.comment?.createdBy ?? SpecialUsers.EliasSupport);
        }
    }

    public onCommentClicked(): void {
        this.commentClicked.emit();
    }
}
