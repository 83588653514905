<div class="modal-container refresh-or-logout-container" style="height: 350px">
    <h1 mat-dialog-title>
        {{ 'modal.autoLogout.title' | translate }}
    </h1>

    <div mat-dialog-content class="modal-content">
        <p *ngIf="timeToLogout !== 1; else messageSingular">
            {{ 'modal.autoLogout.message-plural' | translate : { timeLeft: timeToLogout } }}
        </p>
        <ng-template #messageSingular>
            <p>
                {{ 'modal.autoLogout.message-singular' | translate : { timeLeft: timeToLogout } }}
            </p>
        </ng-template>
    </div>

    <div mat-dialog-actions class="modal-action-buttons">
        <button mat-stroked-button color="accent" (click)="stayLoggedIn()" class="modal-save-button">
            {{ 'modal.autoLogout.stayLoggedIn' | translate }}
        </button>
    </div>
</div>
