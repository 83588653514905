import { UsersStore, UsersState } from '../state/users/users.store';
import { Injectable } from '@angular/core';
import { UsersQuery } from '../state/users/users.query';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DomainService } from '../../core/services/domain.service';
import { User } from '../models/user.model';
import { RequestDto } from '../../../editor/editor/models/dto/request-dto.model';
import { TranslateService } from '@ngx-translate/core';
import { AkitaFiltersPlugin, searchFilterIn } from 'akita-filters-plugin';
import { DateAdapter } from '@angular/material/core';
import { NewUserDto } from '../../../user/models/new-user-dto.model';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(
        private domainService: DomainService,
        private usersStore: UsersStore,
        private usersQuery: UsersQuery,
        private http: HttpClient,
        private adapter: DateAdapter<any>,
        private translateService: TranslateService
    ) {
        this.usersQuery = new UsersQuery(this.usersStore);
    }

    getUsers(): Observable<User[]> {
        this.usersStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/users`;

        const request$ = this.http.get<User[]>(url).pipe(
            tap((users: User[]) => {
                this.usersStore.upsertMany(users);
                this.usersStore.update({ loaded: true });
                this.usersStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
        return this.usersQuery.getHasCache() ? of() : request$;
    }

    getLoggedInUser(): Observable<User> {
        const url = `${this.domainService.apiBaseUrl}/users/me`;

        return this.http.get<User>(url).pipe(
            tap((user) => {
                this.translateService.use(user.locale);
                this.adapter.setLocale(user.locale);

                this.usersStore.setLoggedInUser(user.id);
                this.usersStore.upsert(user.id, user);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateUsers(user, data = null) {
        this.usersStore.setLoading(true);
        data = data ? data : user;
        const url = `${this.domainService.apiBaseUrl}/users/${user.id}`;
        const payload: RequestDto = new RequestDto({}, {}, data);

        return this.http.patch<User>(url, payload.body).pipe(
            tap((user) => {
                this.usersStore.update(user.id, user);
                this.usersStore.setLoading(false);

                if (user.id === this.usersQuery.getLoggedInUserId()) {
                    this.translateService.use(user.locale);
                    this.adapter.setLocale(user.locale);
                }
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateUserPassword(user: User, data: Partial<User>) {
        this.usersStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/users/${user.id}/password`;
        data = data ? data : user;
        const payload: RequestDto = new RequestDto(null, null, data);

        return this.http.put<User>(url, payload.body).pipe(
            tap((user) => {
                this.usersStore.update(user.id, user);
                this.usersStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    updateUserDataProcessingApproval(userId: string) {
        this.usersStore.setLoading(true);

        return this.http
            .post<User>(`${this.domainService.apiBaseUrl}/users/${userId}/data-processing-approvals`, {})
            .pipe(
                tap((user) => {
                    this.usersStore.update(user.id, user);
                    this.usersStore.setLoading(false);
                }),

                catchError((error: any) => throwError(error))
            );
    }

    update2FAMethod(user: User, data: Partial<User>) {
        this.usersStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/users/${user.id}`;

        return this.http.patch<User>(url, data).pipe(
            tap((user) => {
                this.usersStore.update(user.id, user);
                this.usersStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    uploadImage(user: User, image: File) {
        this.usersStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/user/${user.id}/profile-image`;
        const formData = new FormData();
        formData.append('files', image);

        return this.http.post<User>(url, formData).pipe(
            tap((user) => {
                this.usersStore.update(user.id, user);
                this.usersStore.setLoading(false);
            })
        );
    }

    removeImage(user: User) {
        this.usersStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/user/${user.id}/profile-image`;

        return this.http.delete<User>(url).pipe(
            tap((user) => {
                this.usersStore.update(user.id, user);
                this.usersStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    changeViewOption(viewOption: number) {
        this.usersStore.update((userState) => ({
            ui: { ...userState.ui, viewOption },
        }));
    }

    createUser(user: NewUserDto): Observable<any> {
        this.usersStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/users`;

        return this.http.post<User>(url, user).pipe(
            tap((user) => {
                this.usersStore.add(user);
                this.usersStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    activateUser(user, data) {
        this.usersStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/users/${user.id}/enable`;
        const payload: RequestDto = new RequestDto(null, null, data);

        return this.http.patch<User>(url, payload.body).pipe(
            tap((user) => {
                this.usersStore.update(user.id, user);
                this.usersStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    selectAll(userFilters: AkitaFiltersPlugin<UsersState>): Observable<User[]> {
        return userFilters.selectAllByFilters() as Observable<User[]>;
    }

    updateFilter(filterType, value, userFilters: AkitaFiltersPlugin<UsersState>) {
        if (filterType == 'role') {
            this.setRoleFilter(value, userFilters);
        }
        if (filterType == 'state') {
            this.setStateFilter(value, userFilters);
        }
    }

    setRoleFilter(value, userFilters: AkitaFiltersPlugin<UsersState>) {
        userFilters.setFilter({
            id: 'role',
            value,
            predicate: (entity) => entity.roles.includes(value),
        });
    }

    setStateFilter(value, userFilters: AkitaFiltersPlugin<UsersState>) {
        userFilters.setFilter({
            id: 'state',
            value,
            predicate: (entity) => {
                if (value == 'ACTIVE') return entity.enabled;
                else if (value == 'INACTIVE') return !entity.enabled;

                return false;
            },
        });
    }

    removeFilter(id: string, userFilters: AkitaFiltersPlugin<UsersState>) {
        userFilters.removeFilter(id);
    }

    removeAllFilter(userFilters: AkitaFiltersPlugin<UsersState>) {
        userFilters.clearFilters();
    }

    setSearchFilter(filterValue: string, userFilters: AkitaFiltersPlugin<UsersState>) {
        userFilters.setFilter({
            id: 'search',
            value: filterValue,
            predicate: (entity) =>
                searchFilterIn(filterValue, entity, 'username') ||
                searchFilterIn(filterValue, entity, 'firstName') ||
                searchFilterIn(filterValue, entity, 'lastName'),
        });
    }
}
