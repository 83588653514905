import { Injectable } from '@angular/core';
import { SystemInformationState, SystemInformationStore } from './system-information.store';
import { Query } from '@datorama/akita';
import { Language } from '../../models/language.model';
import { KeyValueOption } from '../../models/key-value-option.model';
import { LayoutConfiguration } from '../../models/layout-configuration.model';

@Injectable({
    providedIn: 'root',
})
export class SystemInformationQuery extends Query<SystemInformationState> {
    clientVersion$ = this.select((state) => state.clientVersion);
    version$ = this.select((state) => state.version);

    constructor(protected override store: SystemInformationStore) {
        super(store);
    }

    public getLanguages(): Map<string, Language> {
        const systemInformation = this.getValue();
        if (systemInformation.languages === null) {
            return new Map();
        }

        const sortedLanguages = systemInformation.languages.sort((a, b) => a.name.localeCompare(b.name));

        return new Map<string, Language>(sortedLanguages.map((language) => [language.locale, language]));
    }

    public getLanguageOptions(): KeyValueOption[] {
        const languageOptions: KeyValueOption[] = [];
        this.getLanguages().forEach((language) => {
            languageOptions.push({ key: language.locale, value: language.name });
        });
        return languageOptions;
    }

    public getLayouts(): Map<string, LayoutConfiguration> {
        const systemInformation = this.getValue();
        if (systemInformation.layouts === null) {
            return new Map();
        }

        const sortedLayouts = Object.values(systemInformation.layouts).sort((a, b) => a.name.localeCompare(b.name));

        return new Map<string, any>(sortedLayouts.map((layout) => [layout.key, layout]));
    }

    public getLayoutOptions(): KeyValueOption[] {
        const layoutOptions: KeyValueOption[] = [];
        this.getLayouts().forEach((layout) => {
            layoutOptions.push({ key: layout.key, value: layout.name });
        });
        return layoutOptions;
    }
}
