<div class="container property-editor-container">
    <ng-container *ngIf="loaded$ | async">
        <div class="form-data">
            <div class="form-title">
                <elias-shared-module-title
                    title="{{ 'propertyEditor.widgetTitle' | translate }}"
                    [buttonFlexLayout]="'end'"
                    value="itemCustom">
                    <button *ngIf="isDelete" mat-icon-button [matMenuTriggerFor]="menu" class="menu-button-icon">
                        <i class="ei ei-more-vert"></i>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="remove()">
                            <i class="ei ei-trash"></i>{{ 'actions.delete' | translate }}
                        </button>
                    </mat-menu>
                </elias-shared-module-title>
            </div>
            <div>
                <elias-form
                    [formConfig]="config"
                    translationKey="propertyEditor"
                    [data]="newData"
                    [hideButtons]="true"
                    (formSubmitted)="onFormSubmitted($event)"
                    #form></elias-form>
            </div>
        </div>

        <elias-form-side-nav-buttons
            [forms]="[form]"
            [isSaving]="isSaving"
            (formsCancelled)="close()"></elias-form-side-nav-buttons>
    </ng-container>
</div>
