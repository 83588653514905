import { Injectable } from '@angular/core';
import { CommentsStore, CommentsTarget } from '../state/comments.store';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { withTransaction } from '@datorama/akita';
import { DomainService } from '../../../modules/core/services/domain.service';
import { CommentTargetType } from '../enums/comment-target-type.enum';
import { CommentNormalized } from '../models/comment.model';
import { CommentResolvedResponse } from '../models/comment-resolved-response.model';
import { NodesStore } from '../../editor/state/nodes/nodes.store';
import { SectionsStore } from '../../editor/state/sections/sections.store';
import { CommentsQuery } from '../state/comments.query';
import { QueryParameters } from '../../../modules/core/models/query-parameters.model';
import { QueryParametersBuilder } from '../../../modules/core/builders/query-parameters.builder';
import { FilterOperators } from '../../../modules/core/enums/filter-operators.enum';
import { PaginatedComments } from '../models/paginated-comments.model';
import { CommentEditorOutputDto } from '../models/comment-editor-output-dto.model';
import { UsersQuery } from '../../../modules/shared/state/users/users.query';
import { PublicationsStore } from '../../../publication/state/publications/publications.store';

@Injectable({
    providedIn: 'root',
})
export class CommentsService {
    constructor(
        private commentsQuery: CommentsQuery,
        private commentsStore: CommentsStore,
        private domainService: DomainService,
        private http: HttpClient,
        private nodesStore: NodesStore,
        private publicationsStore: PublicationsStore,
        private sectionsStore: SectionsStore,
        private usersQuery: UsersQuery
    ) {}

    getCommentsInPublication(publicationId: string, resetStore: boolean = true): Observable<PaginatedComments> {
        this.commentsStore.setLoadingNextPage(true);
        const url = `${this.domainService.apiBaseUrl}/publications/${publicationId}/comments`;

        const options = this.getCurrentRequestOptions();

        return this.http.get<PaginatedComments>(url, { params: options }).pipe(
            withTransaction((comments) => {
                this.commentsStore.update({
                    currentPage: comments.currentPage,
                    page: null,
                    totalNumberOfPages: comments.totalNumberOfPages,
                    totalDataLength: comments.totalDataLength,
                    additionalData: comments.additionalData,
                    hasNextPage: comments.hasNextPage,
                });

                if (resetStore) {
                    this.commentsStore.set(comments.data);
                } else {
                    this.commentsStore.add(comments.data);
                }
                this.commentsStore.setLoadingNextPage(false);
                this.commentsStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getComments(type: CommentTargetType, targetId: string): Observable<CommentNormalized[]> {
        this.commentsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/${type}s/${targetId}/comments`;
        return this.http.get<CommentNormalized[]>(url).pipe(
            withTransaction((comments) => {
                this.commentsStore.set(comments);
                this.commentsStore.setLoading(false);
                this.commentsStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createComment(target: CommentsTarget, newComment: CommentEditorOutputDto): Observable<CommentNormalized> {
        const { id, type } = target;
        const url = `${this.domainService.apiBaseUrl}/${type}s/${id}/comments`;

        this.commentsStore.setLoading(true);

        return this.http.post<CommentNormalized>(url, { ...newComment }).pipe(
            withTransaction((comment) => {
                this.commentsStore.add(comment);
                this.commentsStore.setLoading(false);
                this.commentsStore.update({ loaded: true });

                this.updateHasUnresolvedCommentsOnTarget(target, true);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createReply(commentId: string, newReply: CommentEditorOutputDto): Observable<CommentNormalized> {
        this.commentsStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/comments/${commentId}/replies`;

        return this.http.post<CommentNormalized>(url, { ...newReply }).pipe(
            withTransaction((comment) => {
                this.commentsStore.update(commentId, comment);
                this.commentsStore.setLoading(false);
                this.commentsStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }

    resolveComment(commentId: string): Observable<CommentResolvedResponse> {
        const url = `${this.domainService.apiBaseUrl}/comments/${commentId}/resolves`;
        const target = this.commentsStore.getValue().target;
        const loggedInUserId = this.usersQuery.getLoggedInUserId() as string;

        this.commentsStore.setLoading(true);

        return this.http.put<CommentResolvedResponse>(url, { resolved: 1 }).pipe(
            tap(({ hasUnresolvedComments }) => {
                this.commentsStore.update(commentId, { resolvedAt: new Date(), resolvedBy: loggedInUserId });
                this.commentsStore.setLoading(false);
                this.commentsStore.update({ loaded: true });

                this.updateHasUnresolvedCommentsOnTarget(target, hasUnresolvedComments);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    unresolveComment(commentId: string): Observable<CommentResolvedResponse> {
        const url = `${this.domainService.apiBaseUrl}/comments/${commentId}/resolves`;
        const target = this.commentsStore.getValue().target;

        this.commentsStore.setLoading(true);

        return this.http.put<CommentResolvedResponse>(url, { resolved: 0 }).pipe(
            tap(({ hasUnresolvedComments }) => {
                this.commentsStore.update(commentId, { resolvedAt: null, resolvedBy: null });
                this.commentsStore.setLoading(false);
                this.commentsStore.update({ loaded: true });

                this.updateHasUnresolvedCommentsOnTarget(target, hasUnresolvedComments);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    private updateHasUnresolvedCommentsOnTarget(target: CommentsTarget | null, hasUnresolvedComments: boolean): void {
        if (target === null) {
            return;
        }

        if (target.type === CommentTargetType.Node) {
            this.nodesStore.update(target.id, { hasUnresolvedComments });
        }

        this.sectionsStore.update(target.id, { hasUnresolvedComments });
    }

    private getCurrentRequestOptions(): QueryParameters {
        const queryParametersBuilder = new QueryParametersBuilder();

        this.addFiltersToQueryBuilder(queryParametersBuilder);
        this.addSortingToQueryBuilder(queryParametersBuilder);
        this.addPageToQueryBuilder(queryParametersBuilder);

        return queryParametersBuilder.getQueryOptions();
    }

    private addSortingToQueryBuilder(builder: QueryParametersBuilder): void {
        const { property, direction } = this.commentsQuery.getSorting();
        builder.addSorting(property, direction);
    }

    private addPageToQueryBuilder(builder: QueryParametersBuilder): void {
        const page = this.commentsQuery.getNextPage();
        builder.addPage(page);
    }

    private addFiltersToQueryBuilder(builder: QueryParametersBuilder): void {
        const filters = this.commentsQuery.getFilters();
        for (const [key, value] of Object.entries(filters)) {
            if (key === 'resolvedAt') {
                this.addResolvedAtFilter(value, builder);
            } else {
                builder.addFilter(key, value);
            }
        }
    }

    private addResolvedAtFilter(value: string | null, builder: QueryParametersBuilder): void {
        if (value === 'resolved') {
            builder.addFilter('resolvedAt', '', FilterOperators.NotEqual);
        } else if (value === 'unresolved') {
            builder.addFilter('resolvedAt', '');
        }
    }
}
