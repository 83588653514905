import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class NumberValidator {
    static createNumberValidator(acceptDecimal: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value as string;

            if (!value) {
                return null;
            }

            const isNumber = !isNaN(Number(value));

            if (acceptDecimal) {
                return isNumber ? null : { numberValue: true };
            }

            const isNumberWithoutDecimal = Number.isInteger(Number(value)) && !value.includes('.');

            return isNumberWithoutDecimal ? null : { numberValue: true };
        };
    }
}
