import { AfterViewInit, Component, Injector, Input, NgZone, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';

import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/hr';
import 'tinymce/icons/default';
import '../../../../../../assets/tinymce/plugins/colbreak/index';
import { environment } from '../../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { UsersQuery } from '../../../../shared/state/users/users.query';
import { User } from '../../../../shared/models/user.model';

@Component({
    selector: 'elias-form-element-textarea',
    templateUrl: './form-textarea.component.html',
    styleUrls: ['./form-textarea.component.scss'],
})
export class FormTextareaComponent extends BasicFormElementComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() settings;

    rows = 4;
    content;
    inlineEditor: typeof tinymce;
    active = false;
    localContent;
    containerId = 'tinymce';
    currLang = '';
    currLangUrl = '';

    setup: object = {
        setup: (editor) => {
            this.inlineEditor = editor;

            const handler = (event: Event) => {
                // event.stopPropagation();
                this.zone.runOutsideAngular(() => {
                    this.onContentChange();
                });
            };

            editor.on('keyup', handler);
            editor.on('execCommand', handler);
            editor.on('mouseout', handler);
        },
    };

    constructor(
        protected injector: Injector,
        private sanitizer: DomSanitizer,
        private usersQuery: UsersQuery,
        private zone: NgZone
    ) {
        super(injector);
    }

    ngAfterViewInit(): void {
        if (this.defaultSettings() !== undefined) {
            const settings = Object.assign(this.defaultSettings(), this.setup);
            if (this.currLang !== 'en_US') {
                settings['language'] = this.currLang;
                settings['language_url'] = this.currLangUrl;
            }
            tinymce.init(settings);
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.containerId = 'tinymce-' + this.name;
        if (Object.keys(this.settings).length > 0) {
            if (this.settings['enableRichText']) {
                this.active = true;
                this.localContent = this.sanitizer.bypassSecurityTrustHtml(this.formGroup.get(this.name).value);
            }
        }
        this.currLang = (this.usersQuery.getLoggedInUser() as User).locale;
        this.currLangUrl = environment.assetsRootPath + 'tinymce/js/langs/' + this.currLang + '.js';
    }

    ngOnDestroy(): void {
        tinymce.remove('#' + this.containerId);
    }

    defaultSettings() {
        if (Object.keys(this.settings).length > 0) {
            if (this.settings['enableRichText']) {
                const plugins = this.settings['plugins'] ?? ['lists', 'paste', 'nonbreaking', 'link', 'hr', 'colbreak'];
                const toolbar =
                    this.settings['toolbar'] ??
                    'bold italic underline | link | subscript superscript | numlist bullist | nonbreaking | backcolor';
                return {
                    selector: '#' + this.containerId,
                    menubar: false,
                    branding: false,
                    statusbar: false,
                    auto_focus: this.containerId,
                    skin_url: environment.assetsRootPath + 'tinymce/skins/ui/oxide',
                    content_css: environment.assetsRootPath + 'tinymce/skins/content/default/content.css',
                    // theme_url: environment.assetsRootPath + 'tinymce/themes/inlite/theme.min.js',
                    plugins: plugins,
                    toolbar: toolbar,
                    // quickbars_selection_toolbar: 'bold italic underline | link | subscript superscript | numlist bullist | nonbreaking | hr',
                    /* forced_root_block : false,
                    force_br_newlines : false,
                    force_p_newlines : false,
                    convert_newlines_to_brs : false,
                    remove_linebreaks : false,*/
                    indent: false,
                    default_link_target: '_blank',
                    link_default_protocol: 'https',
                    link_title: false,
                };
            } else {
                return {};
            }
        }
    }

    onContentChange() {
        this.zone.run(() => {
            this.content = this.inlineEditor.getContent({
                format: 'html',
            });
            this.content = this.escapeChar(this.content);
            this.formGroup.get(this.name).setValue(this.content);
            this.localContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
        });
    }

    getSanitizedData(val) {
        return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(val));
    }

    escapeChar(val) {
        if (val.includes('style')) {
            val = val.replace(/"/g, "'");
            return val;
        } else {
            return val;
        }
    }
}
