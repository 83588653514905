<div class="login-form">
    <img src="../../../../../assets/logo.png" class="logo" />

    <div class="spacer-2x"></div>

    <h1>{{ 'logout.header' | translate }}</h1>
    <p>{{ 'logout.description' | translate }}</p>
</div>

<div class="image" [ngStyle]="{ 'background-image': homeImage }"></div>
