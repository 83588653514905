import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { FullscreenAssetBrowserComponent } from './pages/fullscreen-asset-browser/fullscreen-asset-browser.component';
import { AssetBrowserDataResolver } from './resolvers/asset-browser-data.resolver';
import { AssetBrowserDeepLinksResolver } from './resolvers/asset-browser-deep-links.resolver';

const routes: Route[] = [
    {
        path: '',
        component: FullscreenAssetBrowserComponent,
        resolve: [AssetBrowserDataResolver, AssetBrowserDeepLinksResolver],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AssetBrowserRoutingModule {}
