import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { AssetStoragesState, AssetStoragesStore } from './asset-storages.store';
import { AssetStorage } from '../../models/asset-storage.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetStorageType } from '../../enums/asset-storage-type.enum';

@Injectable({
    providedIn: 'root',
})
@QueryConfig({
    sortBy: 'type',
    sortByOrder: Order.ASC, // Order.DESC
})
export class AssetStoragesQuery extends QueryEntity<AssetStoragesState, AssetStorage> {
    constructor(protected override store: AssetStoragesStore) {
        super(store);
    }

    selectAssetStorageTree(): Observable<any> {
        return this.selectAll().pipe(
            map((storages) => {
                const hashTable = Object.create(null);
                const sharedHashTable = Object.create(null);
                const dataTree = [];

                let allassets = {
                    id: 'allAssets',
                    name: 'allAssets',
                    type: 'allAssets',
                    default: true,
                    typeDiscriminator: 'allAssets',
                };
                hashTable['allAssets'] = { ...allassets, children: [] };
                dataTree.push(hashTable['allAssets']);

                for (const storage of storages) {
                    if (storage.type == AssetStorageType.Shared) {
                        sharedHashTable[storage.id] = { ...storage, children: [] };
                    } else {
                        hashTable[storage.id] = { ...storage, children: [] };
                    }
                }

                for (const storage of storages) {
                    if (storage.type === AssetStorageType.Shared) {
                        const options = {
                            id: 'parentShared',
                            name: 'parentShared',
                            type: 'parentshared',
                            default: false,
                            typeDiscriminator: 'parentShared',
                        };
                        hashTable['parentShared'] = { ...options, children: [] };
                        break;
                    }
                }

                for (const storage of storages) {
                    if (hashTable['parentShared']) {
                        if (storage.type === AssetStorageType.Shared) {
                            hashTable['parentShared'].children.push(sharedHashTable[storage.id]);
                        }
                    }
                }

                for (const storage of storages) {
                    if (storage.type !== AssetStorageType.Shared) {
                        dataTree.push(hashTable[storage.id]);
                    }
                }

                if (hashTable['parentShared']) {
                    dataTree.push(hashTable['parentShared']);
                }
                return dataTree;
            })
        );
    }
}
