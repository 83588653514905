import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface MaintenanceStatus {
    inMaintenance: boolean;
    isWhitelisted: boolean;
    showScheduledWarning: boolean;
    scheduledFor: string | null;
    warning?: string;
    warningDe?: string;
    scheduledWarning?: string;
    scheduledWarningDe?: string;
}

export interface Status {
    maintenance: MaintenanceStatus;
    version: string;
}

export interface StatusState extends Status {
    loaded: boolean;
}

export function createInitialState(): Partial<StatusState> {
    return {
        maintenance: {
            inMaintenance: false,
            isWhitelisted: false,
            showScheduledWarning: false,
            scheduledFor: null,
        },
        loaded: false,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Status', resettable: false })
export class StatusStore extends Store<StatusState> {
    constructor() {
        super(createInitialState());
    }
}
