<elias-base-comment
    [comment]="reply"
    (commentClicked)="onReplyClicked()"
    [ngClass]="{ 'resolved-comment': isResolved, 'default-action': hasDefaultAction }">
    <ng-container actions>
        <elias-reply-action-readonly
            [reply]="reply"
            [showControls]="showControls"
            (replyLinkClicked)="onReplyLinkClicked()"
            *ngIf="status === CommentStatusEnum.ReadOnly"></elias-reply-action-readonly>
    </ng-container>
</elias-base-comment>
