<div
    class="content"
    [ngClass]="{
        'content-collapsed': collapsed && offsetHeight > contentHeight
    }"
    [style.max-height]="collapsed ? contentHeight + 'px' : offsetHeight + 'px'">
    <div #contentElement class="inner-content">
        <ng-content></ng-content>
    </div>
</div>
<div class="button-container">
    <button
        class="see-more-button"
        [ngStyle]="{ display: offsetHeight > contentHeight ? 'block' : 'none' }"
        *ngIf="collapsed"
        mat-button
        type="button"
        (click)="toggleCollapse($event)">
        {{ 'shared.collapsibleContent.seeMore' | translate }}
    </button>
    <button class="see-less-button" *ngIf="!collapsed" mat-button type="button" (click)="toggleCollapse($event)">
        {{ 'shared.collapsibleContent.seeLess' | translate }}
    </button>
</div>
