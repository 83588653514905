import { Injectable } from '@angular/core';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { SectionsQuery } from '../../state/sections/sections.query';
import { PropertyEditorComponent } from '../../components/property-editor/property-editor.component';

@Injectable({
    providedIn: 'root',
})
export class SectionSettingsSidenavService {
    constructor(private sideNavService: SideNavService, private sectionsQuery: SectionsQuery) {}

    public async open(sectionId?: string) {
        sectionId ??= this.sectionsQuery.getActiveId();

        const inputs = {
            sectionOrNodeType: 'section',
            sectionId: sectionId,
            nodeId: sectionId,
        };

        const outputs = {};
        await this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
    }
}
