import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

type LocalizedDateFormat =
    | 'eliasFullDate'
    | 'eliasMediumDate'
    | 'eliasVerboseDateTime'
    | 'eliasMediumDateTime'
    | 'eliasShortDateTime'
    | 'eliasShortDateTimeSeconds';

const ELIAS_DATE_TIME_FORMATS: { [k in LocalizedDateFormat]: { [l: string]: string } } = {
    eliasFullDate: {
        en: 'd MMMM yyyy', // 8 February 2023
        de: 'd. MMMM yyyy', // 8. Februar 2023
    },
    eliasMediumDate: {
        en: 'd MMM y', // 8 Feb 2023
        de: 'dd.MM.y', // 08.02.2023
    },
    eliasVerboseDateTime: {
        en: 'EEEE, d MMMM y - h:mm a', // Wednesday, 8 February 2023 - 3:10 PM
        de: 'EEEE, d. MMMM y - H:mm', // Mittwoch, 8. Februar 2023 - 15:10
    },
    eliasMediumDateTime: {
        en: 'd MMM y h:mm a', // 8 Feb 2023 3:10 PM
        de: 'd. MMM y HH:mm', // 8. Feb. 2023 15:10
    },
    eliasShortDateTime: {
        en: 'dd.MM.y - h:mm a', // 08.02.2023 - 3:10 PM
        de: 'dd.MM.y - HH:mm', // 08.02.2023 - 15:10
    },
    eliasShortDateTimeSeconds: {
        en: 'dd.MM.yyyy h:mm:ss a', // 08.02.2023 3:10:27 PM
        de: 'dd.MM.yyyy HH:mm:ss', // 08.02.2023 15:10:27
    },
};

@Pipe({
    name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(
        value: Date | string,
        format: LocalizedDateFormat = 'eliasMediumDate',
        timezone = ''
    ): Date | string | number | null | undefined {
        const formattedLocale = this.getFormattedLocale(this.translateService.currentLang);
        const shortLocale = this.getShortLocale(formattedLocale);

        const dateFormat = ELIAS_DATE_TIME_FORMATS[format][shortLocale] as string;
        const datePipe = new DatePipe(formattedLocale, timezone);

        return datePipe.transform(value, dateFormat, timezone, formattedLocale);
    }

    private getFormattedLocale(locale: string): string {
        return locale.replace('_', '-');
    }

    private getShortLocale(locale: string): string {
        return locale.slice(0, 2);
    }
}
