<elias-core-toolbar></elias-core-toolbar>
<div class="loader">
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loading$ | async"></mat-progress-bar>
</div>
<mat-sidenav-container class="main-sidenav-container">
    <mat-sidenav #mainnav mode="over" position="end" class="main-sidenav">
        <elias-core-main-navigation></elias-core-main-navigation>
    </mat-sidenav>
    <mat-sidenav-content class="main-sidenav-content">
        <mat-sidenav-container class="sidenav-container" (backdropClick)="close()">
            <mat-sidenav #sidenav mode="over" position="end" class="sidenav" (closedStart)="onClosedStart()">
                <ng-container #content></ng-container>
            </mat-sidenav>
            <mat-sidenav-content class="sidenav-content">
                <router-outlet> </router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </mat-sidenav-content>
</mat-sidenav-container>
