import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsersQuery } from '../../../modules/shared/state/users/users.query';

type ParserFunction = (html: string) => string;

@Injectable({
    providedIn: 'root',
})
export class CommentHtmlParserService {
    constructor(private translateService: TranslateService, private usersQuery: UsersQuery) {}

    public parseHtmlForDisplaying(html: string): string {
        const parsers: ParserFunction[] = [this.replaceUserMentionsParser, this.replaceGroupMentionsParser];

        return [...parsers].reduce((h, func) => func(h), html);
    }

    public parseHtmlForSaving(html: string): string {
        const parsers: ParserFunction[] = [this.removeMentionLabelsParser];

        return [...parsers].reduce((h, func) => func(h), html);
    }

    private removeMentionLabelsParser = (html: string): string => {
        const parserEl = document.createElement('div');
        parserEl.innerHTML = html;

        const mentions = parserEl.querySelectorAll('[data-type="mention"]');
        mentions.forEach((mention) => {
            mention.removeAttribute('data-label');
            mention.textContent = '';
        });

        return parserEl.innerHTML;
    };

    private replaceUserMentionsParser = (html: string): string => {
        const parserEl = document.createElement('div');
        parserEl.innerHTML = html;

        const mentions = parserEl.querySelectorAll('[data-type="mention"]');
        mentions.forEach((mention) => {
            const id = mention.getAttribute('data-id');
            const mentionType = mention.getAttribute('data-mention-type');

            if (mentionType !== 'user') {
                return;
            }

            const user = this.usersQuery.getEntity(id);

            if (!user) {
                return;
            }

            const label = `${user.firstName} ${user.lastName}`;

            mention.setAttribute('data-label', label);
            mention.textContent = `@${label}`;
        });

        return parserEl.innerHTML;
    };

    private replaceGroupMentionsParser = (html: string): string => {
        const parserEl = document.createElement('div');
        parserEl.innerHTML = html;

        const mentions = parserEl.querySelectorAll('[data-type="mention"]');
        mentions.forEach((mention) => {
            const id = mention.getAttribute('data-id');
            const mentionType = mention.getAttribute('data-mention-type');

            if (mentionType !== 'group') {
                return;
            }

            const label = this.translateService.instant(`comments.mentions.groups.${id}`);

            mention.setAttribute('data-label', label);
            mention.textContent = `@${label}`;
        });

        return parserEl.innerHTML;
    };
}
