import { Injectable } from '@angular/core';
import { StatusState, StatusStore } from './status.store';
import { Query } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StatusQuery extends Query<StatusState> {
    public showMaintenanceScheduledWarning$ = this.select((state) => state.maintenance.showScheduledWarning);
    public maintenanceScheduledWarning$ = this.selectMaintenanceScheduledWarning();
    public maintenanceWarning$ = this.selectMaintenanceWarning();
    public inMaintenance$ = this.select((state) => state.maintenance.inMaintenance);
    public isWhitelistedAtMaintenance$ = this.select((state) => state.maintenance.isWhitelisted);
    public maintenanceScheduledFor$ = this.select((state) => state.maintenance.scheduledFor);

    constructor(protected override store: StatusStore, private translateService: TranslateService) {
        super(store);
    }

    private selectMaintenanceScheduledWarning(): Observable<string | undefined> {
        return this.select(({ maintenance }) => {
            const lang = this.translateService.currentLang.toLowerCase();
            const isGerman = lang.startsWith('de');

            return isGerman ? maintenance.scheduledWarningDe : maintenance.scheduledWarning;
        });
    }

    private selectMaintenanceWarning(): Observable<string | undefined> {
        return this.select(({ maintenance }) => {
            const lang = this.translateService.currentLang.toLowerCase();
            const isGerman = lang.startsWith('de');

            return isGerman ? maintenance.warningDe : maintenance.warning;
        });
    }
}
