import { Injectable } from '@angular/core';
import { LinksGeneratorRegistry } from '../generators/links-generator.registry';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';

@Injectable({
    providedIn: 'root',
})
export abstract class LinksService<T> {
    public generate(context: T): any[] | null {
        const provider = this.createLinksProvider(context);

        for (const generator of LinksGeneratorRegistry.getGenerators()) {
            if (generator.canGenerate(provider)) {
                return generator.generate(provider);
            }
        }

        return null;
    }

    public abstract createLinksProvider(context: T): LinksProviderInterface;
}
