<div class="filters">
    <elias-shared-filter-dropdown
        class="filter-dropdown"
        title="{{ 'comments.filter.createdBy.title' | translate }}"
        [options]="createdByFilterOptions$ | async"
        (changeValue)="onChangeFilterValue('createdBy', $event)"></elias-shared-filter-dropdown>
    <elias-shared-filter-dropdown
        class="filter-dropdown"
        title="{{ 'comments.filter.resolvedAt.title' | translate }}"
        [options]="resolvedAtFilterOptions"
        (changeValue)="onChangeFilterValue('resolvedAt', $event)"></elias-shared-filter-dropdown>
    <elias-shared-sorting-dropdown
        class="sorting-dropdown"
        title="{{ 'comments.sorting.defaultOption' | translate }}"
        defaultOptionText="{{ 'comments.sorting.createdAt' | translate }}"
        [options]="sortOptions"
        (changeValue)="onChangeSortingValue($event)"></elias-shared-sorting-dropdown>
</div>
