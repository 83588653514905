import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavService } from '../../services/side-nav.service';
import { MainSideNavService } from '../../services/main-side-nav.service';
import { AutoLogoutService } from '../../../../auth/service/auto-logout.service';
import { UpdateService } from '../../services/update.service';
import { MaintenanceService } from '../../../shared/services/maintenance.service';
import { StatusUpdateListener } from '../../../shared/services/status-update-listener.service';

@Component({
    selector: 'elias-core-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit, AfterViewInit, OnDestroy {
    loading$: Observable<boolean>;
    updatePollSubscription: Subscription;

    @ViewChild('sidenav') public sidenav: MatSidenav;
    @ViewChild('mainnav') public mainnav: MatSidenav;
    @ViewChild('content', { static: true, read: ViewContainerRef }) private vcf: ViewContainerRef;

    constructor(
        private autoLogoutService: AutoLogoutService,
        private mainSideNavService: MainSideNavService,
        private maintenanceService: MaintenanceService,
        private router: Router,
        private sideNavService: SideNavService,
        private statusUpdateListener: StatusUpdateListener,
        private updateService: UpdateService
    ) {
        this.sideNavService.setSidenav(this.sidenav);
        this.mainSideNavService.setSidenav(this.mainnav);
        this.sideNavService.setContentVcf(this.vcf);

        this.updatePollSubscription = this.updateService.updatePoll$.subscribe();

        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading$ = of(true);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    if (this.sidenav !== undefined && this.sidenav.opened) {
                        this.sideNavService.close();
                    }
                    if (this.mainnav !== undefined && this.mainnav.opened) {
                        this.mainSideNavService.close();
                    }
                    this.loading$ = of(false);
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    ngOnInit(): void {
        this.autoLogoutService.autoLogout$.subscribe();
        this.maintenanceService.incomingMaintenance$.subscribe();
        this.maintenanceService.maintenanceLogout$.subscribe();
        this.statusUpdateListener.statusUpdate$.subscribe();

        this.sideNavService.setContentVcf(this.vcf);
    }

    ngAfterViewInit(): void {
        this.sideNavService.setSidenav(this.sidenav);
        this.mainSideNavService.setSidenav(this.mainnav);
        this.sideNavService.setContentVcf(this.vcf);
    }

    close(): void {
        this.sideNavService.close();
    }

    onClosedStart() {
        this.sideNavService.destroyComponent();
    }

    ngOnDestroy() {
        this.updatePollSubscription.unsubscribe();
    }
}
