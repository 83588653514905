import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UsersQuery } from '../state/users/users.query';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';

type UniqueOptions = { action: 'create' | 'update' };

@Injectable({ providedIn: 'root' })
export class UserDetailValidator {
    constructor(private usersQuery: UsersQuery) {}

    uniqueUsername(options: UniqueOptions, control: AbstractControl): ValidationErrors | boolean | null {
        const value = control.value as String;
        const users = this.usersQuery.getAll({ filterBy: (entity) => entity.username === value });
        const loggedInUser = this.usersQuery.getLoggedInUser();
        const activeUser = this.usersQuery.getActive() as User | undefined;
        const action = (options.action as string) ?? null;

        if (activeUser && activeUser.username === value) {
            return null;
        }

        if (activeUser === undefined && loggedInUser?.username === value && action !== 'create') {
            return null;
        }

        if (users.length > 0) {
            return { username: true };
        }

        return null;
    }

    uniqueEmail(options: UniqueOptions, control: AbstractControl): ValidationErrors | boolean | null {
        const value = control.value as String;
        const users = this.usersQuery.getAll({ filterBy: (entity) => entity.email === value });
        const loggedInUser = this.usersQuery.getLoggedInUser();
        const activeUser = this.usersQuery.getActive() as User | undefined;
        const action = (options.action as string) ?? null;

        if (activeUser && activeUser.email === value) {
            return null;
        }

        if (activeUser === undefined && loggedInUser?.email === value && action !== 'create') {
            return null;
        }

        if (users.length > 0) {
            const user = users[0];
            return { uniqueemail: true, existsUserFullName: user.firstName + ' ' + user.lastName };
        }

        return null;
    }

    passwordMatch(control: AbstractControl): ValidationErrors | boolean | null {
        if (control.parent) {
            if (control.parent.controls['password'].value == control.parent.controls['repeatPassword'].value) {
                return null;
            }
        }
        return { passwordmatch: true };
    }
}
