import { Query } from '@datorama/akita';
import { PreviewState, PreviewStore } from './preview.store';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PreviewQuery extends Query<PreviewState> {
    constructor(protected override store: PreviewStore) {
        super(store);
    }
}
