<div class="scrollable-container">
    <div class="container">
        <div class="comment-editor" #commentEditor cdkFocusInitial></div>

        <div fxLayout="row" class="action-buttons" fxLayoutGap="10%" *ngIf="hasContentToSubmit$ | async" @slideInOut>
            <button color="accent" class="btn-save" mat-flat-button (click)="submitComment()">
                <ng-container *ngIf="action === 'reply'; else comment">
                    {{ 'comments.actions.answer' | translate }}
                </ng-container>
                <ng-template #comment>
                    {{ 'comments.actions.comment' | translate }}
                </ng-template>
            </button>
            <button class="btn-cancel" mat-button (click)="cancel()">{{ 'actions.cancel' | translate }}</button>
        </div>
    </div>
</div>
