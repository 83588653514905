import { Component } from '@angular/core';

@Component({
    selector: 'elias-shared-sidebar-right',
    styleUrls: ['sidebar-right.component.scss'],
    template: ' <ng-content></ng-content>',
})
export class SidebarRightComponent {
    constructor() {}
}
