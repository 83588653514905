import { LinksGeneratorRegistry } from './links-generator.registry';
import { LinksGeneratorInterface } from '../interfaces/links-generator.interface';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';
import { PublicationGroupLinksProvider } from '../providers/publication-group-links.provider';

@LinksGeneratorRegistry.register
export class PublicationGroupLinksGenerator implements LinksGeneratorInterface {
    canGenerate(provider: LinksProviderInterface): provider is PublicationGroupLinksProvider {
        return provider instanceof PublicationGroupLinksProvider;
    }

    generate(provider: LinksProviderInterface): any[] | null {
        if (!this.canGenerate(provider)) {
            return null;
        }

        const { publicationGroupId } = provider.getParameters();

        return ['/publication-groups', publicationGroupId];
    }
}
