import { Component, Input, OnInit } from '@angular/core';
import { VersionDiff } from '../../models/version-diff.model';
@Component({
    selector: 'elias-version-diff',
    templateUrl: './version-diff.component.html',
})
export class VersionDiffComponent implements OnInit {
    @Input() diff?: VersionDiff;
    @Input() condensedView: boolean = true;

    public propertiesDiff: VersionDiff = {};

    constructor() {}

    ngOnInit(): void {
        if (this.diff) {
            this.propertiesDiff = this.filterOutContentDiff(this.diff);
        }
    }

    private filterOutContentDiff(diff: VersionDiff): VersionDiff {
        return Object.keys(diff).reduce((versionDiff, key) => {
            if (key !== 'content') {
                versionDiff[key] = diff[key];
            }
            return versionDiff;
        }, {} as VersionDiff);
    }
}
