import { LinksProviderInterface } from '../interfaces/links-provider.interface';

export class NodeLinksProvider implements LinksProviderInterface {
    constructor(
        private publicationGroupId: string,
        private publicationId: string,
        private sectionId: string,
        private nodeId: string
    ) {}

    public getParameters() {
        return {
            publicationGroupId: this.publicationGroupId,
            publicationId: this.publicationId,
            sectionId: this.sectionId,
            nodeId: this.nodeId,
        };
    }
}
