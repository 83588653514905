import { LinksProviderInterface } from '../interfaces/links-provider.interface';

export class AssetLinksProvider implements LinksProviderInterface {
    constructor(private publicationGroupId: string, private publicationId: string, private assetId: string) {}

    public getParameters() {
        return {
            publicationGroupId: this.publicationGroupId,
            publicationId: this.publicationId,
            assetId: this.assetId,
        };
    }
}
