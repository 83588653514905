<div class="modal-container" style="height: 471px">
    <h1 mat-dialog-title [ngStyle]="{ 'background-color': type === 'creation' ? '#e8af56' : '#ff2654' }">
        {{ 'modal.error.' + type + '.title' | translate }}
    </h1>
    <div mat-dialog-content class="modal-content">
        <p>{{ 'modal.error.' + type + '.message' | translate }}</p>
    </div>
    <div mat-dialog-actions class="modal-action-buttons">
        <button mat-stroked-button color="warn" (click)="onConfirm()" [ngStyle]="{ 'margin-left': '250px' }">
            {{ 'actions.confirm' | translate }}
        </button>
    </div>
</div>
