import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { NodePreset } from '../../models/node-preset.model';

export interface NodePresetsState extends EntityState<NodePreset> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'NodePresets', idKey: 'type', resettable: true })
export class NodePresetsStore extends EntityStore<NodePresetsState, NodePreset> {
    constructor() {
        super({ loaded: false });
    }
}
