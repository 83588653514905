import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface UiState {
    toolbar: {
        state: string;
    };

    nodeHeight: number;
}

export function createInitialState(): UiState {
    return {
        toolbar: {
            state: 'overview',
        },
        nodeHeight: 100,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Ui' })
export class UiStore extends Store<UiState> {
    constructor() {
        super(createInitialState());
    }
}
