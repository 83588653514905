import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules/core/pages/not-found/not-found.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { ContainerComponent } from './modules/core/components/container/container.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { InitDataResolver } from './modules/shared/resolvers/init-data.resolver';
import { Roles } from './modules/shared/enums/roles.enum';
import { DisabledUserComponent } from './modules/core/pages/disabled-user/disabled-user.component';
import { AccessDeniedComponent } from './modules/core/pages/access-denied/access-denied.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { LogoutComponent } from './modules/core/pages/logout/logout.component';
import { LoginResolver } from './auth/resolvers/login.resolver';

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        resolve: [LoginResolver],
    },
    {
        path: '',
        component: ContainerComponent,
        resolve: [InitDataResolver],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'publications',
                redirectTo: '/publication-groups',
                pathMatch: 'full',
            },
            {
                path: '',
                redirectTo: '/publication-groups',
                pathMatch: 'full',
            },
            {
                path: 'publication-groups',
                loadChildren: () =>
                    import('./publication-group/publication-group.module').then((mod) => mod.PublicationGroupModule),
                data: {
                    breadcrumb: {
                        title: 'core.toolbar.publications',
                    },
                },
            },
            {
                path: 'users',
                loadChildren: () => import('./user/user.module').then((mod) => mod.UserModule),
                data: {
                    expectedRole: Roles.ROLE_ADMIN,
                    preload: false,
                    breadcrumb: {
                        title: 'core.toolbar.users',
                    },
                },
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then((mod) => mod.SettingsModule),
                data: {
                    expectedRole: Roles.ROLE_ADMIN,
                    preload: false,
                    breadcrumb: {
                        title: 'core.toolbar.settings',
                    },
                },
            },
            {
                path: 'accounts',
                redirectTo: '/account',
                pathMatch: 'full',
            },
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((mod) => mod.AccountModule),
                data: {
                    preload: false,
                    breadcrumb: {
                        title: 'core.toolbar.account',
                    },
                },
            },
            {
                path: 'dictionaries',
                loadChildren: () => import('./dictionaries/dictionaries.module').then((mod) => mod.DictionariesModule),
                data: {
                    preload: false,
                    breadcrumb: {
                        title: 'core.toolbar.dictionaries',
                    },
                },
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import('./notifications/notifications.module').then((mod) => mod.NotificationsModule),
                data: {
                    preload: false,
                    breadcrumb: {
                        title: 'core.toolbar.notifications',
                    },
                },
            },
        ],
    },
    {
        path: 'disabledUser',
        component: DisabledUserComponent,
    },
    {
        path: 'accessdenied',
        component: AccessDeniedComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            enableTracing: false, // <-- DEV: set true for debugging
            // useHash: true,
            preloadingStrategy: QuicklinkStrategy,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
