import { QueryEntity } from '@datorama/akita';
import { ChartsState, ChartsStore } from './charts.store';
import { Chart } from '../../models/chart.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ChartsQuery extends QueryEntity<ChartsState, Chart> {
    constructor(protected override store: ChartsStore) {
        super(store);
    }
}
