import { Component } from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
    selector: 'elias-shared-dropdown-multiple',
    templateUrl: './dropdown-multiple.component.html',
    styleUrls: ['../dropdown/dropdown.component.scss'],
})
export class DropdownMultipleComponent extends DropdownComponent {
    protected override _selectedValue: string[] = [];

    override get selectTriggerLabel(): string {
        const numOfSelectedValues = this.selectedValue.length;
        if (numOfSelectedValues === 0) {
            return this.title;
        }

        const firstSelectedOption = this.options.find((option) => option.key === this.selectedValue[0]);

        if (firstSelectedOption) {
            return numOfSelectedValues > 1 ? firstSelectedOption.value + '...' : firstSelectedOption.value;
        }

        return this.title;
    }

    constructor() {
        super();
    }

    override isValueSelected() {
        return this.selectedValue.length > 0;
    }
}
