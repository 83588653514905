import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

@Component({
    selector: 'elias-shared-custom-step',
    templateUrl: './custom-step.component.html',
    styleUrls: ['./custom-step.component.scss'],
})
export class CustomStepComponent extends CdkStep {
    @Input() fullWidth = false;
    @Input() hideBackButton = false;
    @Input() hideCancelButton = false;
    @Input() hideDots = false;
    @Input() isLoading = false;
    @Input() isSave = false;
    @Input() nextButtonText = false;
    @Input() subtitle = '';
    @Input() title: string;

    @Output() stepIncreased = new EventEmitter<any>();

    nextStep() {
        this.stepIncreased.emit('xyz');
    }
}
