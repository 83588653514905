import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { DomainService } from '../../../modules/core/services/domain.service';
import { StylesheetStore } from '../state/stylesheet/stylesheet.store';

@Injectable()
export class StylesheetService {
    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private stylesheetStore: StylesheetStore
    ) {}

    public getStylesheet(publicationId: string): Observable<string> {
        const url = `${this.domainService.apiBaseUrl}/stylesheet/${publicationId}`;

        return this.http.get(url, { responseType: 'text' }).pipe(
            tap((stylesheet) => {
                this.stylesheetStore.update({ stylesheet });
            }),
            catchError((error) => throwError(error))
        );
    }
}
