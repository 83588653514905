import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from '../models/notification.model';
import { UpdateService } from '../../modules/core/services/update.service';
import { filter, pluck, tap } from 'rxjs/operators';
import { NotificationsStore } from '../state/notifications.store';
import { NotificationUpdate } from '../models/notification-update.model';
import { UpdateKey } from '../../modules/core/enums/update-key.enum';
import { NotificationsQuery } from '../state/notifications.query';

@Injectable({
    providedIn: 'root',
})
export class NotificationUpdateListener {
    public notificationUpdate$: Observable<Notification[]>;
    public numberOfUnreadNotificationsUpdate$: Observable<number>;

    constructor(
        private notificationsQuery: NotificationsQuery,
        private notificationsStore: NotificationsStore,
        private updateService: UpdateService
    ) {
        this.notificationUpdate$ = this.updateService.getUpdates<NotificationUpdate>(UpdateKey.Notification).pipe(
            pluck('entities'),
            filter((notifications) => notifications.length > 0),
            tap((notifications) => {
                if (!this.notificationsQuery.hasFilters()) {
                    this.notificationsStore.upsertMany(notifications);
                }
            })
        );

        this.numberOfUnreadNotificationsUpdate$ = this.updateService
            .getUpdates<NotificationUpdate>(UpdateKey.Notification)
            .pipe(
                pluck('properties', 'numberOfUnreadNotifications'),
                filter((numberOfUnreadNotifications) => numberOfUnreadNotifications !== undefined),
                tap((numberOfUnreadNotifications) => {
                    this.notificationsStore.update((state) => ({
                        additionalData: {
                            ...state.additionalData,
                            numberOfUnreadNotifications,
                        },
                    }));
                })
            );
    }
}
