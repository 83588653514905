<elias-base-dialog (dialogClosed)="onCancel()">
    <h1 header>{{ 'versioning.modal.restore.title' | translate }}</h1>

    <div class="subtitle">
        {{
            'versioning.modal.restore.description'
                | translate : { changeDate: changeDate | localizedDate : 'eliasShortDateTimeSeconds' }
        }}
    </div>

    <div class="scrollable-area">
        <elias-version-diff [diff]="diff" [condensedView]="false"></elias-version-diff>
    </div>

    <footer footer class="step-navigation-bar">
        <button class="stepper-btn prev" mat-button (click)="onCancel()">
            {{ 'actions.cancel' | translate }}
        </button>

        <button class="stepper-btn next" mat-flat-button color="accent" (click)="restoreVersion()">
            {{ 'actions.restore' | translate }}
        </button>
    </footer>
</elias-base-dialog>
