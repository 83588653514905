export default class SpellCheckAction {
    constructor(url, method, data) {
        this.url = url;
        this.method = method;
        this.data = data;
    }

     getUrl() {
        return this.url;
    }

    getMethod() {
        return this.method;
    }

    getData() {
        return this.data;
    }
}
