<div
    class="content-wrapper-for-hover"
    [ngClass]="{
        'needs-review-border': node?.needsReview,
        'fixed-border': node?.editing,
        'unresolved-comments-border': node?.hasUnresolvedComments
    }">
    <ng-template appNode> </ng-template>
    <div class="saving-overlay" *ngIf="isBeingSaved$ | async">
        <elias-shared-loading [centered]="true" dotSize="medium"></elias-shared-loading>
    </div>
</div>
<div class="node-buttons node-buttons-left" id="drag-button-left" *ngIf="!editing">
    <i class="ei ei-drag node-button-drag icons"></i>
</div>
<div class="node-buttons node-buttons-right">
    <i class="ei ei-settings icons" (click)="onPropertyEditorOpen($event)"></i>
    <i
        class="ei icons"
        [ngClass]="node?.hasUnresolvedComments ? 'ei-comment-full unresolved-comments-icon' : 'ei-comment'"
        (click)="openComments($event)"></i>
    <i
        *ngIf="isSupportedForVersioning && isVersioningEnabled"
        class="ei ei-layers icons"
        (click)="onOpenVersions($event)"></i>
</div>
