import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    Type,
    ViewChild,
} from '@angular/core';
import { NodeRepresentation } from '../../interfaces/node-representation.interface';
import { NodeDirective } from '../../directives/node.directive';
import { isNodeEdit } from '../../interfaces/node-edit.interface';
import { Node } from '../../models/node/node.model';
import { defaultNodeComponent, NodeComponent, NodeComponentMap, NodeComponents } from './node-components';
import { NodeViewModel } from '../../viewmodels/node.viewmodel';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { PropertyEditorComponent } from '../property-editor/property-editor.component';
import { SectionsQuery } from '../../state/sections/sections.query';
import { fromEvent, Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';
import { NodeCommentsSidenavService } from '../../../comments/services/sidenav/node-comments-sidenav.service';
import { NodeType } from '../../models/node/node-type.model';
import { VersionListComponent } from '../../../versioning/components/version-list/version-list.component';
import { SystemInformationQuery } from '../../../../modules/shared/state/system-information/system-information.query';
import { NodesQuery } from '../../state/nodes/nodes.query';
import { MatDialog } from '@angular/material/dialog';

export type NodeDeselectedEvent = { node: Node; isDirty: boolean };

@Component({
    selector: 'elias-editor-node-editable',
    styleUrls: ['./node-editable.component.scss'],
    templateUrl: './node-editable.component.html',
    // use Default strategy instead of OnPush since image node was not possible to implement with onPush
    changeDetection: ChangeDetectionStrategy.Default,
})
export class EditableNodeComponent implements OnInit, OnChanges {
    @Input() editing: boolean = false;
    @Input() node?: Node;

    @Output() contentChange = new EventEmitter<any>();
    @Output() move = new EventEmitter<Node>();
    @Output() remove = new EventEmitter<Node>();
    @Output() deselected = new EventEmitter<NodeDeselectedEvent>();

    @ViewChild(NodeDirective, { static: true }) nodeHost?: NodeDirective;

    public isSupportedForVersioning: boolean = false;
    public isVersioningEnabled: boolean = true;
    public isBeingSaved$: Observable<boolean>;

    private currentComponentRef?: ComponentRef<NodeRepresentation>;
    private currentNodeComponent?: NodeComponent;
    private nodeComponentsMap: NodeComponentMap;

    private oldContent: string = '';

    get isDirty(): boolean {
        if (!this.node) {
            return false;
        }

        // Strip HTML tags just to compare old and new content
        const oldContentStripped = this.oldContent.replace(/<\/?[^>]+(>|$)/g, '');
        const contentStripped = this.node.content.replace(/<\/?[^>]+(>|$)/g, '');

        return oldContentStripped !== contentStripped || this.oldContent !== this.node.content;
    }

    constructor(
        public elementRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private nodeComponents: NodeComponents,
        private nodeCommentsSidenavService: NodeCommentsSidenavService,
        private nodeViewModel: NodeViewModel,
        private publicationsQuery: PublicationsQuery,
        private sectionsQuery: SectionsQuery,
        private sideNavService: SideNavService,
        private systemInformationQuery: SystemInformationQuery,
        private nodesQuery: NodesQuery,
        private dialog: MatDialog,
        private sidenav: SideNavService
    ) {
        const publication = this.publicationsQuery.getActive();
        this.nodeComponentsMap = this.nodeComponents.getConfiguration(publication?.locale);
        this.isBeingSaved$ = this.nodesQuery.select('isSaving').pipe(
            map((isSaving) => {
                return isSaving && !!this.node?.editing;
            })
        );

        this.placeDragIcon();
    }

    ngOnInit(): void {
        if (!this.node) {
            return;
        }

        const scrollContainer = document.querySelector('.scroll-container');
        if (scrollContainer) {
            fromEvent(scrollContainer, 'scroll')
                .pipe(throttleTime(500))
                .subscribe(() => this.placeDragIcon());
        }

        this.isSupportedForVersioning = this.systemInformationQuery
            .getValue()
            .nodesSupportedForVersioning.includes(this.node.type);

        this.isVersioningEnabled = this.systemInformationQuery.getValue().featureFlags['versioning_enabled'];
        this.oldContent = this.node.content;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const nodeChange: SimpleChange = changes['node'];
        if (nodeChange) {
            this.onNodeChange(nodeChange.previousValue, nodeChange.currentValue);
        }
    }

    public async onPropertyEditorOpen(event: MouseEvent) {
        event.stopPropagation();

        if (!this.node) {
            return;
        }

        // Wait until editor is done saving content elements
        await this.nodesQuery.waitUntilAllSaved();

        const inputs = {
            sectionOrNodeType: 'node',
            sectionId: this.sectionsQuery.getActiveId(),
            nodeId: this.node.id,
        };

        const outputs = {};
        await this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
    }

    public async onOpenVersions(event: MouseEvent) {
        event.stopPropagation();

        if (!this.node) {
            return;
        }

        // Wait until editor is done saving content elements
        await this.nodesQuery.waitUntilAllSaved();

        const inputs = {
            nodeId: this.node.id,
        };

        await this.sideNavService.setComponent(VersionListComponent, inputs);
    }

    public async openComments(event: MouseEvent, preselectedCommentId?: string) {
        event.stopPropagation();

        if (!this.node) {
            return;
        }

        // Wait until editor is done saving content elements
        await this.nodesQuery.waitUntilAllSaved();
        await this.nodeCommentsSidenavService.open(this.node, preselectedCommentId);
    }

    private placeDragIcon(): void {
        const element = document.getElementsByClassName('content-wrapper-for-hover');

        for (let i = 0; i < element.length; i++) {
            if (element[i].getBoundingClientRect().height > window.innerHeight) {
                const currentParentElement = element[i].parentElement;
                if (
                    currentParentElement &&
                    element[i].getBoundingClientRect().top < 93 &&
                    element[i].getBoundingClientRect().bottom > window.innerHeight
                ) {
                    const icon = currentParentElement.children[1] as HTMLElement;
                    if (icon && icon.classList.contains('node-buttons-left')) {
                        icon.style.top = window.innerHeight / 2 + 'px';
                    }
                }

                if (currentParentElement && element[i].getBoundingClientRect().bottom > window.innerHeight) {
                    const icon = currentParentElement.children[1] as HTMLElement;
                    if (icon && icon.classList.contains('node-buttons-left')) {
                        // - 200 px - this will place button in the region of the current node content
                        icon.style.top = window.innerHeight - element[i].getBoundingClientRect().top - 200 + 'px';
                    }
                }
            }
        }
    }

    private onNodeChange(oldNode: Node, newNode: Node): void {
        if (!oldNode || oldNode.editing !== newNode.editing || JSON.stringify(oldNode) !== JSON.stringify(newNode)) {
            this.updateComponent();
        }
    }

    private updateComponent(): void {
        if (!this.node) {
            return;
        }

        const nodeComponent = this.getNodeComponent(this.node.type);

        if (!(this.currentNodeComponent === nodeComponent)) {
            this.currentNodeComponent = nodeComponent;
            this.loadComponent(this.currentNodeComponent.component);
            this.setComponentInputs();
            this.setComponentOutputs();
        }
    }

    private getNodeComponent(type: string): NodeComponent {
        if (this.isValidType(type)) {
            return Object.assign({}, defaultNodeComponent, this.getNodeRepresentation(type));
        }

        return defaultNodeComponent;
    }

    private loadComponent(component: Type<NodeRepresentation>): void {
        if (!this.nodeHost) {
            return;
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        this.nodeHost.viewContainerRef.clear();
        this.currentComponentRef = this.nodeHost.viewContainerRef.createComponent(componentFactory);
    }

    private setComponentInputs(): void {
        if (!this.node || !this.currentComponentRef || !this.currentNodeComponent) {
            return;
        }

        this.currentComponentRef.instance.content = this.node.content;
        this.currentComponentRef.instance.config = this.currentNodeComponent.config;
        this.currentComponentRef.instance.node = this.node;

        if (this.node.editing) {
            this.currentComponentRef.instance.nodeViewModel = this.nodeViewModel.node$ as Observable<Node>;
        }
    }

    private setComponentOutputs(): void {
        if (this.currentComponentRef && isNodeEdit(this.currentComponentRef.instance)) {
            this.currentComponentRef.instance.contentChange.subscribe((content: string) =>
                this.onContentChange(content)
            );
        }
    }

    private getNodeRepresentation(type: NodeType): NodeComponent | undefined {
        if (!this.node) {
            return;
        }

        return this.nodeComponentsMap[type][this.node.editing ? 'editor' : 'display'];
    }

    private onContentChange(content: string): void {
        if (!this.node) {
            return;
        }

        this.contentChange.emit(content);
        this.node = { ...this.node, content };
    }

    private isValidType(type: string): type is NodeType {
        return type in NodeType && type in this.nodeComponentsMap;
    }

    @HostListener('document:mouseup', ['$event.target'])
    private checkIfDeselected(target: HTMLElement): void {
        if (!this.node?.editing) {
            return;
        }

        const inside = this.elementRef.nativeElement.querySelector('.content-wrapper-for-hover').contains(target);
        const tinyMce = document.querySelector('.tox-tinymce-aux')?.contains(target);
        const handsontable = this.isPartOfHandsontable(target);
        const isDialogOpen = this.dialog.openDialogs.length > 0;
        const isSidenavOpen = this.sidenav.isOpen();

        if (!inside && !tinyMce && !handsontable && !isDialogOpen && !isSidenavOpen) {
            this.deselected.emit({ node: this.node, isDirty: this.isDirty });
        }
    }

    /**
     * Handsontable menu doesn't anymore exist in the DOM when mouseup event is triggered,
     * therefore we cannot use contains() method to detect if target is part of that menu.
     */
    private isPartOfHandsontable(target: HTMLElement): boolean {
        let curr: HTMLElement | null = target;

        while (curr !== null) {
            if (curr.classList.contains('htCore')) {
                return true;
            }

            curr = curr.parentElement;
        }

        return false;
    }
}
