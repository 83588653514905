import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NotificationsState, NotificationsStore } from './notifications.store';
import { Notification } from '../models/notification.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationsQuery extends QueryEntity<NotificationsState, Notification> {
    constructor(protected override store: NotificationsStore) {
        super(store);
    }

    getFilters(): { [k: string]: string | null } {
        return this.getValue().ui.filters;
    }

    hasFilters(): boolean {
        return Object.values(this.getFilters()).some((filterValue) => filterValue !== null);
    }

    getCurrentPage(): number {
        return this.getValue().currentPage;
    }

    getPage(): number | null {
        return this.getValue().ui.page;
    }
}
