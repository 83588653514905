<div class="mention-menu">
    <div class="title">{{ 'comments.mentions.title' | translate }}</div>
    <div class="mention-menu-items">
        <div *ngIf="mentionableChiefEditors.length > 0" class="mention-submenu-label">
            {{ 'comments.mentions.submenus.chiefEditors' | translate }}
        </div>
        <div
            *ngFor="let user of mentionableChiefEditors"
            class="mention-menu-item"
            [ngClass]="{ selected: this.selectedIndex === user.index }"
            (click)="selectItem(user)"
            #menuItem>
            <elias-mentionable-avatar class="avatar" [mentionable]="user"></elias-mentionable-avatar>
            <span class="label">{{ user.label }}</span>
        </div>

        <div *ngIf="mentionableEditors.length > 0" class="mention-submenu-label">
            {{ 'comments.mentions.submenus.editors' | translate }}
        </div>
        <div
            *ngFor="let user of mentionableEditors"
            class="mention-menu-item"
            [ngClass]="{ selected: this.selectedIndex === user.index }"
            (click)="selectItem(user)"
            #menuItem>
            <elias-mentionable-avatar class="avatar" [mentionable]="user"></elias-mentionable-avatar>
            <span class="label">{{ user.label }}</span>
        </div>

        <div *ngIf="mentionableGroups.length > 0" class="mention-submenu-label">
            {{ 'comments.mentions.submenus.groups' | translate }}
        </div>
        <div
            *ngFor="let group of mentionableGroups"
            class="mention-menu-item"
            [ngClass]="{ selected: this.selectedIndex === group.index }"
            (click)="selectItem(group)"
            #menuItem>
            <elias-mentionable-avatar class="avatar" [mentionable]="group"></elias-mentionable-avatar>
            <span class="label">{{ group.label }}</span>
        </div>
        <div class="empty-message" *ngIf="mentionableItems.length === 0">
            {{ 'comments.mentions.noUsers' | translate }}
        </div>
    </div>
</div>
