import { ComponentFactoryResolver, ComponentRef, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SideNavService {
    private sideNav: MatSidenav;
    private vcf: ViewContainerRef;
    private componentRef: ComponentRef<any>;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    public setSidenav(sidenav: MatSidenav) {
        this.sideNav = sidenav;
    }

    public open() {
        return this.sideNav.open();
    }

    public close() {
        this.destroyComponent();
        return this.sideNav.close();
    }

    public toggle(): void {
        this.sideNav.toggle();
    }

    public isOpen() {
        return this.sideNav.opened;
    }

    public getClosedStartObservable(): Observable<void> {
        return this.sideNav.closedStart;
    }

    setContentVcf(viewContainerRef: ViewContainerRef) {
        this.vcf = viewContainerRef;
    }

    private createView(template: TemplateRef<any>) {
        this.vcf.clear();
        this.vcf.createEmbeddedView(template);
    }

    openTemplate(template: TemplateRef<any>) {
        this.createView(template);
        return this.open();
    }

    public setComponent(component, inputs = {}, outputs = {}) {
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);

        this.vcf.clear();
        this.componentRef = this.vcf.createComponent(factory);

        for (const key in inputs) {
            this.componentRef.instance[key] = inputs[key];
        }

        for (const key in outputs) {
            this.componentRef.instance[key] = outputs[key];
        }

        return this.open();
    }

    public destroyComponent() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }
}
