<div class="select dropdown-container">
    <mat-form-field appearance="legacy" floatLabel="never" class="dropdown-form-field">
        <mat-label class="label">{{ title }}</mat-label>

        <mat-select-trigger>
            <i class="ei ei-arrows-down-thin select-arrow-icon" [ngClass]="{ selected: isValueSelected() }"></i>
        </mat-select-trigger>

        <mat-select multiple [(value)]="selectedValue">
            <mat-select-trigger class="selected">
                {{ selectTriggerLabel }}
            </mat-select-trigger>
            <mat-option *ngFor="let option of options" [value]="option.key" (click)="onClick($event, option)">
                {{ option.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
