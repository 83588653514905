import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class FlashMessageService {
    defaultErrorDuration: number = 6000;
    defaultSuccessDuration: number = 4000;

    constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

    public showError(message: string, duration: number = this.defaultErrorDuration): void {
        this.snackBar.open(message, 'X', { panelClass: ['error'], duration });
    }

    public showInfo(message: string, duration: number = this.defaultSuccessDuration): void {
        this.snackBar.open(message, '', {
            duration,
        });
    }

    public showTranslatedError(key: string, params: object = {}, duration: number = this.defaultErrorDuration): void {
        const message = this.translateService.instant(key, params);
        this.showError(message, duration);
    }

    public showTranslatedInfo(key: string, params: object = {}, duration: number = this.defaultSuccessDuration): void {
        const message = this.translateService.instant(key, params);
        this.showInfo(message, duration);
    }
}
