import { Component, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { of } from 'rxjs';
import { StatusQuery } from '../../../modules/shared/state/status/status.query';

@Component({
    selector: 'elias-auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    public loading$ = of(false);
    public homeImage?: string;
    public inMaintenance$ = this.statusQuery.inMaintenance$;
    public isWhitelistedAtMaintenance$ = this.statusQuery.isWhitelistedAtMaintenance$;

    private images: string[] = [
        'elias_login_background_1.jpg',
        'elias_login_background_2.jpg',
        'elias_login_background_3.jpg',
    ];

    private readonly returnUrl: string;

    constructor(public router: Router, private route: ActivatedRoute, private statusQuery: StatusQuery) {
        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading$ = of(true);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading$ = of(false);
                    break;
                }
                default: {
                    break;
                }
            }
        });

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/publication-groups';
    }

    ngOnInit() {
        const value = Math.floor(Math.random() * this.images.length + 1);
        this.homeImage = "url('/../../../assets/images/" + this.images[value - 1] + "')";
    }

    public async onUserAuthenticated(): Promise<void> {
        await this.router.navigate([this.returnUrl], { relativeTo: this.route });
    }
}
