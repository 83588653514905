import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { fromLocalStorage } from '../observables/fromLocalStorage';
import { LocalStorageKeys } from '../enums/local-storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class UserActivityService implements OnDestroy {
    public userActivity$ = new BehaviorSubject(new Date());
    private lastActivity: string | null = null;
    private activitySubscription: Subscription;

    constructor() {
        const newLocalStorageActivity$ = fromLocalStorage(LocalStorageKeys.LastActivity).pipe(
            filter((e) => e.oldValue === this.lastActivity)
        );

        this.activitySubscription = merge(
            fromEvent(window, 'load'),
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'touchstart'),
            fromEvent(document, 'click'),
            fromEvent(document, 'keyup'),
            newLocalStorageActivity$
        ).subscribe(() => {
            this.reportNewActivity();
        });
    }

    private reportNewActivity(): void {
        const currentDate = new Date();

        localStorage.setItem(LocalStorageKeys.LastActivity, currentDate.toString());
        this.lastActivity = currentDate.toString();

        this.userActivity$.next(currentDate);
    }

    ngOnDestroy(): void {
        this.activitySubscription.unsubscribe();
    }
}
