import { Component, Input } from '@angular/core';

type LoadingDotSize = 'small' | 'medium' | 'large';
type DescriptionContexts = 'assetBrowser' | 'chart' | 'document' | 'editor' | 'login';

@Component({
    selector: 'elias-shared-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
    @Input() centered = false;
    @Input() descriptionContext?: DescriptionContexts;
    @Input() dotSize: LoadingDotSize = 'large';
    @Input() includeText = false;
    @Input() inverted = false;
    @Input() isColorful = true;

    constructor() {}
}
