import { Pipe, PipeTransform } from '@angular/core';
import { CommentHtmlParserService } from '../services/comment-html-parser.service';

@Pipe({
    name: 'commentContent',
})
export class CommentContentPipe implements PipeTransform {
    constructor(private commentHtmlParser: CommentHtmlParserService) {}

    transform(value: string): string {
        return this.commentHtmlParser.parseHtmlForDisplaying(value);
    }
}
