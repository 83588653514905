import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { KeyValueOption } from '../../shared/models/key-value-option.model';

@Injectable({
    providedIn: 'root',
})
export class UrlParamsService {
    private currentRoute: ActivatedRoute = this.route;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(this.router),
                tap(() => {
                    let deepestActivatedRoute = this.route;
                    while (deepestActivatedRoute.firstChild) {
                        deepestActivatedRoute = deepestActivatedRoute.firstChild;
                    }
                    this.currentRoute = deepestActivatedRoute;
                })
            )
            .subscribe();
    }

    public getParamFromURL(key: string): Observable<KeyValueOption> {
        return this.currentRoute.paramMap.pipe(
            filter((paramMap: ParamMap) => paramMap.has(key)),
            map((paramMap: ParamMap) => ({
                key: key,
                value: paramMap.get(key) as string,
            }))
        );
    }

    public urlParamNotFound(key: string): Observable<void> {
        return this.currentRoute.paramMap.pipe(
            filter((paramMap: ParamMap) => !paramMap.has(key)),
            switchMap(() => of(void 0))
        );
    }

    public async removeUrlParam(key: string): Promise<void> {
        const { params, routeConfig } = this.currentRoute.snapshot;
        let filteredParams = this.removeObjectPropertyByKey(params, key);

        if (routeConfig?.path?.startsWith(':')) {
            filteredParams = this.removeObjectPropertyByKey(filteredParams, routeConfig?.path?.slice(1));
        }

        await this.router.navigate([{ ...filteredParams }], {
            relativeTo: this.currentRoute,
            replaceUrl: false,
            state: { type: 'removeUrlParam' },
        });
    }

    private removeObjectPropertyByKey(obj: { [k: string]: any }, key: string): { [k: string]: any } {
        const { [key]: _, ...properties } = obj;

        return properties;
    }
}
