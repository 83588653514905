import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { Node } from '../../../models/node/node.model';
import { Observable } from 'rxjs';
import { NodeType } from '../../../models/node/node-type.model';

@Component({
    selector: 'elias-editor-display-pagebreak',
    styleUrls: ['display-page-break.component.scss'],
    templateUrl: './display-page-break.component.html',
})
export class DisplayPageBreakComponent implements NodeRepresentation, OnInit {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: Node;
    @Input() nodeViewModel: Observable<Node>;

    ngOnInit() {
        this.config = this.config || { type: 'page-break' };
    }
}
