import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'elias-collapsible-content',
    templateUrl: './collapsible-content.component.html',
    styleUrls: ['./collapsible-content.component.scss'],
})
export class CollapsibleContentComponent implements AfterViewInit {
    @ViewChild('contentElement') contentElement?: ElementRef;
    @Input() contentHeight?: number;

    public collapsed = true;
    public offsetHeight?: number;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.offsetHeight = this.contentElement?.nativeElement.offsetHeight;
        this.changeDetectorRef.detectChanges();
    }

    public toggleCollapse(event: any): void {
        event.stopPropagation();
        this.collapsed = !this.collapsed;
    }
}
