import { Component } from '@angular/core';
import { StatusQuery } from '../../../modules/shared/state/status/status.query';

@Component({
    selector: 'elias-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
    public maintenanceWarning$ = this.statusQuery.maintenanceWarning$;

    constructor(private statusQuery: StatusQuery) {}
}
