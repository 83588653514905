import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { Node } from '../../../models/node/node.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ChartsQuery } from '../../../state/charts/charts.query';
import { NodeConfig } from '../../../models/node/node-type.model';
import { map } from 'rxjs/operators';
import { ChartsService } from '../../../services';

@Component({
    selector: 'elias-editor-display-chart',
    templateUrl: './display-chart.component.html',
    styleUrls: ['./display-chart.component.scss'],
})
export class DisplayChartComponent implements NodeRepresentation, OnInit {
    @Input() config?: NodeConfig;
    @Input() content = '';
    @Input() node?: Node;
    @Input() nodeViewModel?: Observable<Node>;

    public isGenerating$?: Observable<boolean | undefined>;
    public isLoading$?: Observable<boolean | undefined>;
    public content$?: Observable<SafeUrl | null>;

    constructor(
        private chartsQuery: ChartsQuery,
        private chartsService: ChartsService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        if (!this.node) {
            return;
        }

        const chartId = this.node.id;

        if (!this.chartsQuery.hasEntity(chartId)) {
            this.chartsService.loadChartAndRegenerateFailed(chartId, 5).subscribe();
        }

        this.content$ = this.chartsQuery.selectEntity(chartId).pipe(
            map((chart) => chart?.content),
            map((content) => (content ? this.sanitizer.bypassSecurityTrustUrl(content) : null))
        );
        this.isLoading$ = this.chartsQuery.selectEntity(chartId).pipe(map((chart) => chart?.isLoading));
        this.isGenerating$ = this.chartsQuery.selectEntity(chartId).pipe(map((chart) => chart?.isGenerating));
    }

    public reloadChart(event: MouseEvent): void {
        event.stopPropagation();

        if (!this.node) {
            return;
        }

        this.chartsService.loadChart(this.node.id, 3).subscribe();
    }

    public regenerateChart(event: MouseEvent): void {
        event.stopPropagation();

        if (!this.node) {
            return;
        }

        this.chartsService.loadChartAndRegenerateFailed(this.node.id, 5).subscribe();
    }
}
