import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('slideInOut', [
    state(
        'in',
        style({
            minHeight: 0,
            overflow: 'auto',
            height: '*',
            opacity: 1,
        })
    ),
    state(
        'out',
        style({
            minHeight: 0,
            opacity: 0,
            overflow: 'hidden',
            height: '0px',
        })
    ),
    transition('in => out', animate('400ms ease-in-out')),
    transition('out => in', animate('400ms ease-in-out')),
]);
