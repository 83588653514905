<div [formGroup]="formGroup">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div
            class="example-box"
            *ngFor="let option of objectKeys(sortableSelectValue); trackBy: trackByIndex"
            cdkDrag
            (click)="toggleActivation(option)">
            <ng-container *ngIf="option !== 'link'">
                <i class="ei ei-drag"></i>
                <div class="border-box" fxLayoutGap="10px">
                    <mat-checkbox [disabled]="option === 'image'" [checked]="sortableSelectValue[option]">{{
                        'document.image.' + option | translate
                    }}</mat-checkbox>
                </div>
            </ng-container>
        </div>
    </div>
</div>
