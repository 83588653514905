import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TitlePipeReplacerService {
    constructor(private http: HttpClient) {}

    /** Replace || with <br> */
    replacePipes(title: string, breakLine: boolean): string {
        return title.replace(/\|\|/g, breakLine ? '<br>' : ' ');
    }
}
