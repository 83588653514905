import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'elias-core-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['logout.component.scss'],
})
export class LogoutComponent implements OnInit {
    images: any[] = ['elias_login_background_1.jpg', 'elias_login_background_2.jpg', 'elias_login_background_3.jpg'];
    homeImage: any;

    constructor() {}

    ngOnInit() {
        const value = Math.floor(Math.random() * this.images.length + 1);
        this.homeImage = "url('../../../../../assets/images/" + this.images[value - 1] + "')";
    }
}
