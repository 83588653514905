import { EventEmitter, Injectable } from '@angular/core';
import { CommentsService } from '../comments.service';
import { CommentTargetType } from '../../enums/comment-target-type.enum';
import { CommentStatus } from '../../enums/comment-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { CommentListComponent } from '../../components/comment-list/comment-list.component';
import { switchMap } from 'rxjs/operators';
import { SideNavService } from '../../../../modules/core/services/side-nav.service';
import { Node } from '../../../editor/models/node/node.model';
import { CommentsStore, CommentsTarget } from '../../state/comments.store';
import { CommentsFacade } from '../comments-facade.service';

@Injectable({
    providedIn: 'root',
})
export class NodeCommentsSidenavService {
    constructor(
        private commentsFacade: CommentsFacade,
        private commentsService: CommentsService,
        private commentsStore: CommentsStore,
        private sideNavService: SideNavService,
        private translateService: TranslateService
    ) {}

    public async open(node: Node, preselectedCommentId?: string): Promise<void> {
        this.commentsFacade.resetStore();

        const target: CommentsTarget = { id: node.id, type: CommentTargetType.Node };
        this.commentsStore.update({ target });

        this.commentsService.getComments(CommentTargetType.Node, node.id).subscribe();
        await this.createComponent(node, preselectedCommentId);
    }

    private async createComponent(node: Node, preselectedCommentId?: string): Promise<void> {
        const inputs = {
            commentStatus: CommentStatus.Editable,
            title: this.translateService.instant('comments.title', { title: node.name }),
            preselectedCommentId,
        };

        const commentCancelled = new EventEmitter();
        const outputs = {
            commentCancelled,
        };

        const commentsCancelled$ = commentCancelled.pipe(switchMap(() => this.sideNavService.close()));
        commentsCancelled$.subscribe();

        await this.sideNavService.setComponent(CommentListComponent, inputs, outputs);
    }
}
