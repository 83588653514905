import { Component, Input } from '@angular/core';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'elias-shared-sorting-dropdown',
    templateUrl: '../dropdown/dropdown.component.html',
    styleUrls: ['../dropdown/dropdown.component.scss'],
})
export class SortingDropdownComponent extends DropdownComponent {
    @Input() override defaultOptionText: string = '';

    constructor(private translateService: TranslateService) {
        super();
    }
}
