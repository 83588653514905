<ng-container *ngIf="!comment.resolvedAt; else resolved">
    <div class="comment-action-content">
        <ng-container>
            <div class="comment-action" *ngIf="showControls">
                <div class="comment-action-answer" (click)="reply($event)">
                    <i class="ei-answer"></i>
                    <div>
                        {{ 'comments.answer' | translate }}
                    </div>
                </div>
                <div class="comment-action-resolve" (click)="resolveComment($event)">
                    <i class="ei-done"></i>
                    <div>
                        {{ 'comments.resolve' | translate }}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #resolved>
    <div class="comment-resolved-action" (click)="unresolveComment($event)">
        <div class="comment-resolve-message" [ngClass]="{ 'resolved-comment': comment.resolvedAt }">
            {{
                'comments.resolvedMessage'
                    | translate
                        : {
                              name: resolverName,
                              time: comment.resolvedAt | timeAgo
                          }
            }}
        </div>
        <div class="comment-action" *ngIf="showControls">
            <i class="ei-refresh action-icon"></i>
            <div class="action-text">
                {{ 'comments.reopen' | translate }}
            </div>
        </div>
    </div>
</ng-template>
