<elias-base-dialog (dialogClosed)="onCancel()">
    <h1 header>
        {{ 'versioning.modal.changes.title' | translate }}
    </h1>
    <div class="subtitle">
        {{
            'versioning.modal.changes.description'
                | translate : { changeDate: changeDate | localizedDate : 'eliasShortDateTimeSeconds' }
        }}
    </div>

    <div class="scrollable-area">
        <elias-version-diff
            *ngIf="!isDiffEmpty; else emptyDiff"
            [diff]="diff"
            [condensedView]="false"></elias-version-diff>
        <ng-template #emptyDiff>
            <elias-shared-placeholder
                [message]="'versioning.modal.changes.empty' | translate"></elias-shared-placeholder>
        </ng-template>
    </div>

    <footer footer class="step-navigation-bar">
        <button class="stepper-btn prev" mat-button (click)="onCancel()">
            {{ 'actions.cancel' | translate }}
        </button>

        <button class="stepper-btn next" mat-flat-button color="accent" (click)="next()" [disabled]="isDiffEmpty">
            {{ 'actions.restore' | translate }}...
        </button>
    </footer>
</elias-base-dialog>
