import { Component, Input } from '@angular/core';
import { TextDiffEntry } from '../../models/diff-entry.model';

@Component({
    selector: 'elias-version-diff-text',
    templateUrl: './version-diff-text.component.html',
    styleUrls: ['./version-diff-text.component.scss'],
})
export class VersionDiffTextComponent {
    @Input() diffKey?: string;
    @Input() diffEntry?: TextDiffEntry;

    constructor() {}
}
