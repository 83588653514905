import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { GridNode } from '../../../models/node/grid-node.model';
import { Observable } from 'rxjs';
import { NodeType } from '../../../models/node/node-type.model';

@Component({
    selector: 'elias-editor-display-keyfigures',
    templateUrl: './display-keyfigures.component.html',
    styleUrls: ['display-keyfigures.component.scss'],
})
export class DisplayKeyfiguresComponent implements NodeRepresentation, OnInit {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: GridNode;
    @Input() nodeViewModel: Observable<GridNode>;

    public alignVertically = false;
    cols = 2;
    public elementIndexes: number[] = [];
    keyFigureLayout = '';
    public keyFigures: any[] = [];

    ngOnInit() {
        this.parseContent();
        this.cols = 12 / this.node.cols;
        this.keyFigureLayout = this.node.layout;
        this.alignVertically = this.node.alignVertically;

        if (this.alignVertically) {
            const maxLength = Math.max(...this.keyFigures.map((col) => col.length));
            this.elementIndexes = [...Array(maxLength).keys()];
        }
    }

    parseContent() {
        this.keyFigures = JSON.parse(this.content);
    }

    trackByIndex(index, item) {
        return index;
    }
}
