import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessageService } from '../../../core/services/flash-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'elias-shared-dialog-delete',
    templateUrl: './delete.component.html',
})
export class DeleteComponent implements OnInit {
    public deleteFormGroup: FormGroup;
    public text?: string;
    public type?: string;
    public requireExplicitConfirmation = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DeleteComponent>,
        private fb: FormBuilder,
        public flashMessageService: FlashMessageService,
        private translateService: TranslateService
    ) {
        this.deleteFormGroup = this.fb.group({
            deleteInput: ['', [Validators.required]],
        });
    }

    ngOnInit() {
        if (this.data) {
            this.type = this.data.type;
            this.text = this.data.text || '';

            if (this.data.hasOwnProperty('requireExplicitConfirmation')) {
                this.requireExplicitConfirmation = this.data.requireExplicitConfirmation;
            }
        }
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public onConfirm(): void {
        if (!this.requireExplicitConfirmation) {
            this.dialogRef.close(true);
            return;
        }

        if (this.type) {
            const inputValue = this.deleteFormGroup.controls['deleteInput'].value.toLowerCase();
            const translatedValue = this.translateService.instant(`modal.delete.${this.type}.delete`).toLowerCase();

            if (translatedValue === inputValue) {
                this.dialogRef.close(true);
                return;
            }

            this.deleteFormGroup.controls['deleteInput'].markAsTouched();
            this.flashMessageService.showTranslatedError(`modal.delete.${this.type}.error`);

            return;
        }

        this.handleDefaultConfirmation();
    }

    private handleDefaultConfirmation(): void {
        const confirmation = 'delete';
        const inputValue = this.deleteFormGroup.controls['deleteInput'].value.toLowerCase();
        const translatedValue = this.translateService.instant('actions.delete').toLowerCase();

        if (inputValue === confirmation || inputValue === translatedValue) {
            this.dialogRef.close(true);
            return;
        }

        this.deleteFormGroup.controls['deleteInput'].markAsTouched();
        this.flashMessageService.showTranslatedError('modal.delete.messageerror');
    }
}
