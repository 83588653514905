import { Injectable } from '@angular/core';
import { LinksService } from './links.service';
import { PublicationsQuery } from '../../../publication/state/publications/publications.query';
import { PublicationGroupsQuery } from '../../../publication-group/state/publication-groups/publication-groups.query';
import { Comment } from '../../../editor/comments/models/comment.model';
import { CommentTargetType } from '../../../editor/comments/enums/comment-target-type.enum';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';
import { NodeCommentLinksProvider } from '../providers/node-comment-links.provider';
import { SectionCommentLinksProvider } from '../providers/section-comment-links.provider';

@Injectable({
    providedIn: 'root',
})
export class CommentLinksService extends LinksService<Comment> {
    constructor(private publicationGroupsQuery: PublicationGroupsQuery, private publicationsQuery: PublicationsQuery) {
        super();
    }

    public createLinksProvider(comment: Comment): LinksProviderInterface {
        const { targetType } = comment;

        switch (targetType) {
            case CommentTargetType.Node:
                return this.createNodeCommentLinksProvider(comment);

            case CommentTargetType.Section:
                return this.createSectionCommentLinksProvider(comment);
        }

        throw new Error(`Cannot create links provider for comment with target: ${targetType}`);
    }

    private createNodeCommentLinksProvider({ routingArguments, id }: Comment): LinksProviderInterface {
        const publicationGroupId = this.publicationGroupsQuery.getActiveId() as string;
        const publicationId = this.publicationsQuery.getActiveId() as string;

        if (
            !publicationGroupId ||
            !publicationId ||
            !routingArguments['sectionId'] ||
            !routingArguments['nodeId'] ||
            !id
        ) {
            throw new Error('Not enough information to create NodeCommentLinksProvider');
        }

        return new NodeCommentLinksProvider(
            publicationGroupId,
            publicationId,
            routingArguments['sectionId'],
            routingArguments['nodeId'],
            id
        );
    }

    private createSectionCommentLinksProvider({ routingArguments, id }: Comment): LinksProviderInterface {
        const publicationGroupId = this.publicationGroupsQuery.getActiveId() as string;
        const publicationId = this.publicationsQuery.getActiveId() as string;

        if (!publicationGroupId || !publicationId || !routingArguments['sectionId'] || !id) {
            throw new Error('Not enough information to create SectionCommentLinksProvider');
        }

        return new SectionCommentLinksProvider(publicationGroupId, publicationId, routingArguments['sectionId'], id);
    }
}
