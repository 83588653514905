import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Category } from '../models/category.model';
import { catchError, tap } from 'rxjs/operators';
import { CategoriesStore } from '../state/categories/categories.store';
import { CategoriesQuery } from '../state/categories/categories.query';
import { HttpClient } from '@angular/common/http';
import { DomainService } from '../../core/services/domain.service';
import { RequestDto } from '../../../editor/editor/models/dto/request-dto.model';
import { ID } from '@datorama/akita';
import { NewCategoryDto } from '../../../settings/models/new-category-dto.model';

@Injectable({
    providedIn: 'root',
})
export class CategoriesService {
    baseUrl: string = this.domainService.apiBaseUrl + '/categories';

    constructor(
        private categoryStore: CategoriesStore,
        private categoryQuery: CategoriesQuery,
        private domainService: DomainService,
        private http: HttpClient
    ) {}

    getCategories(): Observable<Category[]> {
        this.categoryStore.setLoading(true);
        const request$ = this.http.get<any>(this.baseUrl).pipe(
            tap((category) => {
                this.categoryStore.set(category);
                this.categoryStore.update({ loaded: true });
                this.categoryStore.setLoading(false);
            })
        );

        return this.categoryQuery.getHasCache() ? of() : request$;
    }

    updateCategories(category: Category, data: Partial<Category> = null) {
        this.categoryStore.setLoading(true);
        data = data ? data : category;
        const url = `${this.domainService.apiBaseUrl}/categories/${category.id}`;
        const payload: RequestDto = new RequestDto(null, null, data);
        return this.http.patch<Category>(url, payload.body).pipe(
            tap((category) => {
                this.categoryStore.update(category.id, category);
                this.categoryStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    deleteCategory(id: ID) {
        this.categoryStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/categories/${id}`;
        return this.http.delete<Category>(url).pipe(
            tap(() => {
                this.categoryStore.remove(id);
                this.categoryStore.setLoading(false);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createCategory(category: NewCategoryDto, type: Category['type']): Observable<any> {
        this.categoryStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/categories/${type}`;

        return this.http.post<Category>(url, category).pipe(
            tap((category) => {
                this.categoryStore.add(category);
                this.categoryStore.setLoading(false);
            }),

            catchError((error: any) => throwError(error))
        );
    }

    mockCreateCat(): Category[] {
        return [
            {
                id: '9313d060-6478-11ea-9a19-e1c63fe5c117',
                name: 'asdf',
                color: '#FF2654',
                createdAt: new Date('2020-03-12T15:46:01+00:00'),
                updatedAt: new Date('2020-05-19T13:18:06+00:00'),
                type: 'general',
            },
            {
                id: 'a60c2f82-6a95-11ea-957c-6798c30df39e',
                name: 'cvbxcvb',
                color: '#FCAB24',
                createdAt: new Date('2020-03-20T10:29:15+00:00'),
                updatedAt: new Date('2020-05-19T12:46:47+00:00'),
                type: 'general',
            },
            {
                id: '2ef42ed8-6449-11ea-9a19-e1c63fe5c117',
                name: 'Test Publication Type',
                color: '#C6FF2E',
                createdAt: new Date('2020-03-12T10:06:47+00:00'),
                updatedAt: new Date('2020-05-19T12:48:51+00:00'),
                type: 'publication-type',
            },
        ];
    }
}
