import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface StylesheetState {
    stylesheet: string;
}

export function createInitialState(): Partial<StylesheetState> {
    return {};
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Stylesheet', resettable: true })
export class StylesheetStore extends Store<StylesheetState> {
    constructor() {
        super(createInitialState());
    }
}
