import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

/**
 * Document service is used to initialize drag'n'drop in preset browser after document loading has finished.
 */
@Injectable()
export class DocumentService {
    private loading = new BehaviorSubject<boolean>(true);
    loading$ = this.loading.asObservable().pipe(delay(0));

    constructor() {}

    public setLoading(loading: boolean) {
        this.loading.next(loading);
    }
}
