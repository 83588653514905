import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Comment } from '../../models/comment.model';
import { UsersUtilsService } from '../../../../user/services/users-utils.service';

@Component({
    selector: 'elias-comment-action-readonly',
    templateUrl: './comment-action-readonly.component.html',
    styleUrls: ['./comment-action-readonly.component.scss'],
})
export class CommentActionReadonlyComponent implements OnChanges {
    @Input() comment?: Comment;
    @Input() showControls: boolean = true;

    @Output() commentLinkClicked = new EventEmitter<Comment>();

    public resolverName: string | null = null;

    constructor(private usersUtilsService: UsersUtilsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['comment']) {
            this.resolverName = this.comment?.resolvedBy
                ? this.usersUtilsService.getFullName(this.comment.resolvedBy)
                : null;
        }
    }

    public goToComment(): void {
        this.commentLinkClicked.emit(this.comment);
    }
}
