import { LinksProviderInterface } from '../interfaces/links-provider.interface';

export class PublicationGroupLinksProvider implements LinksProviderInterface {
    constructor(private publicationGroupId: string) {}

    public getParameters() {
        return {
            publicationGroupId: this.publicationGroupId,
        };
    }
}
