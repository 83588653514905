import { Component, Input } from '@angular/core';

@Component({
    selector: 'elias-shared-sidebar-title',
    templateUrl: './sidebar-title.component.html',
    styleUrls: ['./sidebar-title.component.scss'],
})
export class SidebarTitleComponent {
    @Input() buttonFlexLayout = 'space-between center';
    @Input() subtitle = '';
    @Input() title = '';

    constructor() {}
}
