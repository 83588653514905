import { LinksGeneratorInterface } from '../interfaces/links-generator.interface';
import { Type } from '@angular/core';

export namespace LinksGeneratorRegistry {
    const generators: LinksGeneratorInterface[] = [];

    export function getGenerators(): LinksGeneratorInterface[] {
        return generators;
    }

    export function register<T extends Type<LinksGeneratorInterface>>(LinksGeneratorImp: T) {
        generators.push(new LinksGeneratorImp());
    }
}
