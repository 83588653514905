import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, retryWhen, switchMap, tap } from 'rxjs/operators';
import { Chart } from '../models/chart.model';
import { ChartsStore } from '../state/charts/charts.store';
import { DomainService } from '../../../modules/core/services/domain.service';

@Injectable()
export class ChartsService {
    constructor(private chartsStore: ChartsStore, private domainService: DomainService, private http: HttpClient) {}

    loadChartAndRegenerateFailed(chartId: string, numOfRetries = 0): Observable<Chart> {
        this.chartsStore.upsert(chartId, { isLoading: true });

        const url = `${this.domainService.apiBaseUrl}/charts/${chartId}?regenerateFailed=1`;
        return this.http.get<Chart>(url).pipe(
            switchMap((chart) => {
                if (numOfRetries > 0 && chart.isGenerating) {
                    return this.loadChart(chartId, numOfRetries - 1);
                }

                return of(chart).pipe(
                    tap((chart) => {
                        this.chartsStore.upsert(chartId, {
                            ...chart,
                            content: chart.content
                                ? URL.createObjectURL(this.b64toBlob(chart.content, 'image/png'))
                                : null,
                            isLoading: false,
                        });
                    })
                );
            }),
            catchError((e) => {
                this.chartsStore.upsert(chartId, {
                    content: null,
                    hasError: false,
                    isLoading: false,
                    isGenerating: true,
                });

                return EMPTY;
            })
        );
    }

    loadChart(chartId: string, numOfRetries = 0): Observable<Chart> {
        this.chartsStore.upsert(chartId, { isLoading: true });

        const url = `${this.domainService.apiBaseUrl}/charts/${chartId}`;
        return this.http.get<Chart>(url).pipe(
            tap((chart) => {
                if (chart.isGenerating) {
                    throw new Error('Not yet generated, try again...');
                }

                this.chartsStore.upsert(chartId, {
                    ...chart,
                    content: chart.content ? URL.createObjectURL(this.b64toBlob(chart.content, 'image/png')) : null,
                    isLoading: false,
                });
            }),
            retryWhen((errors) => errors.pipe(concatMap((e, i) => (i === numOfRetries ? throwError(e) : timer(2000))))),
            catchError((e) => {
                // This part is executed after all retries failed
                this.chartsStore.upsert(chartId, {
                    content: null,
                    hasError: false,
                    isLoading: false,
                    isGenerating: true,
                });

                return EMPTY;
            })
        );
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 1024): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
