import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb } from '../../models/breadcrumb.model';

@Component({
    selector: 'elias-core-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    public activeUrl: string = '';
    public breadcrumbList: Breadcrumb[] = [];

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.breadcrumbList = [];
                this.activeUrl = event.url;
                this.buildBreadcrumb(this.activatedRoute);
            }
        });
    }

    buildBreadcrumb(activatedRoute: ActivatedRoute): void {
        const breadcrumb = activatedRoute.snapshot.data['breadcrumb'] as Breadcrumb;

        if (breadcrumb) {
            const lastBreadcrumbUrl =
                this.breadcrumbList.length !== 0 ? this.breadcrumbList[this.breadcrumbList.length - 1].url : '';
            const currentBreadcrumbUrl = this.getCurrentUrl(activatedRoute);
            const targetUrl = lastBreadcrumbUrl + '/' + currentBreadcrumbUrl;

            this.setBreadcrumbList(breadcrumb, targetUrl);
        }

        if (activatedRoute.firstChild !== null) {
            this.buildBreadcrumb(activatedRoute.firstChild);
        }
    }

    private getCurrentUrl(activatedRoute: ActivatedRoute): string {
        const breadcrumb = activatedRoute.snapshot.data['breadcrumb'] as Breadcrumb;

        return breadcrumb.url || activatedRoute?.routeConfig?.path || '';
    }

    private setBreadcrumbList(breadcrumb: Breadcrumb, url: string) {
        if (this.hasDuplicateBreadcrumb(breadcrumb)) {
            return;
        }

        this.breadcrumbList.push({
            title: breadcrumb.title,
            url,
        });
    }

    private hasDuplicateBreadcrumb(breadcrumb: Breadcrumb): boolean {
        return this.breadcrumbList.some((breadcrumbItem: Breadcrumb) => breadcrumbItem.title === breadcrumb.title);
    }
}
