import { ContentChildren, Directive, QueryList } from '@angular/core';
import { ScrollableItemDirective } from './scrollable-item.directive';

@Directive({
    selector: '[eliasScrollableContainer]',
})
export class ScrollableContainerDirective {
    @ContentChildren(ScrollableItemDirective, { descendants: true }) items?: QueryList<ScrollableItemDirective>;

    constructor() {}

    public scrollTo(id: string): void {
        const component = this.getComponentById(id);
        component?.elementRef.nativeElement?.scrollIntoView({
            behavior: 'smooth',
        });
    }

    private getComponentById(id: string): ScrollableItemDirective | undefined {
        return this.items?.find((component) => component.id === id);
    }
}
