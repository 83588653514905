import { Injectable } from '@angular/core';
import { AssetBrowserState, AssetBrowserStore } from './asset-browser.store';
import { QueryEntity } from '@datorama/akita';
import { Asset } from '../../../../modules/shared/models/asset.model';

@Injectable({
    providedIn: 'root',
})
export class AssetBrowserQuery extends QueryEntity<AssetBrowserState, Asset> {
    selectViewOption$ = this.select((state) => state.ui.viewOption);
    selectFilters$ = this.select((state) => state.ui.filters);
    selectSort$ = this.select((state) => state.ui.sort);
    selectSearch$ = this.select((state) => state.ui.search);

    constructor(protected override store: AssetBrowserStore) {
        super(store);
    }
}
