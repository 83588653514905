export enum Color {
    gray = '#334756',
    mint = '#00DBC9',
    violet = '#8526FF',
    ruby = '#FF2654',
    yellow = '#FCAB24',
    blue = '#0099FF',
    green = '#C6FF2E',
    orange = '#F26E50',
}
