import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/visualchars';
import 'tinymce/icons/default';
import '../../../../../../assets/tinymce/plugins/colbreak/index';
import '../../../../../../assets/tinymce/plugins/spellchecker/index';
import { TextNode } from '../../../models/node/text-node.model';
import { Observable } from 'rxjs';
import { NodeConfig, NodeType } from '../../../models/node/node-type.model';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';

@Component({
    selector: 'elias-editor-edit-text',
    templateUrl: './edit-text.component.html',
    styleUrls: ['./edit-text.component.scss'],
})
export class EditTextComponent implements NodeEdit, NodeRepresentation, OnInit {
    @Input() config?: NodeConfig;
    @Input() content: string = '';
    @Input() node?: TextNode;
    @Input() nodeViewModel?: Observable<TextNode>;

    @Output() contentChange = new EventEmitter<any>();

    localContent: string = '';
    type: NodeType = NodeType.unknown;
    containerId: string = 'tinymce';
    titleClass: string = 'h2';

    ngOnInit(): void {
        this.containerId = 'tinymce-' + this.node?.id;
        this.localContent = this.content;
        this.type = this.config?.type ?? NodeType.unknown;

        this.nodeViewModel?.subscribe((nodeViewModel) => {
            if (nodeViewModel !== null && nodeViewModel.hasOwnProperty('level') && nodeViewModel.level !== undefined) {
                const levelInt = nodeViewModel.level;
                this.titleClass = levelInt > 0 && levelInt <= 6 ? 'h' + levelInt : 'h2';
            }
        });
    }

    public onContentChange(content: string): void {
        this.content = content;
        this.contentChange.emit(this.content);
    }
}
