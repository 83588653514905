<ng-container [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field appearance="outline" [hideRequiredMarker]="false">
            <mat-select
                (selectionChange)="onWorksheetSelect($event)"
                [formControlName]="name"
                required
                placeholder="{{ 'component.excel.selectworksheet' | translate }}">
                <mat-option value="">
                    {{ 'component.excel.wait' | translate }}
                </mat-option>
                <ng-container *ngFor="let option of worksheetsOptions">
                    <mat-option *ngIf="!option.disabled" [value]="option.key">
                        {{ option.value }}
                    </mat-option>
                </ng-container>
            </mat-select>
            <mat-hint>{{ 'component.excel.selectworksheet' | translate }}</mat-hint>
            <mat-error *ngIf="formGroup.get(name).hasError('required')">
                {{ 'component.excel.worksheeterror' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
</ng-container>
