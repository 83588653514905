import { Component } from '@angular/core';

@Component({
    selector: 'elias-shared-sidebar-widget-scrollable',
    styleUrls: ['scrollable-sidebar-widget.component.scss'],
    template: ` <div class="widget">
        <ng-content></ng-content>
    </div>`,
})
export class ScrollableSidebarWidgetComponent {
    constructor() {}
}
