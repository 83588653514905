import { QueryEntity } from '@datorama/akita';
import { ImagesState, ImagesStore } from './images.store';
import { Injectable } from '@angular/core';
import { Image } from '../../models/image.model';

@Injectable({
    providedIn: 'root',
})
export class ImagesQuery extends QueryEntity<ImagesState, Image> {
    constructor(protected override store: ImagesStore) {
        super(store);
    }
}
