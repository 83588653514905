import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Lock } from '../../models/lock.model';
import { Injectable } from '@angular/core';

export interface LocksState extends EntityState<Lock> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Locks', resettable: true, idKey: 'sectionId' })
export class LocksStore extends EntityStore<LocksState, Lock> {
    constructor() {
        super({ loaded: false });
    }
}
