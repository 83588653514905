import { LinksGeneratorRegistry } from './links-generator.registry';
import { LinksGeneratorInterface } from '../interfaces/links-generator.interface';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';
import { SectionCommentLinksProvider } from '../providers/section-comment-links.provider';

@LinksGeneratorRegistry.register
export class SectionCommentLinksGenerator implements LinksGeneratorInterface {
    canGenerate(provider: LinksProviderInterface): provider is SectionCommentLinksProvider {
        return provider instanceof SectionCommentLinksProvider;
    }

    generate(provider: LinksProviderInterface): any[] | null {
        if (!this.canGenerate(provider)) {
            return null;
        }

        const { publicationGroupId, publicationId, sectionId, commentId } = provider.getParameters();

        return [
            '/publication-groups',
            publicationGroupId,
            'publication',
            publicationId,
            'editor',
            'section',
            sectionId,
            { comment: commentId },
        ];
    }
}
