export class CommentDraftDto {
    constructor(private content: string, private repliedCommentId: string | null) {}

    public getContent(): string {
        return this.content;
    }

    public getRepliedCommentId(): string | null {
        return this.repliedCommentId;
    }
}
