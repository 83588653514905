import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PublicationsQuery } from '../../../publication/state/publications/publications.query';
import { SectionsQuery } from '../state/sections/sections.query';
import { SectionsService } from '../services/sections.service';
import { switchMap } from 'rxjs/operators';
import { UrlParamsService } from '../../../modules/core/services/url-params.service';

@Injectable()
export class ChannelGuard implements CanActivate {
    constructor(
        private router: Router,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private publicationsQuery: PublicationsQuery,
        private urlParamsService: UrlParamsService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const sectionId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(route, 'sectionId');

        return this.checkAvailChannels(sectionId).pipe(
            switchMap((checkSuccess) => {
                if (checkSuccess) {
                    return of(true);
                } else {
                    return of(false);
                }
            })
        );
    }

    checkAvailChannels(sectionId): Observable<boolean> {
        const section = this.sectionsQuery.getEntity(sectionId);
        if (section) {
            const pub = this.publicationsQuery.getAll({
                filterBy: (entity) => entity.rootSectionId == section.rootId,
            });
            const publication = pub[0];

            let channelLength = 0;
            for (const key of Object.keys(publication.channelSettings)) {
                if (publication.channelSettings[key]['active'] && publication.channelSettings[key]['channel'].enabled) {
                    channelLength = channelLength + 1;
                }
            }

            if (channelLength > 0) {
                return of(true);
            } else {
                this.router.navigate(['/publications', publication.id]);
                return of(false);
            }
        } else {
            return new Observable<boolean>((observer) => {
                return this.sectionsService.getPublicationBySectionId(sectionId).subscribe(
                    (publication) => {
                        let channelLength = 0;
                        for (const key of Object.keys(publication.channelSettings)) {
                            if (
                                publication.channelSettings[key]['active'] &&
                                publication.channelSettings[key]['channel'].enabled
                            ) {
                                channelLength = channelLength + 1;
                            }
                        }
                        if (channelLength > 0) {
                            observer.next(true);
                            observer.complete();
                            return of(true);
                        } else {
                            this.router.navigate(['/publications', publication.id]);
                            return of(false);
                        }
                    },
                    async () => {
                        await this.router.navigate(['**'], { skipLocationChange: true });
                    }
                );
            });
        }
    }
}
