import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'elias-shared-dialog-error',
    templateUrl: 'error.component.html',
})
export class ErrorComponent implements OnInit {
    type: string;

    constructor(public dialogRef: MatDialogRef<ErrorComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        if (this.data) {
            this.type = this.data.type;
        }
    }

    public onCancel() {
        this.dialogRef.close(false);
    }

    public onConfirm() {
        this.dialogRef.close(true);
    }
}
