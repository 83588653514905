import { Injectable } from '@angular/core';
import { DomainService } from '../../core/services/domain.service';
import { HttpClient } from '@angular/common/http';
import { SystemInformationStore } from '../state/system-information/system-information.store';
import { SystemInformationQuery } from '../state/system-information/system-information.query';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SystemInformationService {
    constructor(
        private domainService: DomainService,
        private systemInformationStore: SystemInformationStore,
        private systemInformationQuery: SystemInformationQuery,
        private http: HttpClient
    ) {}

    getSystemInformation() {
        this.systemInformationStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/system-information`;
        const request$ = this.http.get(url).pipe(
            tap((sysInfo) => {
                this.systemInformationStore.update(sysInfo);
                this.systemInformationStore.update({ loading: false, loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
        return this.systemInformationQuery.getHasCache() ? of() : request$;
    }
}
