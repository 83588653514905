import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'strlen' })
export class StrlenPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    transform(value, ...args: any[]) {
        if (value.length > args[0]) {
            return value.substr(0, args[0] - 3) + '…';
        } else {
            return value;
        }
    }
}
