import {
    AfterViewInit,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    OnDestroy,
    Output,
    QueryList,
} from '@angular/core';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { fromIntersectionObserver } from '../observables/fromIntersectionObserver';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { InfiniteScrollingItemDirective } from './infinite-scrolling-item.directive';

@Directive({
    selector: '[eliasInfiniteScrollingContainer]',
})
export class InfiniteScrollingContainerDirective implements AfterViewInit, OnDestroy {
    @ContentChildren(InfiniteScrollingItemDirective, { descendants: true, read: ElementRef })
    items?: QueryList<ElementRef>;
    @Output() lastItemReached = new EventEmitter();

    private destroyed$ = new ReplaySubject<void>(1);

    ngAfterViewInit() {
        this.items?.changes
            .pipe(
                switchMap(() => this.handleInfiniteScrolling()),
                takeUntil(this.destroyed$)
            )
            .subscribe();
    }

    private handleInfiniteScrolling(): Observable<any> {
        if (this.items === undefined || this.items.length === 0) {
            return EMPTY;
        }

        return fromIntersectionObserver(() => this.items!.last?.nativeElement).pipe(
            filter(({ entries }) => entries.length > 0 && entries[0].isIntersecting),
            tap(() => {
                this.lastItemReached.emit();
            })
        );
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
