import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'elias-shared-placeholder',
    styleUrls: ['./placeholder.component.scss'],
    templateUrl: './placeholder.component.html',
})
export class PlaceholderComponent {
    @Input() message = '';
}
