import { QueryEntity } from '@datorama/akita';
import { LocksState, LocksStore } from './locks.store';
import { Lock } from '../../models/lock.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocksQuery extends QueryEntity<LocksState, Lock> {
    constructor(protected override store: LocksStore) {
        super(store);
    }

    public getSectionLock(sectionId: string): Observable<Lock | null> {
        return this.selectAll({
            filterBy: ({ section }) => section.id === sectionId,
        }).pipe(map((locks) => (locks && locks.length > 0 ? locks[0] : null)));
    }
}
