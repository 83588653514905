import { UiStore } from '../state/ui/ui.store';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UiService {
    constructor(private uiStore: UiStore) {}

    setToolbarState(state): void {
        this.uiStore.update((uiState) => ({
            toolbar: { ...uiState.toolbar, state },
        }));
    }

    setNodeHeight(nodeHeight = 100) {
        this.uiStore.update((uiState) => ({
            nodeHeight,
        }));
    }
}
