import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appHighlight]',
})
export class HighlightDirective {
    constructor(private el: ElementRef) {}

    @Input('appHighlight') highlightColor = '';

    @HostListener('mouseover') onMouseEnter() {
        this.highlight(this.highlightColor);
    }

    @HostListener('mouseout') onMouseLeave() {
        if (this.highlightColor.includes('hover-')) {
            this.highlight('inactiveColor');
        } else {
            this.highlight(this.highlightColor);
        }
    }

    private highlight(color: string) {
        if (color.includes('hover-')) {
            const val = color.slice(6);
            this.el.nativeElement.style.backgroundColor = this.getRGBA(val);
            this.el.nativeElement.style.color = val;
            this.el.nativeElement.style.border = '1px solid ' + val;
        } else if (color == 'inactiveColor') {
            this.el.nativeElement.style.backgroundColor = 'white';
            this.el.nativeElement.style.color = '#C1C1C1';
            this.el.nativeElement.style.border = '1px solid #C1C1C1';
        } else {
            this.el.nativeElement.style.backgroundColor = color;
            this.el.nativeElement.style.color = 'white';
        }
    }

    private getRGBA(value) {
        const r = parseInt(value.slice(1, 3), 16);
        const g = parseInt(value.slice(3, 5), 16);
        const b = parseInt(value.slice(5, 7), 16);
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + 0.4 + ')';
    }
}
