import { Observable } from 'rxjs';

interface FromIntersectionObserverContext {
    entries: IntersectionObserverEntry[];
    restartIntersectionObserver: Function;
}

export const fromIntersectionObserver = (
    getTargetFn: () => Element | null,
    options?: IntersectionObserverInit
): Observable<FromIntersectionObserverContext> => {
    return new Observable((observer) => {
        function observeTarget() {
            const target = getTargetFn();
            if (target !== null) {
                intersectionObserver.observe(target);
            }
        }

        const intersectionObserver = new IntersectionObserver((entries) => {
            const restartIntersectionObserver = () => {
                intersectionObserver.disconnect();
                observeTarget();
            };

            observer.next({ entries, restartIntersectionObserver });
        }, options);

        observeTarget();

        return () => {
            intersectionObserver.disconnect();
        };
    });
};
