import { Component, Input } from '@angular/core';
import { VersionDiff } from '../../models/version-diff.model';

@Component({
    selector: 'elias-version-diff-properties',
    templateUrl: './version-diff-properties.component.html',
    styleUrls: ['./version-diff-properties.component.scss'],
})
export class VersionDiffPropertiesComponent {
    @Input() propertiesDiff?: VersionDiff;

    constructor() {}
}
