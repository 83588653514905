<div class="modal-container refresh-or-logout-container" style="height: 350px">
    <h1 mat-dialog-title>
        {{ 'modal.maintenance.title' | translate }}
    </h1>

    <div mat-dialog-content class="modal-content">
        <p>
            {{ 'modal.maintenance.message' | translate }}
        </p>
    </div>

    <div mat-dialog-actions class="modal-action-buttons">
        <button mat-stroked-button color="warn" (click)="close()" class="modal-save-button">
            {{ 'modal.maintenance.confirm' | translate }}
        </button>
    </div>
</div>
