import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../../models/section.model';
import { MatDialog } from '@angular/material/dialog';
import { ChangeSectionTypeComponent } from '../../dialogs/change-section-type/change-section-type.component';
import { LayoutService } from '../../services';
import { PublicationsQuery } from '../../../../publication/state/publications/publications.query';

@Component({
    selector: 'elias-section-type-editor',
    templateUrl: './section-type-editor.component.html',
    styleUrls: ['./section-type-editor.component.scss'],
})
export class SectionTypeEditorComponent {
    @Input() section?: Section;

    public disabled = false;

    constructor(
        public dialog: MatDialog,
        private layoutService: LayoutService,
        private publicationsQuery: PublicationsQuery
    ) {
        const sectionTypeOptions = this.layoutService.getSectionTypeOptions(this.publicationsQuery.getActive());
        if (sectionTypeOptions.length < 2) {
            this.disabled = true;
        }
    }

    public changeSectionType(): void {
        this.dialog.open(ChangeSectionTypeComponent, {
            width: '800px',
            height: '600px',
            autoFocus: false,
            data: {
                section: this.section,
            },
        });
    }
}
