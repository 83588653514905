import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomainService } from './domain.service';
import { concatMap, filter, pluck, tap } from 'rxjs/operators';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { SystemInformationStore } from '../../shared/state/system-information/system-information.store';
import { Update, Updates } from '../models/update.model';
import { UpdateResponse } from '../models/update-response.model';
import { UpdateKey } from '../enums/update-key.enum';

@Injectable({
    providedIn: 'root',
})
export class UpdateService {
    public updatePoll$: Observable<any>;

    private lastUpdated: string | null = null;
    private updates$ = new BehaviorSubject<Updates | null>(null);

    constructor(
        private domainService: DomainService,
        private http: HttpClient,
        private systemInformationStore: SystemInformationStore
    ) {
        const updateInterval = systemInformationStore.getValue().updateInterval * 1000;
        this.updatePoll$ = interval(updateInterval).pipe(
            concatMap(() => this.fetchUpdates()),
            tap((response: UpdateResponse) => {
                const { lastUpdated, updates } = response;
                this.updates$.next(updates);
                this.lastUpdated = lastUpdated;
            })
        );
    }

    public getUpdates<T extends Update>(key: UpdateKey): Observable<T> {
        return this.updates$.pipe(
            filter((updates): updates is Updates => updates !== null),
            pluck(key),
            filter((update): update is T => update !== undefined)
        );
    }

    private fetchUpdates(): Observable<UpdateResponse> {
        const url = `${this.domainService.apiBaseUrl}/updates`;

        if (this.lastUpdated === null) {
            return this.http.get<UpdateResponse>(url);
        }

        return this.http.get<UpdateResponse>(url, { params: { since: this.lastUpdated } });
    }
}
