import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { LocksService, SectionsService } from '../services';
import { Lock } from '../models/lock.model';
import { LockDto } from '../models/dto/lock-dto.model';
import { TreeService } from '../services/tree.service';
import { FlashMessageService } from '../../../modules/core/services/flash-message.service';
import { UrlParamsService } from '../../../modules/core/services/url-params.service';
import { SectionsQuery } from '../state/sections/sections.query';

@Injectable()
export class SectionLockGuard implements CanActivate {
    constructor(
        private flashMessageService: FlashMessageService,
        private lockService: LocksService,
        private router: Router,
        private sectionsQuery: SectionsQuery,
        private sectionsService: SectionsService,
        private treeService: TreeService,
        private urlParamsService: UrlParamsService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<any> {
        const sectionId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(route, 'sectionId');

        if (!sectionId) {
            return of(false);
        }

        return this.lockSection(sectionId).pipe(
            tap(() => {
                this.treeService.setActive(sectionId);
                this.sectionsService.setActiveSectionById(sectionId);
            }),
            catchError(async (error) => {
                if (error.status === 404) {
                    await this.router.navigate(['**'], { skipLocationChange: true });

                    return of(false);
                }

                this.flashMessageService.showError(error.error.message);
                this.treeService.removeFocus(sectionId);

                return this.redirectToLastAllowedSection(route);
            })
        );
    }

    private lockSection(id: string): Observable<boolean> {
        const payload = new LockDto({ sectionId: id });

        return this.lockService.addLock(payload).pipe(
            map((locks: Lock[]) => locks[0].hasOwnProperty('section')),
            take(1)
        );
    }

    private redirectToLastAllowedSection(route: ActivatedRouteSnapshot): Promise<boolean> {
        const publicationGroupId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(
            route,
            'publicationGroupId'
        );
        const publicationId = this.urlParamsService.getNestedParamFromActivatedRouteSnapshot(route, 'publicationId');

        // Stay on the current section
        if (this.sectionsQuery.getActiveId()) {
            return this.router.navigate([
                '/publication-groups',
                publicationGroupId,
                'publications',
                publicationId,
                'editor',
                'sections',
                this.sectionsQuery.getActiveId(),
            ]);
        }

        return this.router.navigate([
            '/publication-groups',
            publicationGroupId,
            'publications',
            publicationId,
            'editor',
            'sections',
        ]);
    }
}
