import { Component } from '@angular/core';

@Component({
    selector: 'elias-core-access-denied',
    template: `<h2>You have been denied access to the publication</h2>
        <p>You will be redirected to home page</p>`,
    styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
    constructor() {}
}
