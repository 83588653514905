import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // removed files route
    // TODO: Change it so that the caching works for file and editor module
    allowRetriveCache = {
        'publication/:id/editor': true,
        'publication/:id/editor/section': true,
        'publication/:id/editor/section/:sectionId': true,
    };

    shouldReuseRoute(curr: ActivatedRouteSnapshot, before: ActivatedRouteSnapshot): boolean {
        if (
            this.allowRetriveCache.hasOwnProperty(this.getPath(before)) &&
            this.allowRetriveCache.hasOwnProperty(this.getPath(curr))
        ) {
            this.allowRetriveCache['publication/:id/editor'] = true;
            this.allowRetriveCache['publication/:id/editor/section'] = true;
            this.allowRetriveCache['publication/:id/editor/section/:sectionId'] = true;
        } else {
            this.allowRetriveCache['publication/:id/editor'] = false;
            this.allowRetriveCache['publication/:id/editor/section'] = false;
            this.allowRetriveCache['publication/:id/editor/section/:sectionId'] = false;
        }

        return before.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const path = this.getPath(route);
        if (this.storedRouteHandles.has(path) && this.allowRetriveCache[path] && this.storedRouteHandles.get(path)) {
            return this.storedRouteHandles.get(this.getPath(route));
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if (this.allowRetriveCache[path]) {
            return this.storedRouteHandles.has(this.getPath(route));
        }

        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if (this.allowRetriveCache.hasOwnProperty(path) && this.allowRetriveCache[path]) {
            return true;
        }
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        if (this.allowRetriveCache[this.getPath(route)]) {
            this.storedRouteHandles.set(this.getPath(route), detachedTree);
        }
    }

    private getPath(route: ActivatedRouteSnapshot) {
        return route.pathFromRoot
            .map((route) => route.routeConfig && route.routeConfig.path)
            .filter((path) => !!path)
            .join('/');
    }
}
