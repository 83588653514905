import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Map } from '../../models/map.model';

export interface MapsState extends EntityState<Map> {
    loaded: boolean;
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Maps', resettable: true })
export class MapsStore extends EntityStore<MapsState, Map> {
    constructor() {
        super({ loaded: false });
    }
}
