import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ColumnsReorganizerService {
    public reorganizeColumns(content: any[], newNumberOfColumns: number): any[] {
        // if cols are added
        if (newNumberOfColumns > content.length) {
            for (let i = content.length; i < newNumberOfColumns; i++) {
                content[i] = [];
            }
        }
        // if cols are removed
        else {
            for (let i = content.length - 1; i >= newNumberOfColumns; i--) {
                if (content[i].length > 0) {
                    content[i - 1] = content[i - 1].concat(content[i]);
                }
                content.splice(i, 1);
            }
        }

        return content;
    }
}
