import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { DomainService } from '../../../modules/core/services/domain.service';
import { PaginatedResult } from '../../../modules/shared/models/paginated-result.model';
import { QueryParameters } from '../../../modules/core/models/query-parameters.model';
import { QueryParametersBuilder } from '../../../modules/core/builders/query-parameters.builder';
import { catchError, map, tap } from 'rxjs/operators';
import { NodeDto } from '../../editor/models/dto/node-dto.model';
import { Node } from '../../editor/models/node/node.model';
import { Version } from '../models/version.model';
import { PaginatedVersions } from '../models/paginated-versions.model';
import { NodesService } from '../../editor/services';
import { VersionDiff } from '../models/version-diff.model';

@Injectable({
    providedIn: 'root',
})
export class VersionsService {
    public versions$: Observable<Version[]>;
    private versionsSubject = new BehaviorSubject<Version[]>([]);

    constructor(private domainService: DomainService, private http: HttpClient, private nodesService: NodesService) {
        this.versions$ = this.versionsSubject
            .asObservable()
            .pipe(map((versions) => versions.filter((version) => version.log.action !== 'deleted')));
    }

    public getVersions(nodeId: string, options: any = {}): Observable<PaginatedResult> {
        const url = `${this.domainService.apiBaseUrl}/nodes/${nodeId}/versions`;

        return this.http.get<PaginatedVersions>(url, { params: options }).pipe(
            tap((data) => {
                const currentVersions = this.versionsSubject.getValue();
                this.versionsSubject.next([...currentVersions, ...data.data]);
            }),
            catchError((error: any) => throwError(error))
        );
    }

    public getCurrentRequestOptions(page: number): QueryParameters {
        const queryParametersBuilder = new QueryParametersBuilder();
        queryParametersBuilder.addPage(page);

        return queryParametersBuilder.getQueryOptions();
    }

    public recreateRemovedNode(
        versionId: string,
        sectionId: string,
        type: string
    ): Observable<{ nodes: Node[]; node: Node }> {
        const url = `${this.domainService.apiBaseUrl}/sections/${sectionId}/nodes/${type}?versionId=${versionId}`;
        return this.http.post<{ nodes: Node[]; node: Node }>(url, {});
    }

    public restoreNodeVersion(nodeId: string, versionId: string): Observable<Node> {
        return this.nodesService.updateNode(new NodeDto({ nodeId }, { versionId }));
    }

    public getDiffForNodeAndVersion(
        nodeId: string,
        versionId: string,
        simulateRestore?: boolean
    ): Observable<VersionDiff> {
        const url = `${this.domainService.apiBaseUrl}/nodes/${nodeId}/versions/${versionId}/diff`;

        let params = new HttpParams();
        if (simulateRestore) {
            params = params.set('simulateRestore', 'true');
        }
        return this.http.get<VersionDiff>(url, { params }).pipe(catchError((error: any) => throwError(error)));
    }

    public resetStore(): void {
        this.versionsSubject.next([]);
    }
}
