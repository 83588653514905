<div class="container">
    <elias-shared-sidebar-title title="{{ 'versioning.title' | translate }}"></elias-shared-sidebar-title>

    <div class="version-list" eliasInfiniteScrollingContainer (lastItemReached)="loadMoreVersions()">
        <elias-version-card
            *ngFor="let version of versions$ | async"
            [version]="version"
            [creator]="creators[version.log.createdBy]"
            [nodeId]="nodeId"
            (versionOpened)="displayChanges($event)"
            eliasInfiniteScrollingItem>
        </elias-version-card>
    </div>

    <ng-container *ngIf="!loading && (versions$ | async)?.length === 0">
        <elias-shared-placeholder [message]="'versioning.placeholder' | translate"></elias-shared-placeholder>
    </ng-container>

    <elias-shared-loading *ngIf="loading"></elias-shared-loading>
</div>
