import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponent } from '../form/form.component';
import { SideNavFormButtonsAnimation } from '../../../shared/animations';

@Component({
    selector: 'elias-form-side-nav-buttons',
    templateUrl: './form-side-nav-buttons.component.html',
    animations: [SideNavFormButtonsAnimation],
})
export class FormSideNavButtonsComponent {
    @Input() forms: FormComponent[] = [];
    @Input() isSaving: boolean = false;

    @Output() formsSubmitted = new EventEmitter<void>();
    @Output() formsCancelled = new EventEmitter<void>();

    get isVisible(): boolean {
        return this.areAllValid() && this.isAnyDirty();
    }

    get numberOfChanges(): number {
        return this.forms.reduce((acc, form) => acc + form.getNumberOfChanges(), 0);
    }

    public onSubmit(): void {
        for (let form of this.forms) {
            if (form.canSave()) {
                form.onSubmit();
            }
        }

        this.formsSubmitted.emit();
    }

    public onCancel(): void {
        this.formsCancelled.emit();
    }

    private areAllValid(): boolean {
        for (let form of this.forms) {
            if (!form.isValid()) {
                return false;
            }
        }

        return true;
    }

    private isAnyDirty(): boolean {
        for (let form of this.forms) {
            if (form.isDirty()) {
                return true;
            }
        }

        return false;
    }
}
