import { Component } from '@angular/core';

@Component({
    selector: 'elias-shared-sidebar-left',
    styleUrls: ['sidebar-left.component.scss'],
    template: ' <div class="sidebar"><ng-content></ng-content></div>',
})
export class SidebarLeftComponent {
    constructor() {}
}
