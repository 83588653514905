import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { StylesheetState, StylesheetStore } from './stylesheet.store';

@Injectable({
    providedIn: 'root',
})
export class StylesheetQuery extends Query<StylesheetState> {
    constructor(protected override store: StylesheetStore) {
        super(store);
    }
}
