import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { SelectedElement } from '../../models/selected-element.model';

export interface PropertyEditorState {
    sectionConfigurations: any;
    nodeConfigurations: any;
    selectedElement: SelectedElement | null;
    loaded: boolean;
    loading: boolean;
}

export function createInitialState(): PropertyEditorState {
    return {
        sectionConfigurations: null,
        nodeConfigurations: null,
        selectedElement: null,
        loaded: false,
        loading: false,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'PropertyEditor', resettable: true })
export class PropertyEditorStore extends Store<PropertyEditorState> {
    constructor() {
        super(createInitialState());
    }
}
