import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import { Node } from '../../../models/node/node.model';
import { Observable } from 'rxjs';
import { HotTableComponent, HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { FlashMessageService } from '../../../../../modules/core/services/flash-message.service';
import { NodeType } from '../../../models/node/node-type.model';

@Component({
    selector: 'elias-editor-edit-table',
    styleUrls: ['./edit-table.component.scss'],
    templateUrl: './edit-table.component.html',
})
export class EditTableComponent implements NodeEdit, AfterViewInit, OnInit {
    @Input() config: { type: NodeType; settings?: any };
    @Input() content: string;
    @Input() node: Node;
    @Input() nodeViewModel: Observable<Node>;

    @Output() contentChange = new EventEmitter<any>();

    @ViewChild(HotTableComponent, { static: true }) hotTableComponent: HotTableComponent;
    hotInstance: Handsontable;
    hotRegisterer = new HotTableRegisterer();

    data: any[];
    editing = false;
    localContent: string;
    afterChange: any;
    afterCellOrRowCreate: any;

    constructor(private flashMessageService: FlashMessageService) {}

    parseJSONContent(data: string) {
        try {
            if (data) {
                const ans = JSON.parse(data);
                if (ans && typeof ans === 'object') {
                    return ans;
                }
            }
        } catch (e) {
            this.flashMessageService.showError('It is not a valid table data. Please check the input');
            return [];
        }
        return [];
    }

    ngOnInit(): void {
        const defaultSettings = {
            licenseKey: 'non-commercial-and-evaluation',
            renderer: 'html',
            renderAllRows: true,
            readOnly: false,
            disableVisualSelection: false,
            stretchH: 'all',
            preventOverflow: 'horizontal',
            height: 'auto',
            width: 'auto',
        };

        this.config.settings = {
            ...defaultSettings,
            ...this.config.settings,
        };

        // const content = this.content ? JSON.parse(this.content) : [];
        const content = this.parseJSONContent(this.content);
        if (content.hasOwnProperty('data')) {
            this.data = content.data;
        } else {
            // Compatibility when data was stored in content directly. Remove.
            this.data = content;
        }

        this.localContent = JSON.stringify({
            data: this.data,
        });

        if (!this.config.settings.readOnly && !this.config.settings.disableVisualSelection) {
            this.editing = true;
        }

        // define this method here in order to get access to class variables and methods
        const that = this;
        this.afterChange = (changes, source) => {
            const data = that.nullToEmptyString(that.hotInstance.getData());

            that.localContent = JSON.stringify({
                data: data,
            });
            that.contentChange.emit(this.localContent);
        };

        this.afterCellOrRowCreate = (e: any) => {
            const data = that.nullToEmptyString(that.hotInstance.getData());
            setTimeout(() => {
                that.hotInstance.loadData(data);
            }, 100);

            that.afterChange(e);
        };
    }

    ngAfterViewInit(): void {
        this.hotInstance = this.hotRegisterer.getInstance('hotTable');

        this.hotInstance.addHook('afterChange', this.afterChange);
        this.hotInstance.addHook('afterRemoveRow', this.afterChange);
        this.hotInstance.addHook('afterRemoveCol', this.afterChange);
        this.hotInstance.addHook('afterCopy', this.afterChange);
        this.hotInstance.addHook('afterCreateRow', this.afterCellOrRowCreate);
        this.hotInstance.addHook('afterCreateCol', this.afterCellOrRowCreate);
    }

    /**
     * Convert null values to empty string.
     *
     * @param {any[][]} table
     * @returns {any[][]}
     */
    nullToEmptyString(table: any[][]): any[][] {
        return table.map((row: any[]): any[] => row.map((cell: any): any => (cell ? cell : '')));
    }

    /**
     * Set alignment to cells.
     * It works but is unused because cell metadata is not connected to data, when inserting a new row, metadata is invalid.
     * @param alignments
     */
    setCellAlignments(alignments) {
        const hot = this.hotInstance;
        for (let rowKey = 0; rowKey < hot.countRows(); rowKey++) {
            for (let colKey = 0; colKey < hot.countCols(); colKey++) {
                if (alignments.hasOwnProperty(rowKey) && alignments[rowKey].hasOwnProperty(colKey)) {
                    hot.setCellMeta(rowKey, colKey, 'className', alignments[rowKey][colKey]);
                }
            }
        }

        hot.render();
    }
}
