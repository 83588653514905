import { Component } from '@angular/core';
import { SectionCommentsSidenavService } from '../../../comments/services/sidenav/section-comments-sidenav.service';
import { SectionsQuery } from '../../state/sections/sections.query';
import { Section } from '../../models/section.model';
import { Observable } from 'rxjs';
import { SectionSettingsSidenavService } from '../../services/sidenav/section-settings-sidenav.service';

@Component({
    selector: 'elias-section-buttons',
    templateUrl: './section-buttons.component.html',
    styleUrls: ['./section-buttons.component.scss'],
})
export class SectionButtonsComponent {
    public section$: Observable<Section>;

    constructor(
        private sectionCommentsSidenav: SectionCommentsSidenavService,
        private sectionSettingsSidenav: SectionSettingsSidenavService,
        private sectionsQuery: SectionsQuery
    ) {
        this.section$ = this.sectionsQuery.selectActive() as Observable<Section>;
    }

    public openPropertyEditor(): void {
        this.sectionSettingsSidenav.open();
    }

    public async openComments(preselectedCommentId?: string): Promise<void> {
        const section = this.sectionsQuery.getActive() as Section;
        await this.sectionCommentsSidenav.open(section, preselectedCommentId);
    }
}
