import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { StatusService } from '../../modules/shared/services/status.service';

@Injectable()
export class LoginResolver implements Resolve<any> {
    constructor(private router: Router, private statusService: StatusService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.statusService.getApplicationStatus();
    }
}
