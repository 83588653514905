<ng-container [ngSwitch]="element.type">
    <elias-form-element-static-heading
        [value]="element.value"
        [level]="element.level"
        *ngSwitchCase="'static-heading'"></elias-form-element-static-heading>

    <elias-form-element-static-text
        [value]="element.value"
        *ngSwitchCase="'static-text'"></elias-form-element-static-text>

    <elias-form-element-color
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [required]="element.required"
        [formGroup]="formGroup"
        [options]="element.options"
        [hint]="element.hint"
        [settings]="element.settings"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'color'">
    </elias-form-element-color>

    <elias-form-element-date
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [required]="element.required"
        [formGroup]="formGroup"
        [hint]="element.hint"
        [settings]="element.settings"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'date'">
    </elias-form-element-date>

    <elias-form-element-pills
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [required]="element.required"
        [formGroup]="formGroup"
        [options]="element.options"
        [settings]="element.settings"
        [hint]="element.hint"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'pills'"></elias-form-element-pills>

    <elias-form-element-input
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [required]="element.required"
        [formGroup]="formGroup"
        [hint]="element.hint"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'email'"></elias-form-element-input>

    <elias-form-element-input
        [name]="element.name"
        [label]="element.label"
        [type]="element.type"
        [placeholder]="element.placeholder"
        [required]="element.required"
        [formGroup]="formGroup"
        [settings]="element.settings"
        [hint]="element.hint"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'password'"></elias-form-element-input>

    <elias-form-element-input
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [required]="element.required"
        [hint]="element.hint"
        [settings]="element.settings"
        [formGroup]="formGroup"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'text'"></elias-form-element-input>

    <elias-form-element-textarea
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [required]="element.required"
        [hint]="element.hint"
        [formGroup]="formGroup"
        [settings]="element.settings"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'textarea'"></elias-form-element-textarea>

    <elias-form-element-select
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [hint]="element.hint"
        [options]="element.options"
        [settings]="element.settings"
        [required]="element.required"
        [initValue]="initValue ? initValue : element.default"
        [translationKey]="translationKey"
        *ngSwitchCase="'select'"></elias-form-element-select>

    <elias-form-element-select-publication
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [hint]="element.hint"
        [settings]="element.settings"
        [required]="element.required"
        [initValue]="initValue ? initValue : element.default"
        [translationKey]="translationKey"
        *ngSwitchCase="'select-publication'"></elias-form-element-select-publication>

    <elias-form-element-select-shared-storage
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [hint]="element.hint"
        [options]="element.options"
        [settings]="element.settings"
        [required]="element.required"
        [initValue]="initValue ? initValue : element.default"
        [translationKey]="translationKey"
        *ngSwitchCase="'select-shared-storage'"></elias-form-element-select-shared-storage>

    <elias-form-element-sortable-select
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [options]="element.options"
        [required]="element.required"
        [initValue]="initValue ? initValue : element.default"
        [translationKey]="translationKey"
        *ngSwitchCase="'sortable-select'"></elias-form-element-sortable-select>

    <elias-form-element-checkbox
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [text]="element.text"
        [initValue]="initValue"
        [deprecated]="element.deprecated"
        [disabled]="element.disabled"
        [translationKey]="translationKey"
        *ngSwitchCase="'checkbox'"></elias-form-element-checkbox>

    <elias-form-element-radio
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [options]="element.options"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'radio'"></elias-form-element-radio>

    <elias-form-element-asset
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [initValue]="initValue"
        [settings]="element.settings"
        [allowedTypes]="['image/jpeg', 'image/png', 'image/jpg']"
        [translationKey]="translationKey"
        *ngSwitchCase="'image'"></elias-form-element-asset>

    <elias-form-element-asset
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [settings]="element.settings"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'asset'"></elias-form-element-asset>

    <elias-form-element-excel-asset
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'excel-asset'"></elias-form-element-excel-asset>

    <elias-form-element-excel-worksheet
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'excel-worksheet'"></elias-form-element-excel-worksheet>

    <elias-form-element-person
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [disabledOptions]="disabledOptions"
        [options]="element.options"
        [multiple]="element.settings.multiple"
        [initValue]="initValue"
        [settings]="element.settings"
        [translationKey]="translationKey"
        *ngSwitchCase="'person'"></elias-form-element-person>

    <elias-form-element-channel
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [multiple]="element.settings.multiple"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'channel'"></elias-form-element-channel>

    <elias-form-element-channel-settings
        [name]="element.name"
        [label]="element.label"
        [placeholder]="element.placeholder"
        [formGroup]="formGroup"
        [initValue]="initValue"
        [translationKey]="translationKey"
        *ngSwitchCase="'channel-settings'"></elias-form-element-channel-settings>
</ng-container>
