import { Pipe, PipeTransform } from '@angular/core';
import { DayjsService } from '../services/dayjs.service';

@Pipe({
    name: 'timeAgo',
    pure: false,
})
export class TimeAgoPipe implements PipeTransform {
    constructor(private dayjsService: DayjsService) {}

    transform(value: Date): string {
        return this.dayjsService.fromNow(value);
    }
}
