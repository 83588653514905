import { PublicationsStore, PublicationsState } from './publications.store';
import { Publication } from '../../models/publication.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PublicationStatistics } from '../../../modules/shared/models/publication-statistics.model';

@Injectable({
    providedIn: 'root',
})
export class PublicationsQuery extends QueryEntity<PublicationsState, Publication> {
    constructor(protected override store: PublicationsStore) {
        super(store);
    }

    selectStatistics(): Observable<PublicationStatistics> {
        return this.select('statistics');
    }

    getStatistics(): PublicationStatistics {
        return this.getValue().statistics;
    }
}
