import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VersionDiff } from '../../models/version-diff.model';

@Component({
    selector: 'elias-restore-version',
    templateUrl: './restore-version.component.html',
    styleUrls: ['./restore-version.component.scss'],
})
export class RestoreVersionComponent implements OnInit {
    public diff?: VersionDiff;
    public changeDate: Date;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RestoreVersionComponent>) {
        this.changeDate = this.data?.log?.createdAt;
    }

    ngOnInit(): void {
        this.diff = this.data?.diff;
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public restoreVersion(): void {
        this.dialogRef.close(true);
    }
}
