import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { QuoteNode } from '../../../models/node/quote-node.model';
import { Observable } from 'rxjs';
import { NodeConfig } from '../../../models/node/node-type.model';

@Component({
    selector: 'elias-editor-display-quote',
    templateUrl: './display-quote.component.html',
    styleUrls: [],
})
export class DisplayQuoteComponent implements NodeRepresentation, OnInit {
    @Input() config?: NodeConfig;
    @Input() content: string = '';
    @Input() node?: QuoteNode;
    @Input() nodeViewModel?: Observable<QuoteNode>;

    ngOnInit() {
        this.config = this.config || { type: 'quote' };
    }
}
