import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Node } from '../models/node/node.model';
import { Section } from '../models/section.model';
import { NodesService } from './nodes.service';
import { NodeDto } from '../models/dto/node-dto.model';
import { SectionsService } from './sections.service';
import { SectionDto } from '../models/dto/section-dto.model';
import { GridNode } from '../models/node/grid-node.model';
import { ColumnsReorganizerService } from './utilities/columns-reorganizer.service';
import { SelectedElement } from '../models/selected-element.model';
import { SelectedElementType } from '../enums/selected-element-type.enum';

@Injectable()
export class SaveService {
    constructor(
        private columnsReorganizerService: ColumnsReorganizerService,
        private nodesService: NodesService,
        private sectionsService: SectionsService
    ) {}

    public saveProperties(selectedElement: SelectedElement, properties: any): Observable<Node | Section> {
        const body = this.preprocessProperties(selectedElement, properties);

        if (selectedElement.type === SelectedElementType.Node) {
            const payload = new NodeDto({ nodeId: selectedElement.element.id }, {}, body);
            return this.nodesService.updateNode(payload).pipe(
                tap((node: Node) => {
                    this.nodesService.deselect(node);
                })
            );
        } else {
            const payload = new SectionDto({ sectionId: selectedElement.element.id }, {}, body);
            return this.sectionsService.updateSection(payload);
        }
    }

    public saveNode(node: Node): Observable<Node> {
        const body = { content: node.content };
        const payload = new NodeDto({ nodeId: node.id }, {}, body);

        return this.nodesService.updateNode(payload);
    }

    private preprocessProperties(selectedElement: SelectedElement, properties: any): any {
        if (selectedElement.type === SelectedElementType.Section) {
            return properties;
        }

        const element = selectedElement.element as Node;

        switch (element.type) {
            case 'key-figure': {
                if ((element as GridNode).cols !== properties.cols) {
                    const parsedContent = JSON.parse(element.content);
                    const result = this.columnsReorganizerService.reorganizeColumns(parsedContent, properties.cols);

                    properties['content'] = JSON.stringify(result);
                }
            }
        }

        return properties;
    }
}
