import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'elias-update-detected',
    templateUrl: './update-detected.component.html',
    styleUrls: ['./update-detected.component.scss'],
})
export class UpdateDetectedComponent {
    constructor(private dialogRef: MatDialogRef<UpdateDetectedComponent>) {}

    public onClick(): void {
        window.location.reload();
    }

    public onClose(): void {
        this.dialogRef.close();
    }
}
