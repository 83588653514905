import { Injectable } from '@angular/core';

export type TreeItem<T> = T & { children: TreeItem<T>[] };

@Injectable({
    providedIn: 'root',
})
export class TreeUtilsService {
    public createTreeFromArray<T extends { id: any; parentId: any }>(flatArray: T[]): TreeItem<T>[] {
        const hashTable = Object.create(null);
        for (const item of flatArray) {
            hashTable[item.id] = { ...item, children: [] };
        }

        const dataTree = [];
        for (const item of flatArray) {
            if (item.parentId) {
                if (!hashTable[item.parentId]) {
                    throw new Error(
                        `The parent of section ${item.id}: ${item.parentId} was not found when building a section tree`
                    );
                }

                hashTable[item.parentId].children.push(hashTable[item.id]);
            } else {
                dataTree.push(hashTable[item.id]);
            }
        }
        return dataTree;
    }
}
