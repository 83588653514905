import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'elias-data-protection',
    templateUrl: './data-protection.component.html',
    styleUrls: ['./data-protection.component.scss'],
})
export class DataProtectionComponent {
    constructor(private dialogRef: MatDialogRef<DataProtectionComponent>) {}

    public onCancel(): void {
        this.dialogRef.close(false);
    }
}
