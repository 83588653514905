<div style="word-break: break-word">
    <blockquote class="editor pe-reset pe-style-quote" id="{{ containerId }}" [innerHTML]="localContent | html">
        <elias-tinymce-editor
            [content]="localContent"
            [elementId]="node?.id"
            [config]="config"
            (contentChange)="onContentChange($event)"></elias-tinymce-editor>
    </blockquote>
    <figcaption>{{ node?.authorName }} {{ node?.authorTitle }}</figcaption>
</div>
