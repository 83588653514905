<div fxLayout="row" class="action-buttons" fxLayoutGap="15%" *ngIf="isVisible" @slideInOut>
    <button
        color="accent"
        class="btn-save"
        data-cy="btn-save"
        data-karma="btn-save"
        mat-flat-button
        [disabled]="isSaving"
        (click)="onSubmit()">
        <ng-container *ngIf="!isSaving">
            {{ 'actions.save' | translate }}
        </ng-container>
        <ng-container *ngIf="isSaving">
            <mat-spinner [diameter]="15" color="accent"></mat-spinner>
        </ng-container>
    </button>
    <button mat-button class="btn-save" data-karma="btn-cancel" (click)="onCancel()">
        {{ 'actions.cancel' | translate }}
    </button>
    <div class="changes" *ngIf="numberOfChanges === 1">{{ numberOfChanges }} {{ 'shared.change' | translate }}</div>
    <div class="changes" *ngIf="numberOfChanges > 1">{{ numberOfChanges }} {{ 'shared.changes' | translate }}</div>
</div>
