import { Extension, KeyboardShortcutCommand } from '@tiptap/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

// Add all custom key bindings here
const customKeyBindings = ['Mod-Enter'] as const;
type CustomKeyBinding = (typeof customKeyBindings)[number];

export class TiptapKeyBindingsService {
    private customKeyBinding$ = new ReplaySubject<CustomKeyBinding>(1);

    constructor() {}

    public getConfiguration(): Extension {
        return Extension.create({
            name: 'customKeyBindings',
            addKeyboardShortcuts: () => {
                let shortcutCommands: { [p: string]: KeyboardShortcutCommand } = {};

                for (let key of customKeyBindings) {
                    shortcutCommands[key] = () => {
                        this.customKeyBinding$.next(key);
                        return true;
                    };
                }

                return shortcutCommands;
            },
        });
    }

    public getKeyBindingObservable(key: CustomKeyBinding): Observable<CustomKeyBinding> {
        return this.customKeyBinding$.pipe(filter((k) => k === key));
    }
}
