import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Section } from '../../models/section.model';
import { DocumentNodesComponent } from '../document-nodes/document-nodes.component';
import { SectionSettingsSidenavService } from '../../services/sidenav/section-settings-sidenav.service';

@Component({
    selector: 'elias-section-layout',
    templateUrl: './section-layout.component.html',
    styleUrls: ['./section-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SectionLayoutComponent implements OnChanges, AfterViewInit {
    @ViewChild('root') rootElement?: ElementRef;
    @Input() section?: Section;

    private nodesComponent?: ComponentRef<DocumentNodesComponent>;

    constructor(
        private renderer: Renderer2,
        private sectionSettingsSidenav: SectionSettingsSidenavService,
        private viewContainer: ViewContainerRef
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const sectionChange = changes['section'];

        if (
            !sectionChange.isFirstChange() &&
            sectionChange.previousValue['editorTemplate'] !== sectionChange.currentValue['editorTemplate']
        ) {
            this.renderSectionLayout();
        }
    }

    ngAfterViewInit() {
        this.renderSectionLayout();
    }

    private renderSectionLayout(): void {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = this.section?.editorTemplate ?? '';

        const sectionSettingsDOMElements = tempDiv.querySelectorAll('[section-settings]');
        this.addSectionSettingsListeners(sectionSettingsDOMElements);

        const nodesAreaDOMElement = tempDiv.querySelector('[nodes-area]');
        if (nodesAreaDOMElement) {
            this.createDocumentNodesComponent(nodesAreaDOMElement);
        }

        this.addSectionLayoutToDOM(tempDiv);
    }

    private createDocumentNodesComponent(nodeAreaDOMElement: Element): void {
        // Destroy previous nodes component
        this.nodesComponent?.destroy();

        this.nodesComponent = this.viewContainer.createComponent(DocumentNodesComponent);
        this.nodesComponent.changeDetectorRef.detectChanges();

        this.renderer.appendChild(nodeAreaDOMElement, this.nodesComponent.location.nativeElement);
    }

    private addSectionLayoutToDOM(sectionLayoutDOMElement: HTMLDivElement): void {
        const rootElement = this.rootElement!.nativeElement;
        rootElement.replaceChildren(...Array.from(sectionLayoutDOMElement.children));
    }

    private addSectionSettingsListeners(sectionSettingsDOMElements: NodeListOf<Element>) {
        sectionSettingsDOMElements.forEach((el) => {
            el.addEventListener('click', () => {
                this.sectionSettingsSidenav.open();
            });
        });
    }
}
