import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Node } from '../models/node/node.model';
import { NodeDto } from '../models/dto/node-dto.model';

@Injectable()
export class NodeViewModel {
    // Observable string sources
    private node = new BehaviorSubject<Node | undefined>(undefined);

    node$ = this.node.asObservable();

    // TODO - Replace with string if possible
    updateContent(content: any): void {
        const node = this.node.getValue();
        if (!node) {
            return;
        }

        this.node.next({ ...node, content });
    }

    updateProperties(properties: any): void {
        const node = this.node.getValue();
        this.node.next({ ...node, ...properties });
    }

    get(): NodeDto {
        return this.getNodeDto();
    }

    getNodeDto(): NodeDto {
        const node = this.node.getValue();

        const nodeForApi: Node = { ...node };
        delete nodeForApi.createdAt;
        delete nodeForApi.editing;
        delete nodeForApi.id;
        delete nodeForApi.nodeTypeDiscriminator;
        delete nodeForApi.type;
        delete nodeForApi.updatedAt;
        delete nodeForApi.sectionId;
        delete nodeForApi.position;

        return new NodeDto({ nodeId: node.id }, {}, nodeForApi);
    }

    getNode(): Node {
        return this.node.getValue();
    }

    set(node: Node): void {
        this.node.next(node);
    }

    reset(): void {
        this.node.next(null);
    }
}
