import { EventEmitter } from '@angular/core';
import { NodeRepresentation } from './node-representation.interface';

export interface NodeEdit extends NodeRepresentation {
    contentChange: EventEmitter<any>;
}

export const isNodeEdit = (componentRefInstance: any): componentRefInstance is NodeEdit => {
    return 'contentChange' in componentRefInstance;
};
