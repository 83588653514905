import {environment} from "../../../../environments/environment";

(function () {
    var colbreak = (function () {

        var global = tinymce.util.Tools.resolve('tinymce.PluginManager');

        var colBreakContent = '<hr class="col-break" />';

        var register = function (editor) {
            editor.addCommand('InsertColBreak', function () {
                editor.execCommand('mceInsertContent', false, colBreakContent);
            });
        };
        var $_c8b65jbnjk26xj9f = {register: register};

        var register$1 = function (editor) {

            editor.ui.registry.addIcon('colbreak', '<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
              '    <g id="icon/subdirectory_arrow_left" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
              '        <path d="M4.2925,15.41875 L8.8725,19.99875 C9.2625,20.38875 9.9025,20.38875 10.2925,19.99875 C10.6825,19.60875 10.6825,18.96875 10.2925,18.57875 L7.4125,15.70875 L17.5825,15.70875 C18.1325,15.70875 18.5825,15.25875 18.5825,14.70875 L18.5825,4.70875 C18.5825,4.15875 18.1325,3.70875 17.5825,3.70875 C17.0325,3.70875 16.5825,4.15875 16.5825,4.70875 L16.5825,13.70875 L7.4125,13.70875 L10.2925,10.83875 C10.6825,10.44875 10.6825,9.80875 10.2925,9.41875 C9.9025,9.02875 9.2625,9.02875 8.8725,9.41875 L4.2925,13.99875 C3.9025,14.38875 3.9025,15.02875 4.2925,15.41875 Z" id="icon/navigation/subdirectory_arrow_left_24px" fill="#334756"></path>\n' +
              '    </g>\n' +
              '</svg>')

            editor.ui.registry.addButton('colbreak', {
                icon: 'colbreak',
                tooltip: 'column break',
                /*cmd: 'InsertColBreak'*/
                onAction: (buttonApi) => editor.execCommand('InsertColBreak')
            });
        };
        var $_54b9ow6ojk26xj9g = {register: register$1};

        global.add('colbreak', function (editor) {
            $_c8b65jbnjk26xj9f.register(editor);
            $_54b9ow6ojk26xj9g.register(editor);
        });

        function Plugin() {
        }

        return Plugin;

    }());
})();
