import { Component, OnInit } from '@angular/core';
import { UserActivityService } from './modules/shared/services/user-activity.service';

@Component({
    selector: 'elias-app',
    template: ' <router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
    constructor(private userActivityService: UserActivityService) {}

    ngOnInit() {
        this.userActivityService.userActivity$.subscribe();
    }
}
