import { CategoriesStore, CategoriesState } from './categories.store';
import { Category } from '../../models/category.model';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CategoriesQuery extends QueryEntity<CategoriesState, Category> {
    constructor(protected override store: CategoriesStore) {
        super(store);
    }
}
