const NodeType = {
    text: 'text' as const,
    title: 'title' as const,
    lead: 'lead' as const,
    footnote: 'footnote' as const,
    disclaimer: 'disclaimer' as const,
    table: 'table' as const,
    maps: 'maps' as const,
    'excel-table': 'excel-table' as const,
    'line-chart': 'line-chart' as const,
    'bar-chart': 'bar-chart' as const,
    'bubble-chart': 'bubble-chart' as const,
    'map-chart': 'map-chart' as const,
    'paired-bar-chart': 'paired-bar-chart' as const,
    'paired-bar-line-chart': 'paired-bar-line-chart' as const,
    'accumulated-bar-chart': 'accumulated-bar-chart' as const,
    'pie-chart': 'pie-chart' as const,
    image: 'image' as const,
    'image-grid': 'image-grid' as const,
    line: 'line' as const,
    'two-cols-start': 'two-cols-start' as const,
    'two-cols-end': 'two-cols-end' as const,
    'page-break': 'page-break' as const,
    'col-break': 'col-break' as const,
    'table-of-content': 'table-of-content' as const,
    'key-figure': 'key-figure' as const,
    quote: 'quote' as const,
    unknown: 'unknown' as const,
};

type NodeType = (typeof NodeType)[keyof typeof NodeType];

export { NodeType };

export interface NodeConfig {
    type: NodeType;
    settings?: any;
}
