import { Query } from '@datorama/akita';
import { PropertyEditorState, PropertyEditorStore } from './property-editor.store';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PropertyEditorQuery extends Query<PropertyEditorState> {
    constructor(protected override store: PropertyEditorStore) {
        super(store);
    }
}
