import { UsersStore, UsersState } from './users.store';
import { User } from '../../models/user.model';
import { ID, QueryConfig, QueryEntity, SortBy } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Creators } from '../../models/creator.model';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

const sortBy: SortBy<User> = (a: User, b: User) => {
    return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
};

@Injectable({
    providedIn: 'root',
})
@QueryConfig({
    sortBy,
})
export class UsersQuery extends QueryEntity<UsersState, User> {
    selectViewOption$ = this.select((state) => state.ui.viewOption);
    selectFilters$ = this.select((state) => state.ui.filters);
    selectSearch$ = this.select((state) => state.ui.search);

    constructor(protected override store: UsersStore) {
        super(store);
    }

    getCreators(): Creators {
        return Object.fromEntries(
            this.getAll().map((user) => {
                const userId = user.id;
                const name = `${user.firstName} ${user.lastName}`;
                return [
                    userId,
                    {
                        name,
                        user,
                    },
                ];
            })
        );
    }

    getLoggedInUserId(): ID | undefined {
        return this.getValue().loggedInUserId;
    }

    getLoggedInUser(): User | undefined {
        const id = this.getLoggedInUserId();

        return id ? this.getEntity(id) : undefined;
    }

    selectLoggedInUser(): Observable<User | undefined> {
        return this.select('loggedInUserId').pipe(
            filter((id) => id !== undefined),
            switchMap((id) => this.selectEntity(id))
        );
    }

    hasLoggedInUser(): boolean {
        return !!this.getLoggedInUserId();
    }
}
