import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DecodedToken } from '../models/decoded-token.model';
import { RoleKey } from '../../modules/shared/enums/roles.enum';
import { LocalStorageKeys } from '../../modules/shared/enums/local-storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    constructor(private jwtHelper: JwtHelperService) {}

    public decodeToken(token: string | null): DecodedToken {
        return this.jwtHelper.decodeToken(token ?? '');
    }

    public getRolesFromToken(): RoleKey[] {
        const decodedToken = this.decodeToken(localStorage.getItem(LocalStorageKeys.Token) ?? '');
        return decodedToken.roles;
    }

    public getTokenExpirationDate(token: string | null): Date | null {
        return this.jwtHelper.getTokenExpirationDate(token ?? '');
    }

    public getUsernameFromToken(): string {
        const decodedToken = this.decodeToken(localStorage.getItem(LocalStorageKeys.Token) ?? '');
        return decodedToken.username;
    }

    public isTokenExpired(token: string | null): boolean {
        return this.jwtHelper.isTokenExpired(token ?? '');
    }
}
