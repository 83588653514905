import { Injectable } from '@angular/core';
import { FormConfig } from '../../../modules/forms/models/form-config.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class AddChannelSettingsForm {
    constructor(private translateService: TranslateService) {}

    public getConfiguration(): FormConfig {
        return {
            elements: [
                {
                    name: 'channelSettingsGroup',
                    type: 'group',
                    width: 100,
                    properties: [
                        {
                            type: 'static-heading',
                            value: this.translateService.instant('propertyEditor.group.channelSettingsGroup'),
                            level: 3,
                        },
                        {
                            type: 'static-text',
                            value: this.translateService.instant('propertyEditor.channelSettingsGroup.description'),
                        },
                        {
                            name: 'channelSettings',
                            type: 'channel-settings',
                            width: 100,
                        },
                    ],
                },
            ],
        };
    }
}
