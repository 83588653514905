import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormSortableSelectService } from '../../../service/form-sortable-select.service';
import { KeyValueOption } from '../../../../shared/models/key-value-option.model';
import { SortableSelectValue } from '../../../models/sortable-select-value.model';

@Component({
    selector: 'elias-form-element-sortable-select',
    templateUrl: './form-sortable-select.component.html',
    styleUrls: ['./form-sortable-select.component.scss'],
})
export class FormSortableSelectComponent extends BasicFormElementComponent implements OnInit {
    @Input() options: KeyValueOption[];

    sortableSelectValue: SortableSelectValue;
    objectKeys = Object.keys;

    constructor(injector: Injector, private formSortableSelectService: FormSortableSelectService) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initializeSortableSelectValue();
    }

    toggleActivation(option: string): void {
        if (option !== 'image') {
            setTimeout(() => {
                this.sortableSelectValue[option] = !this.sortableSelectValue[option];
                this.formGroup.get(this.name).patchValue(JSON.stringify(this.sortableSelectValue));
            }, 100);
        }
    }

    drop(event: CdkDragDrop<any[]>): void {
        const newData = _.cloneDeep(this.sortableSelectValue) as SortableSelectValue;
        const keys = Object.entries(newData);
        moveItemInArray(keys, event.previousIndex, event.currentIndex);

        const objectData = keys.reduce(function (p, c) {
            p[c[0]] = c[1];
            return p;
        }, {} as SortableSelectValue);
        this.sortableSelectValue = objectData;

        this.formGroup.get(this.name).patchValue(JSON.stringify(this.sortableSelectValue));
    }

    trackByIndex(index, item) {
        return index;
    }

    private initializeSortableSelectValue(): void {
        const fieldValue = JSON.parse(this.formGroup.get(this.name).value) as SortableSelectValue;
        this.sortableSelectValue = this.formSortableSelectService.mergeProvidedOptionsWithFieldValue(
            this.options,
            fieldValue
        );
    }
}
