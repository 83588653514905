import { Component, Input, OnInit } from '@angular/core';
import { ChannelSettingsDiffEntry, DiffEntry } from '../../models/diff-entry.model';
import { ChannelsQuery } from '../../../../modules/shared/state/channels/channels.query';

@Component({
    selector: 'elias-version-diff-channel-settings',
    templateUrl: './version-diff-channel-settings.component.html',
    styleUrls: ['./version-diff-channel-settings.component.scss'],
})
export class VersionDiffChannelSettingsComponent implements OnInit {
    @Input() channelSettings?: ChannelSettingsDiffEntry;

    public channelIds: string[] = [];

    constructor(private channelsQuery: ChannelsQuery) {}

    ngOnInit(): void {
        if (this.channelSettings) {
            this.channelIds = this.getChannelIds(this.channelSettings);
        }
    }

    public getChannelName(id: string): string {
        return <string>this.channelsQuery.getEntity(id)?.name;
    }

    public getChannelSettingStatus(
        channelSettingsDiffEntry: ChannelSettingsDiffEntry,
        channelId: string
    ): { before: boolean; after: boolean } {
        return {
            before: channelSettingsDiffEntry.before?.[channelId]?.active ?? false,
            after: channelSettingsDiffEntry.after?.[channelId]?.active ?? false,
        };
    }

    private getChannelIds(channelSettings: DiffEntry): string[] {
        return Object.keys(channelSettings.before);
    }
}
