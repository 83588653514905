import { LinksProviderInterface } from '../interfaces/links-provider.interface';

export class SharedStorageLinksProvider implements LinksProviderInterface {
    constructor(private publicationGroupId: string, private publicationId: string, private sharedStorageId: string) {}

    public getParameters() {
        return {
            publicationGroupId: this.publicationGroupId,
            publicationId: this.publicationId,
            sharedStorageId: this.sharedStorageId,
        };
    }
}
