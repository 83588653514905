<div class="chart" [ngClass]="{ 'initial-height': (content$ | async) === null }">
    <div *ngIf="isLoading$ | async">
        <elias-shared-loading [centered]="true" descriptionContext="chart"></elias-shared-loading>
    </div>

    <img
        *ngIf="content$ | async as path; else chartNotGenerated"
        [ngClass]="{ invisible: isLoading$ | async }"
        [src]="path" />

    <ng-template #chartNotGenerated>
        <div
            *ngIf="isGenerating$ | async; else chartError"
            class="processing-warning"
            [ngClass]="{ invisible: isLoading$ | async }">
            <div class="message">
                <i class="ei ei-processing processing-icon"></i>
                <div>{{ 'error.chartTooManyRetries' | translate }}</div>
            </div>

            <button class="refresh-button" (click)="reloadChart($event)">
                <i class="ei ei-refresh refresh-icon"></i>
            </button>
        </div>
    </ng-template>

    <ng-template #chartError>
        <div class="chart-error" [ngClass]="{ invisible: isLoading$ | async }">
            <div class="message">
                <i class="ei ei-error error-icon"></i>
                <div>{{ 'error.chartError' | translate }}</div>
            </div>

            <button class="refresh-button" (click)="regenerateChart($event)">
                <i class="ei ei-refresh refresh-icon"></i>
            </button>
        </div>
    </ng-template>
</div>
