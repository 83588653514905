import { Component, Input } from '@angular/core';

@Component({
    selector: 'elias-form-element-static-text',
    templateUrl: './form-static-text.component.html',
    styleUrls: ['./form-static-text.component.scss'],
})
export class FormStaticTextComponent {
    @Input() value: string;
}
