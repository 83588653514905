import { Injectable } from '@angular/core';
import { User } from '../../modules/shared/models/user.model';
import { Role } from '../../editor/editor/enums/role.enum';
import { Roles } from '../../modules/shared/enums/roles.enum';
import { UserRoleColor } from '../enums/user-role-color.enum';
import { SpecialUsers } from '../../modules/shared/enums/special-users.enum';

@Injectable({
    providedIn: 'root',
})
export class UsersUtilsService {
    constructor() {}

    checkIfUserIsAdmin(user: User) {
        let flag = 0;
        user.roles.forEach(function (roles) {
            if (roles === 'ROLE_ADMIN') flag = 1;
        });
        return flag;
    }

    checkIfUserIsCreator(user: User) {
        let flag = 0;
        user.roles.forEach(function (roles) {
            if (roles === 'ROLE_CREATOR') flag = 1;
        });
        return flag;
    }

    formatUserData(sectionData) {
        const newData: { roles: string[] } = { roles: [] };
        if (sectionData['admin']) {
            newData.roles.push('ROLE_ADMIN');
        }
        if (sectionData['roles']) {
            newData.roles.push(sectionData['roles']);
        }
        return newData;
    }

    getName(user) {
        return { name: user.firstName + ' ' + user.lastName };
    }

    getNumberOfUserUpdateColor(value) {
        if (value < 80) return 'ok';
        else if (value < 95) return 'warn';
        else return 'danger';
    }

    isCreator(user: User) {
        return user && user.roles.includes(Role.Creator);
    }

    isAdmin(user: User) {
        return user && user.roles.includes(Role.Admin);
    }

    isSuperAdmin(user: User) {
        return user && user.roles.includes(Role.SuperAdmin);
    }

    getUserRoleColor(user: Partial<User>): string {
        const values = [];
        if (user.roles) {
            user.roles.forEach((role) => {
                values.push(Roles[role]);
            });
            const minValue = Math.min.apply(null, values);
            return Object.values(UserRoleColor)[minValue];
        }
        return '';
    }

    getFullName(user: User | SpecialUsers): string {
        if (typeof user === 'string') {
            return user;
        }

        return `${user.firstName} ${user.lastName}`;
    }
}
