import { Component } from '@angular/core';

@Component({
    selector: 'elias-core-disabled-user',
    template: `<h2>Not Authorized</h2>
        <p>You have been deactivated</p>`,
    styleUrls: ['disabled-user.component.scss'],
})
export class DisabledUserComponent {
    constructor() {}
}
