import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Mentionable } from '../../models/mentionable.model';
import { User } from '../../../../modules/shared/models/user.model';
import { UsersQuery } from '../../../../modules/shared/state/users/users.query';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'elias-mentionable-avatar',
    templateUrl: './mentionable-avatar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionableAvatarComponent {
    @Input() mentionable?: Mentionable;

    get user(): User | undefined {
        if (!this.mentionable || this.mentionable.mentionType !== 'user') {
            return undefined;
        }

        return this.usersQuery.getEntity(this.mentionable.id);
    }

    get groupInitials(): string {
        if (!this.mentionable || this.mentionable.mentionType !== 'group') {
            return '';
        }

        return this.translateService.instant(`comments.mentions.initials.${this.mentionable.id}`);
    }

    constructor(private usersQuery: UsersQuery, private translateService: TranslateService) {}
}
