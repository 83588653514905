import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'elias-core-not-found',
    template: `<h2>Not found</h2>
        <p>The resource you are looking for cannot be found.</p>`,
    styleUrls: ['not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {
        timer(2000).subscribe(async () => {
            await this.router.navigate(['/']);
        });
    }
}
