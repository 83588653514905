import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ExcelDataService {
    private data = new BehaviorSubject<any>(null);
    data$ = this.data.asObservable();

    constructor() {}

    public setData(value) {
        this.data.next(value);
    }
}
