<div>
    <img src="/../../../assets/maintenance-image.jpg" class="image" />
    <div class="caption">
        <a href="https://unsplash.com/@dimhou/" target="_blank">Dim Hou</a>
        <span>, </span>
        <a href="https://unsplash.com/" target="_blank">Unsplash</a>
    </div>
</div>

<div class="message">{{ maintenanceWarning$ | async }}</div>
