import { Component, Input, OnInit } from '@angular/core';
import { DiffEntry } from '../../models/diff-entry.model';

@Component({
    selector: 'elias-version-diff-content',
    templateUrl: './version-diff-content.component.html',
    styleUrls: ['./version-diff-content.component.scss'],
})
export class VersionDiffContentComponent implements OnInit {
    @Input() diffEntry?: DiffEntry;
    @Input() condensedView: boolean = true;

    public htmlDiff?: string;

    constructor() {}

    ngOnInit() {
        if (!this.diffEntry?.htmlDiff) {
            return;
        }

        this.htmlDiff = this.condensedView ? this.condenseHtml(this.diffEntry.htmlDiff) : this.diffEntry.htmlDiff;
    }

    private condenseHtml(html: string): string {
        const parser = document.createElement('div');
        parser.innerHTML = html;

        let outermostElements = Array.from(parser.children);
        const numOfElements = outermostElements.length;

        // Replace with hidden-content elements
        for (let i = 1; i < numOfElements; i++) {
            const previousEl = outermostElements[i - 1];
            const currentEl = outermostElements[i];

            if (this.hasDifferences(currentEl) && !this.hasDifferences(previousEl)) {
                this.replaceElementWithDots(previousEl);
                continue;
            }

            // Last element
            if (i === numOfElements - 1 && !this.hasDifferences(currentEl)) {
                this.replaceElementWithDots(currentEl);
            }
        }

        // Remove redundant elements
        outermostElements = Array.from(parser.children);

        for (let i = 0; i < numOfElements; i++) {
            const currentEl = outermostElements[i];

            if (this.hasDifferences(currentEl)) {
                continue;
            }

            if (currentEl.classList.contains('hidden-content')) {
                continue;
            }

            currentEl.parentNode!.removeChild(currentEl);
        }

        return parser.innerHTML;
    }

    private replaceElementWithDots(element: Element): void {
        const hiddenContentEl = document.createElement('div');
        hiddenContentEl.classList.add('hidden-content');
        hiddenContentEl.textContent = '[...]';

        element.parentNode!.replaceChild(hiddenContentEl, element);
    }

    private hasDifferences(el: Element): boolean {
        return !!(el.querySelector('ins') || el.querySelector('del'));
    }
}
