import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface PreviewState {
    key: string;
    loaded: boolean;
    loading: boolean;
}

export function createInitialState(): PreviewState {
    return {
        key: '',
        loaded: false,
        loading: false,
    };
}

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'Preview', resettable: true })
export class PreviewStore extends Store<PreviewState> {
    constructor() {
        super(createInitialState());
    }
}
