import { QueryEntity } from '@datorama/akita';
import { SectionsState, SectionsStore } from './sections.store';
import { Section } from '../../models/section.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SectionsQuery extends QueryEntity<SectionsState, Section> {
    constructor(protected override store: SectionsStore) {
        super(store);
    }
}
