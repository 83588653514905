import { Injectable } from '@angular/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Extension } from '@tiptap/core';

@Injectable({
    providedIn: 'root',
})
export class TiptapStarterKitService {
    constructor() {}

    public getConfiguration(): Extension {
        return StarterKit;
    }
}
