import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NodeEdit } from '../../../interfaces/node-edit.interface';
import 'tinymce/plugins/paste';
import 'tinymce/icons/default';
import '../../../../../../assets/tinymce/plugins/spellchecker/index';
import { Observable } from 'rxjs';
import { NodeConfig } from '../../../models/node/node-type.model';
import { QuoteNode } from '../../../models/node/quote-node.model';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';

@Component({
    selector: 'elias-editor-edit-quote',
    templateUrl: './edit-quote.component.html',
    styleUrls: ['./edit-quote.component.scss'],
})
export class EditQuoteComponent implements NodeEdit, NodeRepresentation, OnInit {
    @Input() config?: NodeConfig;
    @Input() content: string = '';
    @Input() node?: QuoteNode;
    @Input() nodeViewModel?: Observable<QuoteNode>;

    @Output() contentChange = new EventEmitter<any>();

    public localContent: string = '';
    public containerId = 'tinymce';

    ngOnInit(): void {
        this.containerId = 'tinymce-' + this.node?.id;
        this.localContent = this.content;
    }

    public onContentChange(content: string): void {
        this.content = content;
        this.contentChange.emit(this.content);
    }
}
