<mat-card *ngIf="version && canBeDisplayed" class="version-card" (click)="openVersion()">
    <mat-card-header>
        <div class="user-label">
            <elias-shared-user-avatar [user]="creator?.user" [hideToolTip]="true" [size]="'xsmall'">
            </elias-shared-user-avatar>
            <mat-card-title>{{ creator?.name }}</mat-card-title>
        </div>

        <div
            class="version-date"
            matTooltip="{{ version.log.createdAt | localizedDate : 'eliasShortDateTimeSeconds' }}"
            [matTooltipPosition]="'above'">
            {{ version.log.createdAt | timeAgo }}
        </div>
        <div class="version-action">
            <div class="action-text">Details</div>
            <i class="ei ei-arrows-right icons"></i>
        </div>
    </mat-card-header>

    <elias-version-diff [diff]="version.diff"></elias-version-diff>
</mat-card>
