import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { EditorComponent } from '../pages/editor/editor.component';
import { NodesQuery } from '../state/nodes/nodes.query';

@Injectable()
export class ContentSaveGuard implements CanDeactivate<EditorComponent> {
    constructor(private nodesQuery: NodesQuery) {}

    async canDeactivate(): Promise<boolean> {
        // Wait until all the nodes are saved before leaving the editor page
        await this.nodesQuery.waitUntilAllSaved();

        return true;
    }
}
