import { Component, Input } from '@angular/core';

@Component({
    selector: 'elias-form-element-static-heading',
    templateUrl: './form-static-heading.component.html',
    styleUrls: ['./form-static-heading.component.scss'],
})
export class FormStaticHeadingComponent {
    @Input() level: number;
    @Input() value: string;
}
