import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Reply } from '../../models/comment.model';

@Component({
    selector: 'elias-reply-action-readonly',
    templateUrl: './reply-action-readonly.component.html',
    styleUrls: ['./reply-action-readonly.component.scss'],
})
export class ReplyActionReadonlyComponent {
    @Input() reply?: Reply;
    @Input() showControls: boolean = true;

    @Output() replyLinkClicked = new EventEmitter<void>();

    constructor() {}

    public goToReply(): void {
        this.replyLinkClicked.emit();
    }
}
