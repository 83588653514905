<div class="properties-diff-container" *ngIf="propertiesDiff">
    <div class="properties-diff-table">
        <div class="properties-diff-row header">
            <div class="properties-diff-cell"></div>
            <div class="properties-diff-cell properties-diff-cell-header">
                {{ 'versioning.diff.before' | translate }}
            </div>
            <div class="properties-diff-cell properties-diff-cell-header">
                {{ 'versioning.diff.after' | translate }}
            </div>
        </div>

        <ng-container *ngFor="let diff of propertiesDiff | keyvalue">
            <elias-version-diff-default
                *ngIf="diff.value.type === 'default'"
                [diffEntry]="diff.value"
                [diffKey]="diff.key"></elias-version-diff-default>

            <elias-version-diff-default
                *ngIf="diff.value.type === 'checkbox'"
                [diffEntry]="diff.value"
                [diffKey]="diff.key"></elias-version-diff-default>

            <elias-version-diff-text
                *ngIf="diff.value.type === 'text'"
                [diffEntry]="diff.value"
                [diffKey]="diff.key"></elias-version-diff-text>

            <elias-version-diff-channel-settings
                *ngIf="diff.value.type === 'channel-settings'"
                [channelSettings]="diff.value"></elias-version-diff-channel-settings>
        </ng-container>
    </div>
</div>
