import { Component, Input } from '@angular/core';

@Component({
    selector: 'elias-form-element',
    templateUrl: './form-element.component.html',
})
export class FormElementComponent {
    @Input() disabledOptions;
    @Input() element;
    @Input() errors;
    @Input() formGroup;
    @Input() hint: string;
    @Input() initValue: any;
    @Input() settings;
    @Input() translationKey: string;
}
