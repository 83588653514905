import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Section } from '../models/section.model';

@Injectable()
export class SharedEventsService {
    private emitDiselectPropertyEditor$ = new Subject<Section>();

    diselectPropertyEditor$ = this.emitDiselectPropertyEditor$.asObservable();

    diselectPropertyEditor() {
        this.emitDiselectPropertyEditor$.next();
    }
}
