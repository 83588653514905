import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UrlParamsService {
    public getNestedParamFromActivatedRouteSnapshot(route: ActivatedRouteSnapshot, param: string): string | undefined {
        let routeLevel: ActivatedRouteSnapshot | null = route;
        while (routeLevel) {
            if (routeLevel.params[param]) {
                return routeLevel.params[param];
            }

            routeLevel = routeLevel.parent;
        }

        return undefined;
    }
}
