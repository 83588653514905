import { Injectable } from '@angular/core';
import { FormFieldConfig } from '../../../modules/forms/models/form-field-config.model';
import { FormConfig } from '../../../modules/forms/models/form-config.model';

@Injectable({
    providedIn: 'root',
})
export class ImageGridForm {
    constructor() {}

    public getConfiguration(type: string): FormConfig {
        const obj: FormFieldConfig = {
            name: 'image',
            type: 'asset',
            required: false,
            width: 100,
            options: [],
            settings: {
                type: '',
            },
            validators: [],
            show: [],
        };

        if (type == 'image') {
            obj.settings.type = 'image';
        } else {
            obj.settings.type = 'images';
        }

        return {
            elements: [
                obj,
                {
                    name: 'title',
                    type: 'text',
                    required: false,
                    width: 100,
                    options: [],
                    settings: {},
                    validators: [],
                    show: [],
                },
                {
                    name: 'subtitle',
                    type: 'text',
                    required: false,
                    width: 100,
                    options: [],
                    settings: {},
                    validators: [],
                    show: [],
                },
                {
                    name: 'description',
                    type: 'textarea',
                    required: false,
                    width: 100,
                    options: [],
                    settings: {
                        enableRichText: true,
                    },
                    validators: [],
                    show: [],
                },
                {
                    name: 'source',
                    type: 'text',
                    required: false,
                    width: 100,
                    options: [],
                    settings: {},
                    validators: [],
                    show: [],
                },
                {
                    name: 'link',
                    type: 'text',
                    required: false,
                    width: 100,
                    options: [],
                    settings: {},
                    validators: [],
                    show: [],
                },
            ],
        };
    }
}
