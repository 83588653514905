import { Injectable } from '@angular/core';
import { CommentsService } from './comments.service';
import { CommentsQuery } from '../state/comments.query';
import { CommentTargetType } from '../enums/comment-target-type.enum';
import { CommentEditorOutputDto } from '../models/comment-editor-output-dto.model';
import { Comment } from '../models/comment.model';
import { CommentFilterTypes, CommentsStore } from '../state/comments.store';
import { Router } from '@angular/router';
import { CommentLinksService } from '../../../modules/links/services/comment-links.service';
import { SortingOptions } from '../../../modules/shared/models/sorting-options.model';
import { CommentDraftsService } from './comment-drafts.service';
import { CommentDraftDto } from '../models/comment-draft-dto.model';
import { CommentHtmlParserService } from './comment-html-parser.service';

@Injectable({
    providedIn: 'root',
})
export class CommentsFacade {
    constructor(
        private commentDraftsService: CommentDraftsService,
        private commentsQuery: CommentsQuery,
        private commentsService: CommentsService,
        private commentsStore: CommentsStore,
        private router: Router,
        private commentLinksService: CommentLinksService,
        private commentHtmlParser: CommentHtmlParserService
    ) {}

    public createComment(body: CommentEditorOutputDto): void {
        const target = this.commentsQuery.getTarget();

        if (target === null) {
            throw new Error(`Comment cannot be created if a target is not set`);
        }

        const parsedContent = this.commentHtmlParser.parseHtmlForSaving(body.getContent());
        const parsedBody = new CommentEditorOutputDto(
            parsedContent,
            body.getMentionedUserIds(),
            body.getMentionedGroups()
        );

        switch (target.type) {
            case CommentTargetType.Node:
            case CommentTargetType.Section:
                this.commentsService.createComment(target, parsedBody).subscribe();
                this.removeCommentDraft();
                return;

            default:
                throw new Error(`Comment cannot be created for a target type: ${target}`);
        }
    }

    public createReply(body: CommentEditorOutputDto): void {
        const selectedComment = this.commentsQuery.getActive();
        if (selectedComment === undefined) {
            throw new Error('Reply cannot be created if there is no comment selected');
        }

        const parsedContent = this.commentHtmlParser.parseHtmlForSaving(body.getContent());
        const parsedBody = new CommentEditorOutputDto(
            parsedContent,
            body.getMentionedUserIds(),
            body.getMentionedGroups()
        );

        this.commentsService.createReply(selectedComment.id, parsedBody).subscribe();
        this.removeCommentDraft();
    }

    public resolveComment(comment: Comment): void {
        this.commentsService.resolveComment(comment.id).subscribe();
    }

    public unresolveComment(comment: Comment): void {
        this.commentsService.unresolveComment(comment.id).subscribe();
    }

    public selectComment(commentId: string): void {
        const comment = this.commentsQuery.getEntity(commentId);

        if (comment && comment.type === 'comment') {
            this.commentsStore.setActive(commentId);
        }
    }

    public deselectComment(): void {
        this.commentsStore.setActive(null);
    }

    public getCommentDraft(): CommentDraftDto | null {
        const target = this.commentsQuery.getTarget();

        if (target === null) {
            throw new Error('Comment draft cannot be read if a target is not set');
        }

        const draft = this.commentDraftsService.getDraft(target);

        if (!draft) {
            return null;
        }

        const parsedContent = this.commentHtmlParser.parseHtmlForDisplaying(draft.getContent());

        return new CommentDraftDto(parsedContent, draft.getRepliedCommentId());
    }

    public updateCommentDraft(editorOutput: CommentEditorOutputDto, isReply: boolean): void {
        const target = this.commentsQuery.getTarget();
        const selectedCommentId = this.commentsQuery.getActiveId() ?? null;
        const repliedCommentId = isReply ? (selectedCommentId as string) : null;

        const parsedContent = this.commentHtmlParser.parseHtmlForSaving(editorOutput.getContent());
        const commentDraft = new CommentDraftDto(parsedContent, repliedCommentId);

        if (target === null) {
            throw new Error('Comment draft cannot be updated if a target is not set');
        }

        this.commentDraftsService.saveDraft(commentDraft, target);
    }

    public removeCommentDraft(): void {
        const target = this.commentsQuery.getTarget();

        if (target === null) {
            throw new Error('Comment draft cannot be removed if a target is not set');
        }

        this.commentDraftsService.removeDraft(target);
    }

    public async navigateToComment(comment: Comment): Promise<void> {
        const link = this.commentLinksService.generate(comment);
        if (link === null) {
            return;
        }

        await this.router.navigate(link);
    }

    public updateFilter(filterType: CommentFilterTypes, value: any) {
        this.commentsStore.update((commentState) => ({
            ui: { ...commentState.ui, filters: { ...commentState.ui.filters, [filterType]: value } },
        }));
    }

    public setSorting(sorting: SortingOptions) {
        this.commentsStore.update((commentState) => ({
            ui: { ...commentState.ui, sorting },
        }));
    }

    public nextPage(): void {
        const nextPage = this.commentsQuery.getCurrentPage() + 1;

        this.commentsStore.update((commentState) => ({
            ui: { ...commentState.ui, page: nextPage },
        }));
    }

    public resetPage(): void {
        this.commentsStore.update((commentState) => ({
            ui: { ...commentState.ui, page: null },
        }));
    }

    public resetUiStore(): void {
        this.commentsStore.update((commentState) => ({
            ui: { ...commentState.ui, page: null, filters: {} },
        }));
    }

    public resetStore(): void {
        this.commentsStore.reset();
    }
}
