import { Injectable } from '@angular/core';
import { FormConfig } from '../../../modules/forms/models/form-config.model';
import { Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationForm {
    constructor() {}

    public getConfiguration(): FormConfig {
        return {
            elements: [
                {
                    label: 'editor.forms.changeSectionType.confirm',
                    name: 'confirm',
                    required: true,
                    type: 'checkbox',
                    validators: [
                        {
                            name: 'requiredTrue',
                        },
                    ],
                    width: 100,
                },
            ],
        };
    }
}
