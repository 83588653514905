import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { CategoriesService } from '../services/categories.service';
import { SystemInformationService } from '../services/system-information.service';
import { SystemInformationQuery } from '../state/system-information/system-information.query';
import { CategoriesQuery } from '../state/categories/categories.query';
import { UsersService } from '../services/users.service';
import { UsersQuery } from '../state/users/users.query';
import { NotificationsQuery } from '../../../notifications/state/notifications.query';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { StatusQuery } from '../state/status/status.query';
import { StatusService } from '../services/status.service';

@Injectable()
export class InitDataResolver implements Resolve<any> {
    constructor(
        private categoriesQuery: CategoriesQuery,
        private categoriesService: CategoriesService,
        private notificationsQuery: NotificationsQuery,
        private notificationsService: NotificationsService,
        private statusQuery: StatusQuery,
        private statusService: StatusService,
        private sysInfoQuery: SystemInformationQuery,
        private sysInfoService: SystemInformationService,
        private usersQuery: UsersQuery,
        private usersService: UsersService
    ) {}

    resolve() {
        const observables = [];
        if (!this.usersQuery.getValue().loaded) {
            observables.push(this.usersService.getUsers());
        }
        if (!this.usersQuery.hasLoggedInUser()) {
            observables.push(this.usersService.getLoggedInUser());
        }
        if (!this.sysInfoQuery.getValue().loaded) {
            observables.push(this.sysInfoService.getSystemInformation());
        }
        if (!this.categoriesQuery.getValue().loaded) {
            observables.push(this.categoriesService.getCategories());
        }
        if (!this.notificationsQuery.getValue().loaded) {
            observables.push(this.notificationsService.getNumberOfUnreadNotifications());
        }
        if (!this.statusQuery.getValue().loaded) {
            observables.push(this.statusService.getApplicationStatus());
        }

        if (observables.length > 0) {
            return forkJoin(observables);
        } else {
            return of(true);
        }
    }
}
