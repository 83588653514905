import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[eliasScrollableItem]',
})
export class ScrollableItemDirective {
    @Input() id?: string;

    constructor(public elementRef: ElementRef) {}
}
