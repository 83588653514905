import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropzoneConfig, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { slideInAnimation } from '../../animation';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'elias-assetbrowser-dropzone-upload',
    templateUrl: './asset-dropzone-upload.component.html',
    styleUrls: ['./asset-dropzone-upload.component.scss'],
    animations: [slideInAnimation],
})
export class AssetDropzoneUploadComponent {
    @Input() config: DropzoneConfig;

    @Output() assetDropped = new EventEmitter<any>();
    @Output() dragEnter = new EventEmitter<any>();
    @Output() dragLeave = new EventEmitter<any>();
    @Output() dragOver = new EventEmitter<any>();
    @Output() errorUpload = new EventEmitter<any>();
    @Output() fileUploaded = new EventEmitter<[File, any[], ProgressEvent]>();
    @Output() uploadCompleted = new EventEmitter<any>();
    @Output() uploadInProgress = new EventEmitter<any>();
    @Output() uploadStart = new EventEmitter<any>();

    imageUploadInProgress = false;
    uploadError = false;
    uploadErrorFiles: any[] = [];
    showErrorPanel: boolean;
    showSuccessPanel: boolean;

    @ViewChild(DropzoneDirective, { static: true }) directiveRef?: DropzoneDirective;

    constructor(private translateService: TranslateService) {}

    onUploadError([file]: any[]): void {
        const allowedFileTypes = this.config.acceptedFiles!.split(',');
        const maxFileSize = (this.config.maxFilesize as number) * 1024 * 1024;

        let errorMessage;

        if (!allowedFileTypes.includes(file.type)) {
            errorMessage = this.translateService.instant('assetBrowser.upload.error.fileType');
        } else if (file.size > maxFileSize) {
            errorMessage = this.translateService.instant('assetBrowser.upload.error.fileSize');
        } else {
            errorMessage = this.translateService.instant('assetBrowser.upload.error.default');
        }

        this.uploadError = true;
        this.uploadErrorFiles.push({ name: file.name, error: errorMessage });
        this.errorUpload.emit(errorMessage);
    }

    onUploadComplete(): void {
        if (this.uploadError) {
            this.setShowErrorPanel(true);
        } else {
            this.setShowSuccessPanel(true);
            setTimeout(() => {
                this.setShowSuccessPanel(false);
            }, 3000);
        }

        this.imageUploadInProgress = false;
        this.uploadInProgress.emit(this.imageUploadInProgress);
        this.directiveRef.reset();
        this.uploadCompleted.emit(null);
    }

    onUploadInit(): void {
        if (this.imageUploadInProgress === false) {
            this.uploadInProgress.emit(this.imageUploadInProgress);
            this.uploadStart.emit();
            this.setShowErrorPanel(false);
            this.setShowSuccessPanel(false);
            this.uploadError = false;
            this.uploadErrorFiles = [];
        }
        this.imageUploadInProgress = true;
        this.uploadInProgress.emit(this.imageUploadInProgress);
    }

    onSuccess(output: [File, any[], ProgressEvent]): void {
        this.fileUploaded.emit(output);
    }

    setShowSuccessPanel(successPanelState: boolean): void {
        this.showSuccessPanel = successPanelState;
    }

    setShowErrorPanel(errorPanelState: boolean): void {
        this.showErrorPanel = errorPanelState;
    }

    trackByIndex(index, item) {
        return index;
    }

    onDragEnter(event): void {
        this.dragEnter.emit(event);
    }

    onDrop(event): void {
        this.assetDropped.emit(event);
    }

    onDragLeave(event): void {
        this.dragLeave.emit(event);
    }

    onDragOver(event): void {
        this.dragOver.emit(event);
    }
}
