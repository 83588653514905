import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BasicFormElementComponent } from '../_basic-form-element/form-basic-component.component';

@Component({
    selector: 'elias-form-element-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['./form-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputComponent extends BasicFormElementComponent implements OnInit {
    isVisible = true;
    moduleName;

    matPrefix = '';

    ngOnInit() {
        super.ngOnInit();
        if (this.settings !== undefined) {
            if (Object.keys(this.settings).length > 0) {
                if (this.settings.hasOwnProperty('moduleName')) this.moduleName = this.settings['moduleName'];
                if (this.settings.hasOwnProperty('matPrefix')) this.matPrefix = this.settings['matPrefix'] + ' ';
            }
        }
    }
}
