<div [formGroup]="formGroup">
    <div fxLayout="row wrap" fxLayoutGap="20px grid">
        <div *ngFor="let channel of allChannels$ | async; trackBy: trackByItemId; let i = index">
            <mat-card class="person" [ngClass]="{ active: isActive(channel) }" (click)="toggleActivation(channel)">
                <mat-card-header>
                    <div mat-card-avatar class="example-header-image">
                        <img src="/assets/images/default.jpg" />
                    </div>
                    <mat-card-title>{{ channel.name }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <button mat-flat-button color="accent" *ngIf="isActive(channel)">
                        {{ 'publication.deselect' | translate }}
                    </button>
                    <button mat-stroked-button color="accent" *ngIf="!isActive(channel)">
                        {{ 'publication.select' | translate }}
                    </button>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
