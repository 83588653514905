<elias-shared-custom-stepper #stepper (cancel)="onCancel()" linear [mode]="'create-section'">
    <elias-shared-custom-step
        (stepIncreased)="createSection(nameStepForm.formGroup)"
        title="{{ 'modal.createSection.' + type + '.title' | translate }}"
        subtitle="{{ 'modal.createSection.' + type + '.subtitle' | translate }}"
        class="basic-step"
        [isSave]="nameStepForm.canSave()"
        [hideDots]="true">
        <elias-form [formConfig]="newSectionFormConfig" [hideButtons]="true" #nameStepForm></elias-form>
    </elias-shared-custom-step>
</elias-shared-custom-stepper>
