<ng-container *ngIf="!comment.resolvedAt; else resolved">
    <div class="comment-action" *ngIf="showControls">
        <div class="action-text">
            {{ 'comments.goto' | translate }}
        </div>
        <i class="ei-goto action-icon" (click)="goToComment()"></i>
    </div>
</ng-container>
<ng-template #resolved>
    <div class="comment-resolved-action" [ngClass]="{ 'has-controls': showControls }">
        <div class="comment-resolve-message">
            {{
                'comments.resolvedMessage'
                    | translate
                        : {
                              name: resolverName,
                              time: comment.resolvedAt | timeAgo
                          }
            }}
        </div>
        <div class="comment-action" *ngIf="showControls">
            <div class="action-text">
                {{ 'comments.goto' | translate }}
            </div>
            <i class="ei-goto action-icon" (click)="goToComment()"></i>
        </div>
    </div>
</ng-template>
