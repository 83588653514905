import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AspectRatio } from '../models/aspect-ratio.model';
import { AspectRatiosStore } from '../state/aspect-ratios/aspect-ratios.store';
import { DomainService } from '../../core/services/domain.service';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class AspectRatiosService {
    constructor(
        private aspectRatiosStore: AspectRatiosStore,
        private domainService: DomainService,
        private http: HttpClient
    ) {}

    /** GET assets from the server */
    getAspectRatios(options: any = {}): Observable<AspectRatio[]> {
        this.aspectRatiosStore.setLoading(true);
        const url = `${this.domainService.apiBaseUrl}/aspect-ratios`;
        return this.http.get<AspectRatio[]>(url).pipe(
            withTransaction((aspectRatios) => {
                this.aspectRatiosStore.set(aspectRatios);
                this.aspectRatiosStore.setLoading(false);
                this.aspectRatiosStore.update({ loaded: true });
            }),
            catchError((error: any) => throwError(error))
        );
    }
}
