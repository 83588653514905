<div id="droppable" dragula="bag-draggable" [ngClass]="{ 'document-without-nodes': (nodes$ | async)?.length }">
    <ng-container *ngFor="let node of nodes$ | async">
        <ng-container *ngIf="node.type !== 'undefined'; else createComponent">
            <elias-editor-node-editable
                [ngClass]="[
                    node.editing ? 'pe-node-active' : 'pe-node-inactive',
                    node.needsReview ? 'pe-needs-review' : '',
                    node['alignment'] ? 'text-align-' + node['alignment'] : '',
                    node.type === 'key-figure' ? 'e-key-figures' : 'e-' + node.type,
                    node?.type === 'image' && node['imageStyle'] === 'default' ? 'e-image-default' : '',
                    node?.type === 'image' && node['imageStyle'] === 'pushLeft' ? 'e-image-push-left' : '',
                    node?.type === 'image' && node['imageStyle'] === 'pushRight' ? 'e-image-push-right' : '',
                    node?.type === 'image' && node['imageStyle'] === 'fullWidth' ? 'e-image-full-width' : ''
                ]"
                [node]="node"
                [editing]="(selectedNode$ | async) !== undefined || (active$ | async) === 1"
                data-id="{{ node.id }}"
                class="node-type-{{ node.type }} e-node"
                (click)="onSelect($event, node)"
                (deselected)="onDeselect($event)"
                (remove)="onRemove($event)"
                (contentChange)="onContentChange($event)">
            </elias-editor-node-editable>
        </ng-container>
        <ng-template #createComponent>
            <elias-editor-node-selection
                [isLoading]="isLoading"
                (selection)="selectedOption($event)"
                (remove)="removeOptions()">
            </elias-editor-node-selection>
        </ng-template>
    </ng-container>
</div>
