import { Component, Input, OnInit } from '@angular/core';
import { TextDiffEntry } from '../../models/diff-entry.model';

@Component({
    selector: 'elias-version-diff-default',
    templateUrl: './version-diff-default.component.html',
    styleUrls: ['./version-diff-default.component.scss'],
})
export class VersionDiffDefaultComponent {
    @Input() diffEntry?: TextDiffEntry;
    @Input() diffKey?: string;

    constructor() {}
}
