import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

@Injectable({
    providedIn: 'root',
})
export class DateTimeValidator {
    static createDateTimeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value as string;

            if (!value) {
                return null;
            }

            dayjs.extend(customParseFormat);
            const isDateTimeInValidFormat = dayjs(value, 'YYYY-MM-DD HH:mm:ss', true).isValid();

            return isDateTimeInValidFormat ? null : { dateTimeValue: true };
        };
    }
}
