import { FormConfig } from '../../../modules/forms/models/form-config.model';

export const KeyFigureForm: FormConfig = {
    elements: [
        {
            name: 'title',
            type: 'text',
            required: false,
            width: 100,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'number',
            label: 'keyfigures.number',
            type: 'text',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'unit',
            type: 'text',
            required: false,
            width: 50,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
        {
            name: 'description',
            type: 'text',
            required: false,
            width: 100,
            options: [],
            settings: {},
            validators: [],
            show: [],
        },
    ],
};
