<elias-shared-user-avatar
    size="xsmall"
    [user]="user"
    *ngIf="mentionable.mentionType === 'user'; else groupAvatar"></elias-shared-user-avatar>
<ng-template #groupAvatar>
    <elias-shared-avatar
        size="xsmall"
        [label]="mentionable.label"
        [initials]="groupInitials"
        backgroundColor="#ccc"></elias-shared-avatar>
</ng-template>
