import { LinksGeneratorRegistry } from './links-generator.registry';
import { LinksGeneratorInterface } from '../interfaces/links-generator.interface';
import { LinksProviderInterface } from '../interfaces/links-provider.interface';
import { SharedStorageLinksProvider } from '../providers/shared-storage-links.provider';

@LinksGeneratorRegistry.register
export class SharedStorageLinksGenerator implements LinksGeneratorInterface {
    canGenerate(provider: LinksProviderInterface): provider is SharedStorageLinksProvider {
        return provider instanceof SharedStorageLinksProvider;
    }

    generate(provider: LinksProviderInterface): any[] | null {
        if (!this.canGenerate(provider)) {
            return null;
        }

        const { publicationGroupId, publicationId, sharedStorageId } = provider.getParameters();

        return [
            '/publication-groups',
            publicationGroupId,
            'publication',
            publicationId,
            { 'shared-storages': sharedStorageId },
        ];
    }
}
