import { Injectable } from '@angular/core';
import { SystemInformationQuery } from '../../../modules/shared/state/system-information/system-information.query';
import { KeyValueOption } from '../../../modules/shared/models/key-value-option.model';
import { Publication } from '../../../publication/models/publication.model';
import { Section } from '../models/section.model';
import { LayoutConfiguration } from '../../../modules/shared/models/layout-configuration.model';

@Injectable()
export class LayoutService {
    constructor(private systemInformationQuery: SystemInformationQuery) {}

    public getLayoutOptions(): KeyValueOption[] {
        return this.systemInformationQuery.getLayoutOptions();
    }

    public getSectionTypeOptions(publication?: Publication): KeyValueOption[] {
        const layoutConfiguration = this.getLayoutConfiguration(publication);

        if (layoutConfiguration === undefined) {
            return [];
        }

        return Object.values(layoutConfiguration.allowedSectionTypes).map(
            ({ type }): KeyValueOption => ({
                key: type,
                value: `section.${type}`,
            })
        );
    }

    public getAllowedNodeTypes(sectionType: string, publication?: Publication): string[] {
        const layoutConfiguration = this.getLayoutConfiguration(publication);
        if (layoutConfiguration === undefined) {
            return [];
        }

        if (!(sectionType in layoutConfiguration.allowedSectionTypes)) {
            return [];
        }

        return layoutConfiguration.allowedSectionTypes[sectionType].allowedNodeTypes;
    }

    private getLayoutConfiguration(publication?: Publication): LayoutConfiguration | undefined {
        const layout = publication?.layout;
        if (!layout) {
            return undefined;
        }

        const layoutConfigurations = this.systemInformationQuery.getLayouts();

        return layoutConfigurations.get(layout);
    }
}
