<elias-shared-loading
    *ngIf="loading$ | async; else loginPage"
    [includeText]="true"
    [centered]="true"
    descriptionContext="login"></elias-shared-loading>
<ng-template #loginPage>
    <div class="login-form">
        <img src="/../../../assets/logo.png" class="logo" />

        <div class="spacer-2x"></div>

        <elias-maintenance
            *ngIf="
                (inMaintenance$ | async) && (isWhitelistedAtMaintenance$ | async) === false;
                else loginForm
            "></elias-maintenance>

        <ng-template #loginForm>
            <elias-login-form
                [inMaintenance]="(inMaintenance$ | async) ?? false"
                (userAuthenticated)="onUserAuthenticated()"></elias-login-form>
        </ng-template>
    </div>

    <!--<div class="footer">
        {{ 'login.footer' | translate }}
    </div>-->

    <div class="image" [ngStyle]="{ 'background-image': homeImage }"></div>
</ng-template>
