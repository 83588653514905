import { Injectable } from '@angular/core';
import { DomainService } from '../../core/services/domain.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StatusState, StatusStore } from '../state/status/status.store';

@Injectable({
    providedIn: 'root',
})
export class StatusService {
    constructor(private domainService: DomainService, private http: HttpClient, private statusStore: StatusStore) {}

    public getApplicationStatus(): Observable<Partial<StatusState>> {
        const url = `${this.domainService.apiBaseUrl}/public/status`;

        return this.http.get<Partial<StatusState>>(url).pipe(
            tap((status) => {
                this.statusStore.update(() => ({
                    ...status,
                    loaded: true,
                }));
            }),
            catchError((error: any) => throwError(error))
        );
    }
}
